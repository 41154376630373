import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation UserManagementCreateUser($requestData: UserManagementCreateUserRequest!) {
    userManagementCreateUser(requestData: $requestData) {
      success
    }
  }
`;

export const ADD_USER_SCOPE = gql`
  mutation UserManagementAddUserScope($requestData: UserManagementAddUserScopeRequest!) {
    userManagementAddUserScope(requestData: $requestData) {
      success
    }
  }
`;

export const REMOVE_USER_FROM_COMPANY = gql`
  mutation UserManagementRemoveUserFromCompany($requestData: UserManagementRemoveUserFromCompanyRequest!) {
    userManagementRemoveUserFromCompany(requestData: $requestData) {
      success
    }
  }
`;

export const UPDATE_USER_ROLES = gql`
  mutation UserManagementUpdateUserRoles($requestData: UserManagementUpdateUserRolesRequest!) {
    userManagementUpdateUserRoles(requestData: $requestData) {
      success
    }
  }
`;

export const UPDATE_USER_SCOPES = gql`
  mutation UserManagementUpdateUserScopes($requestData: UserManagementUpdateUserScopesRequest!) {
    userManagementUpdateUserScopes(requestData: $requestData) {
      success
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation UserManagementUpdateUserInfo($requestData: UserManagementUpdateUserInfoRequest!) {
    userManagementUpdateUserInfo(requestData: $requestData) {
      success
    }
  }
`;
