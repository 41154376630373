import './portfolio-dashboard.scss';
import { PortfolioLayout } from '../Portfolio/PortfolioLayout';
import { useQuery } from '@apollo/client';
import {
  GetPortfolioSummary,
  GetPortfolioSummaryVariables,
} from '../__generated__/GetPortfolioSummary';
import {
  GET_PORTFOLIO_SUMMARY_QUERY,
  kpiConfigs,
} from 'features/pages/portfolio/PortfolioSummary';
import { styled } from 'style/ORSNNTheme';
import {
  typographyHeebo14Bold,
  typographyHeebo20Bold,
  typographyHeebo24,
  typographyHeebo32Bold,
  typographyMono20,
} from 'common-ui/typography';
import { AssetClass } from '__generated__/globalTypes';
import { Spinner } from 'common-ui/Spinner';
import { GET_LISTINGS } from 'query/getListings';
import { GetListings } from 'query/__generated__/GetListings';
import Dinero from 'dinero.js';

const usePortfolioSummary = (assetClass?: AssetClass) => {
  const { data, loading } = useQuery<
    GetPortfolioSummary,
    GetPortfolioSummaryVariables
  >(GET_PORTFOLIO_SUMMARY_QUERY, {
    variables: {
      hasAssetClass: !!assetClass,
      assetClass,
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 15000,
  });
  return { data, loading };
};

const mapKpis = (data: GetPortfolioSummary | undefined) => {
  return kpiConfigs.map((kpi) => {
    if (!data) {
      return {
        ...kpi,
        value: '-',
      };
    }

    const performanceData =
      data.user.company.assetClassPerformanceData ||
      data.user.company.performanceData;
    const value = performanceData ? performanceData[kpi.selector] ?? 0 : 0;
    return {
      ...kpi,
      value: kpi.description === 'UPB' ? formatUpb(value) : kpi.display(value),
    };
  });
};

// Rounds down to nearest dollar, as requested by Ralf K.
const formatUpb = (value: number) => {
  const truncatedValue = Math.floor(value);
  return Dinero({
    amount: truncatedValue,
    currency: 'USD',
  }).toFormat('$0,0');
};

const KpiCardContainer = ({
  kpis,
  loading,
}: {
  kpis: any[];
  loading: boolean;
}) => (
  <StyledKpiCardContainer>
    {kpis.map((kpi) => (
      <KpiCard key={kpi.description}>
        <KpiValue>{kpi.value}</KpiValue>
        <KpiLabel>
          {kpi.description === '#' ? '# LOANS' : kpi.description}
        </KpiLabel>
      </KpiCard>
    ))}
  </StyledKpiCardContainer>
);

const PortfolioDashboard = () => {
  const { data, loading } = usePortfolioSummary();
  const { data: autoData, loading: autoLoading } = usePortfolioSummary(
    AssetClass.AUTO
  );
  const { data: homeData, loading: homeLoading } = usePortfolioSummary(
    AssetClass.HOME
  );
  const { data: listingsData } = useQuery<GetListings>(GET_LISTINGS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 15000,
  });

  const kpis = mapKpis(data);
  const autoKpis = mapKpis(autoData);
  const homeKpis = mapKpis(homeData);

  const autoPools =
    listingsData?.user.company.listings.filter(
      (listing) => listing.asset_class === AssetClass.AUTO
    ).length || 0;
  const homePools =
    listingsData?.user.company.listings.filter(
      (listing) => listing.asset_class === AssetClass.HOME
    ).length || 0;
  const totalPools = autoPools + homePools;

  return (
    <PortfolioLayout hideSummery={true}>
      <Spinner loading={loading || autoLoading || homeLoading} />
      <CenterInPage>
        <KpiContainer>
          <div>
            <h2>Total in your Portfolio</h2>
            <div>{totalPools} pools</div>
          </div>

          <KpiCardContainer kpis={kpis} loading={loading} />

          <div>
            <h1>Auto</h1>
            <h2>Total by asset class</h2>
            <div>{autoPools} pools</div>
          </div>

          <KpiCardContainer kpis={autoKpis} loading={autoLoading} />

          <div>
            <h1>Home</h1>
            <h2>Total by asset class</h2>
            <div>{homePools} pools</div>
          </div>

          <KpiCardContainer kpis={homeKpis} loading={homeLoading} />
        </KpiContainer>
      </CenterInPage>
    </PortfolioLayout>
  );
};

const CenterInPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const KpiContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h1 {
    ${typographyHeebo32Bold}
    color: #e8e9ec;
    margin: 20px 0 0 0;
  }

  h2 {
    ${typographyHeebo20Bold}
    color: #bbc5d7;
    margin-bottom: 2px;
  }
`;

const KpiValue = styled.div`
  ${typographyMono20}
  color: #f2f2f2;
`;

const KpiLabel = styled.div`
  ${typographyHeebo14Bold}
  font-weight: 900;
  color: #ffffff;
`;

const KpiCard = styled.div`
  background-color: #1f1f1f;
  border-radius: 6px;
  border: 1px solid #868e9f;
  width: 170px;
  padding: 10px;
  height: 66px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const StyledKpiCardContainer = styled.div`
  backgroundcolor: '#282a2f';
  display: flex;
  flex-direction: row;
  gap: 10px;

  & > :first-child {
    background-color: #e028dd;
    border: 1px solid #260626;
    background: linear-gradient(103.13deg, #1555ec -8.88%, #ff23e1 93.85%);

    ${KpiValue} {
      ${typographyHeebo24}
      font-weight: 700;
      font-size: 20px;
    }
  }
`;

export default PortfolioDashboard;
