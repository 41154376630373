import { gql, useQuery } from '@apollo/client';
import { FileType } from '__generated__/globalTypes';
import { GetDocuments, GetDocuments_user_company_documents as Documents } from './__generated__/GetDocuments';

const GET_DOCUMENTS = gql`
  query GetDocuments($fileType: FileType) {
    user {
      id
      company {
        id
        documents(fileType: $fileType) {
          id
          ...Document
        }
      }
    }
  }
  fragment Document on Document {
    __typename
    id
    name
    uploadedAt
    uploadedBy {
      given_name
      family_name
    }
    documentUrl
    documentSizeBytes
    is_template
  }
`;

export function useDocumentLibrary(fileType?: FileType, onlyTemplates?: boolean) {
  const { data, error, loading, refetch } = useQuery<GetDocuments>(
    GET_DOCUMENTS,
    {
      fetchPolicy: 'cache-and-network',
      ...(fileType && { variables: { fileType } }),
    }
  );
  const documents = data?.user.company.documents;
  return { 
    documents: onlyTemplates
      ? documents?.filter((file: Documents) => file.is_template)
      : documents,
    loading,
    error,
    refetch
  };
}
