import {
  typographyBadgeXSmallCondensedBold,
  typographyBodyPrimaryMedium,
} from 'common-ui/typography';
import { styled } from 'style/ORSNNTheme';

export const NewsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 612px;
  height: 90%;
`;

export const Charts = styled.div`
  display: flex;
  gap: 10px;
  margin: 5px 0 20px;
`;

export const Chart = styled.div`
  width: 602px;
  height: 387px;
  background-color: ${(props) => props.theme.color.bgSurface};
  border: 1px solid ${(props) => props.theme.color.accentDisabled};
  border-radius: 7px;
  padding: 12px;
`;

export const SectionTitle = styled.div`
  font-size: 24px;
  line-height: 30px;
  margin: 40px 0 32px 0;
  color: ${(props) => props.theme.color.fgDefault};
`;

export const StyledProminentNewsItem = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  padding-bottom: 25px;
`;
export const StyledRegularNewsItem = styled.div`
  height: 188px;
  position: relative;

  // background-color: ${(props) => props.theme.color.bgSurface};
  border: 1px solid ${(props) => props.theme.color.accentMuted};
  border-color: linear-gradient(
    106.57deg,
    rgba(21, 85, 236, 0.2) -57.01%,
    rgba(255, 35, 225, 0.2) 95.42%
  );
  border-radius: 8px;
  width: 23%;

  padding: 10px 20px 0 20px;
`;

export const StyledNewsListItem = styled.div`
  width: 100%;
  border: 1px solid rgba(21, 85, 236, 0.2);
  border-radius: 8px;
  padding: 7px 0 0 14px;
  ${typographyBodyPrimaryMedium}
  position: relative;
`;

export const PublicationDate = styled.span`
  color: ${(props) => props.theme.color.slate50};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 5px;
`;
export const Subject = styled.div`
  margin: 20px 0;
  color: ${(props) => props.theme.color.slate100};
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
`;
export const Summary = styled.div`
  color: ${(props) => props.theme.color.slate50};
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  margin: 20px 0;
`;

export const ReadTime = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.color.slate100};
`;

export const NumberOfViews = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.color.accentEmphasis};
  padding-left: 10px;
`;

export const ReadTimeAndViewsLine = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
`;

export const StyledChart = styled.div`
  h2 {
    color: ${(props) => props.theme.color.slate100};
    font-size: 18px;
    text-wrap: nowrap;
    letter-spacing: 0.5px;
  }

  font-size: 10px;
`;

export const ReadFullStoryLink = styled.a`
  color: ${(props) => props.theme.color.accentDefault};
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
`;
