import { CounterpartyDetails } from 'features/deals/dealCards/CounterpartyCard';
import { CounterpartyCard_CounterpartyCard } from './__generated__/CounterpartyCard';
import { isNotNull } from '../utils';
import { Emphasis } from '__generated__/globalTypes';
import { useMutation } from '@apollo/client';
import { AdvanceDeal, AdvanceDealVariables } from 'mutation/__generated__/AdvanceDeal';
import { GET_DEAL_TIMELINE } from '../../fragments';
import { ADVANCE_DEAL } from 'mutation/advanceDeal';
import { Button, KIND } from 'baseui/button';
import { styled } from 'style/ORSNNTheme';

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
`;

type Props = { dealId: string } & CounterpartyCard_CounterpartyCard;

const CounterpartyCardBody = (props: Props): JSX.Element => {
  const { dealId, actions } = props;

  const [advanceDeal] = useMutation<AdvanceDeal, AdvanceDealVariables>(
    ADVANCE_DEAL,
  );

  const buttons = dealId != null && actions.filter(isNotNull).map((action) => {
    return (
      <Button
        kind={action.emphasis === Emphasis.STRONG ? KIND.primary : KIND.secondary}
        key={action.title}
        onClick={() =>
          advanceDeal({
            variables: { id: dealId, action: action.type },
            refetchQueries: [
              {
                query: GET_DEAL_TIMELINE,
                variables: {
                  id: dealId,
                },
              },
            ],
          })
        }
      >
        {action.title}
      </Button>
    );
  });

  return (
    <>
      <CounterpartyDetails  {...props} />
      <ButtonRow>
        {buttons}
      </ButtonRow>
    </>
  );
};

export default CounterpartyCardBody;