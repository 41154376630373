import {
  typographyHeebo14Bold,
  typographyParagraphSmallPrimaryRegular,
} from 'common-ui/typography';
import { TwoColumns } from 'features/deals/DealStages/EventActionCards/utils';
import { styled } from 'style/ORSNNTheme';
import DocumentPreview from './DocumentPreview';
import { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { FileDropZone } from 'features/common-elements/FileDropZone';
import { Action } from './ActionButton';
import { Note } from './DealDocumentTypes';
import { useDialog } from 'common-ui/Dialog';
import DocumentsTable from './DocumentsTable';
import ConfirmDialog from '../../../../common-ui/ConfirmDialog';
import { FileDiligenceStatus } from './FileStatus';
import { GetDealDiligence_deal_diligence } from 'query/__generated__/GetDealDiligence';
import { DealRole, FileType } from '__generated__/globalTypes';
import CollateralNotesDialog from './CollateralNotesDialog';
import { useS3Uploader } from 'features/common-elements/useS3Uploader';

export interface DiligenceSelectorProps {
  selectedLoanId: string;
  onSelectLoan?: (loanId: string) => void;
  diligence: GetDealDiligence_deal_diligence[];
  fetchCollateralNotes: (collateralIds: string[]) => void;
}

export interface DocumentsProps {
  collateralNotesMap: Record<string, Note[]>;
  onFileStatusSelect: (docId: string, status: FileDiligenceStatus) => void;
  onAddNote: (docId: string, note: string) => void;
  onRemoveFile: (docId: string) => void;
  uploadProgress?: number;
}

export interface DiligenceLoanDocumentDrawerProps {
  dealName: string;
  role: DealRole;
  diligenceSelector: DiligenceSelectorProps;
  documents: DocumentsProps;
}

const DiligenceLoanDocumentDrawer = ({
  dealName,
  role,
  diligenceSelector: {
    selectedLoanId,
    onSelectLoan,
    diligence,
    fetchCollateralNotes,
  },
  documents: {
    collateralNotesMap,
    uploadProgress,
    onFileStatusSelect,
    onRemoveFile,
  },
}: DiligenceLoanDocumentDrawerProps) => {
  const [selectedDocId, setSelectedDocId] = useState<string>();
  const [currentDocId, setCurrentDocId] = useState<string | null>(null);
  const [docUrl, setDocUrl] = useState<string | undefined>();

  const selectedDiligence = useMemo(
    () => diligence.find(({ loan: { id } }) => id === selectedLoanId),
    [selectedLoanId, diligence]
  );

  const { upload } = useS3Uploader({
    onSuccess: (file, versionId) => {
      console.log(`File uploaded successfully. Version ID: ${versionId}`);
    },
    onProgress: (progress) => {
      console.log(`File upload progress: ${progress}%`);
    },
    onError: (error: unknown) => {
      console.error('Error uploading file:', error);
    },
    fileType: FileType.COLLATERAL_FILE,
    parentId: selectedDiligence?.id,
  });

  useEffect(() => {
    if (selectedDiligence) {
      fetchCollateralNotes(selectedDiligence.collateral.map((c) => c.id));
    }
  }, [selectedLoanId, fetchCollateralNotes, selectedDiligence]);

  // Automatically select the first document when the list of documents changes.
  useEffect(() => {
    if (
      !selectedDiligence?.collateral.some((doc) => doc.id === selectedDocId)
    ) {
      setSelectedDocId(selectedDiligence?.collateral?.[0]?.id || undefined);
    }
  }, [selectedLoanId, selectedDocId, selectedDiligence]);

  const [currentNotesDocumentId, setCurrentNotesDocumentId] =
    useState<string>();

  const currentNotesDocument = selectedDiligence?.collateral.find(
    (doc) => doc.id === currentNotesDocumentId
  );

  const noteDialog = useDialog();

  const handleNoteClick = (docId: string) => {
    setCurrentNotesDocumentId(docId);
    noteDialog.openDialog();
  };

  const confirmRemoveFileDialog = useDialog();

  const handleClickRemove = () => {
    confirmRemoveFileDialog.openDialog();
  };

  const selectedDoc = selectedDiligence?.collateral.find(
    (doc) => doc.id === selectedDocId
  );

  useEffect(() => {
    const selectedDoc = selectedDiligence?.collateral.find(
      (doc) => doc.id === selectedDocId
    );

    if (selectedDoc?.id !== currentDocId) {
      setCurrentDocId(selectedDoc?.id || null);
      setDocUrl(selectedDoc?.documentUrl);
    }
  }, [selectedDocId, selectedDiligence]);

  const documentPreview = useMemo(
    () => docUrl && <DocumentPreview documentUrl={docUrl} />,
    [docUrl]
  );

  const hasNote = (collateralId: string) => {
    return notesForCollateralId(collateralId).length > 0;
  };

  const notesForCollateralId = (collateralId?: string) => {
    if (!collateralId) return [];
    if (!collateralNotesMap) return [];
    return collateralNotesMap[collateralId] || [];
  };

  const onFileDrop = async (file: File) => {
    try {
      await upload(file);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <>
      <Container>
        <TwoColumns>
          <LoanSelectorColumn>
            <DealInfo>
              <DealId>{dealName}</DealId>
              <NumberOfLoans>{diligence.length}</NumberOfLoans>
            </DealInfo>
            <LoanIdList>
              {diligence.map(
                ({ loan: { id: loanId, account_id: accountId } }) => (
                  <LoanId
                    key={loanId}
                    aria-selected={loanId === selectedLoanId ? 'true' : 'false'}
                    className={loanId === selectedLoanId ? 'selected-loan' : ''}
                    onClick={() => onSelectLoan && onSelectLoan(loanId)}
                  >
                    {accountId}
                  </LoanId>
                )
              )}
            </LoanIdList>
          </LoanSelectorColumn>
          <LoanDocumentsColumn>
            {role === DealRole.SELLER && (
              <>
                <FileDropZone onFileDrop={onFileDrop} />
                {uploadProgress && (
                  <div>Upload Progress: {uploadProgress}%</div>
                )}
              </>
            )}
            <DocumentsTable
              role={role}
              collateral={selectedDiligence?.collateral}
              selectedDocId={selectedDocId}
              onDocSelected={setSelectedDocId}
              handleNoteClick={handleNoteClick}
              onFileStatusSelect={onFileStatusSelect}
              hasNote={hasNote}
            />
            <FooterContainer>
              <FlexCenter>
              </FlexCenter>
              <ActionsContainer>
                {role === DealRole.SELLER && (
                  <Action
                    label="Remove"
                    icon="close-round"
                    onClick={handleClickRemove}
                    disabled={!selectedDocId}
                  />
                )}
                <Action
                  as="a"
                  download
                  href={docUrl}
                  target="_blank"
                  label="Download"
                  icon="download-icon"
                  disabled={!selectedDocId}
                />
                <Action
                  label="Add Note"
                  icon="note-icon"
                  onClick={() => handleNoteClick(selectedDocId || '')}
                  disabled={!selectedDocId}
                />
                <Action
                  label="Mark Complete"
                  icon="complete-icon"
                  disabled={!selectedDocId}
                />
              </ActionsContainer>
            </FooterContainer>
          </LoanDocumentsColumn>
        </TwoColumns>
        {documentPreview}
      </Container>
      <CollateralNotesDialog
        collateralId={currentNotesDocumentId}
        dialog={noteDialog}
        documentName={currentNotesDocument?.name || ''}
      />

      <ConfirmDialog
        dialog={confirmRemoveFileDialog}
        message={'Remove file "' + selectedDoc?.name + '"?'}
        confirmButtonText="Remove"
        onConfirm={() =>
          onRemoveFile && selectedDocId && onRemoveFile(selectedDocId)
        }
      />
    </>
  );
};

export default DiligenceLoanDocumentDrawer;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ActionsContainer = styled(FlexCenter)``;

const FooterContainer = styled(FlexCenter)`
  justify-content: space-between;
`;

const Container = styled.div`
  background-color: #131316;
  padding: 16px;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${typographyParagraphSmallPrimaryRegular}
`;

const LoanDocumentsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const formatDate = (date: string): string => {
  if (!date) return '-';
  const luxonDate = DateTime.fromISO(date);
  return luxonDate.toFormat('yyyy-MM-dd h:mma');
};

const LoanSelectorColumn = styled.div`
  width: 270px;
  border: 1px solid ${(props) => props.theme.color.borderDefault};
  border-radius: 4px;
  padding: 16px 24px;
`;

const DealInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DealId = styled.div`
  ${typographyParagraphSmallPrimaryRegular}
  color: ${(props) => props.theme.color.slate50};
`;

const LoanIdList = styled.div`
  ${typographyParagraphSmallPrimaryRegular}
  color: ${(props) => props.theme.color.slate50};
  padding-left: 20px;
`;

const LoanId = styled.div`
  padding: 2px 10px;
  cursor: pointer;
  ${(props) =>
    props['aria-selected'] === 'true' &&
    `
      background-color: #9a1698;
    `}
`;

const NumberOfLoans = styled.div`
  ${typographyHeebo14Bold}
  color: ${(props) => props.theme.color.slate200};
`;
