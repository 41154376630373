import { useDrop } from 'react-dnd';
import { styled } from 'style/ORSNNTheme';
import { ItemTypes } from './common';
import { FieldIdAndLabel, FieldsMap } from './MapRequiredFieldsCard';
import { BaseButton } from 'common-ui';
import { SearchBox } from './SearchBox';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const fieldItemWidthInPixels = 288;

// Allow precisely two items to show side-by-side.
const fieldGroupContainerWidthInPixels = fieldItemWidthInPixels * 2;

const StyledFieldsListWrapper = styled.div`
  color: ${(props) => props.theme.color.grey300};
  font-size: 12px;
  min-width: 588px; // Wide enough for two columns plus some padding.
`;

const FieldList = styled.div`
  height: 540px;
  overflow-y: auto;
  .isOver {
    background-color: ${(props) => props.theme.color.accentMuted};
    border-width: 1px;
    border-style: dashed;
    border-color: ${(props) => props.theme.color.themeAccentEmphasis};
    border-radius: 4px;
  }
`;

const StyledListItem = styled.div`
  min-height: 60px;
  width: ${fieldItemWidthInPixels}px;
  padding: 0 24px 0 22px;
  color: ${(props) => props.theme.color.slate50};
  line-height: 20px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.color.slate400};
  }

  .mappedTo {
    color: ${(props) => props.theme.color.slate200};
  }
`;

const StyledUndoButton = styled(BaseButton)`
  border: 1px solid ${(props) => props.theme.color.slate200};
  color: ${(props) => props.theme.color.slate200};
`;

const FieldGroupContainer = styled.div`
  display: flex;
  width: ${fieldGroupContainerWidthInPixels}px;
  flex-wrap: wrap;
`;

type HeaderItem = {
  label: string;
};

type UnmappedFieldListItemProps = {
  field: FieldIdAndLabel;
  onHeaderDrop: (fieldId: string, header: string) => void;
};

const UnmappedFieldListItem = ({
  field,
  onHeaderDrop,
}: UnmappedFieldListItemProps) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.HEADER,
    drop: (item: HeaderItem) => onHeaderDrop(field.fieldId, item.label),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  return (
    <StyledListItem ref={drop} className={isOver ? 'isOver' : ''}>
      <div>
        <div>{field.label}</div>
      </div>
    </StyledListItem>
  );
};

type MappedFieldListItemProps = {
  field: FieldIdAndLabel;
  onUnmap: (fieldId: string) => void;
  mappedHeader?: string;
};

const MappedFieldListItem = ({
  field,
  onUnmap,
  mappedHeader,
}: MappedFieldListItemProps) => {
  return (
    <StyledListItem>
      <div>
        <div>{field.label}</div>
        <div className="mappedTo">{mappedHeader}</div>
      </div>
      <StyledUndoButton
        label="undo"
        size="small"
        type="secondary"
        onClick={() => onUnmap(field.fieldId)}
      >
        UNDO
      </StyledUndoButton>
    </StyledListItem>
  );
};

const FieldGroupHeader = styled.div`
  padding: 5px 24px 5px 20px;
  color: ${(props) => props.theme.color.grey500};
  line-height: 20px;
  font-size: 12px;
  background-color: ${(props) => props.theme.color.bgSurface};

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.color.slate400};
  }
`;

type FieldGroupProps = {
  fields: FieldIdAndLabel[];
  map: FieldsMap;
  searchTerm?: string;
  onUnmap: (fieldId: string) => void;
  onMap: (fieldId: string, header: string) => void;
  header: string;
};

const FieldGroup = ({
  fields,
  map,
  searchTerm,
  onMap,
  onUnmap,
  header,
}: FieldGroupProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const matchesSearchText = (field: FieldIdAndLabel) => {
    if (!searchTerm) return true;
    return field.label.toLowerCase().includes(searchTerm?.toLowerCase());
  };

  const matchingFields = fields
    .filter(matchesSearchText)
    .sort((a, b) => a.label.localeCompare(b.label));
  const unmappedFields = matchingFields.filter((field) => !map[field.fieldId]);
  const mappedFields = matchingFields.filter((field) => map[field.fieldId]);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <FieldGroupHeader onClick={toggleIsOpen}>
        {header}
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronRight} />
      </FieldGroupHeader>
      {isOpen && (
        <FieldGroupContainer>
          {mappedFields.map((field) => {
            return (
              <MappedFieldListItem
                key={field.fieldId}
                field={field}
                mappedHeader={map[field.fieldId]}
                onUnmap={onUnmap}
              />
            );
          })}
          {unmappedFields.map((field) => {
            return (
              <UnmappedFieldListItem
                key={field.fieldId}
                field={field}
                onHeaderDrop={onMap}
              />
            );
          })}
        </FieldGroupContainer>
      )}
    </>
  );
};

const FieldsList = ({
  requiredFields,
  optionalFields,
  map,
  onUnmap,
  onMap,
}: {
  requiredFields: FieldIdAndLabel[];
  optionalFields: FieldIdAndLabel[];
  map: FieldsMap;
  onUnmap: (fieldId: string) => void;
  onMap: (fieldId: string, header: string) => void;
}) => {
  const [searchTerm, setSearchTerm] = useState<string>();

  const handleSearchTermChanged = (text: string) => {
    setSearchTerm(text);
  };

  return (
    <StyledFieldsListWrapper>
      <p className='mb-1'>Search for an ORSNN column to map to</p>
      <SearchBox
        placeholder="Filter fields by name"
        onSearchTermChanged={handleSearchTermChanged}
      />
      <FieldList className='py-2 '>
        <FieldGroup
          header="REQUIRED"
          fields={requiredFields}
          map={map}
          searchTerm={searchTerm}
          onMap={onMap}
          onUnmap={onUnmap}
        />
        <FieldGroup
          header="OPTIONAL"
          fields={optionalFields}
          map={map}
          searchTerm={searchTerm}
          onMap={onMap}
          onUnmap={onUnmap}
        />
      </FieldList>
    </StyledFieldsListWrapper>
  );
};

export default FieldsList;
