import { useMutation } from '@apollo/client';
import { Button, KIND } from 'baseui/button';
import { DatePicker } from 'baseui/datepicker';
import { Input } from 'baseui/input';
import { PERCENT_REGEX } from 'configs/columns';
import { DateTime } from 'luxon';
import { SUBMIT_FUNDING_SCHEDULE } from 'mutation/submitFundingSchedule';
import {
  SubmitFundingSchedule,
  SubmitFundingScheduleVariables,
} from 'mutation/__generated__/SubmitFundingSchedule';
import { useEffect, useState } from 'react';
import { styled } from 'style/ORSNNTheme';
import * as yup from 'yup';
import { Emphasis } from '__generated__/globalTypes';
import { GET_DEAL_TIMELINE } from '../../fragments';
import {
  basisPointsToPercent,
  Column,
  percentToBasisPoints,
  SubjectText,
} from '../utils';
import { FundingScheduleInputCard_FundingScheduleInputCard } from './__generated__/FundingScheduleInputCard';

const Body = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: end;

  div {
    flex-grow: 1;
    align-items: center;
  }
`;

type Props = {
  dealId: string;
} & FundingScheduleInputCard_FundingScheduleInputCard;

const PERCENT_VALIDATOR = yup.string().required().matches(PERCENT_REGEX);

const FundingScheduleInputCardBody = (props: Props): JSX.Element => {
  const [bidRate, setBidRate] = useState<string>('');
  const [isBidRateValid, setIsBidRateValid] = useState(true);
  const [purchaseDate, setPurchaseDate] = useState<Date | undefined>(
    props.funding_schedule?.purchase_date
      ? DateTime.fromISO(props.funding_schedule.purchase_date).toJSDate()
      : undefined
  );
  const [submitFundingSchedule] = useMutation<
    SubmitFundingSchedule,
    SubmitFundingScheduleVariables
  >(SUBMIT_FUNDING_SCHEDULE);

  useEffect(() => {
    PERCENT_VALIDATOR.isValid(bidRate).then((isValid) =>
      setIsBidRateValid(isValid)
    );
  }, [bidRate]);

  const handleChange = (value: string) => {
    if (props.can_edit_funding_schedule) {
      setBidRate(value);
    }
  };
  const handleBlur = handleChange;

  // Calculate the next business day
  const getNextBusinessDay = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    // If tomorrow is Saturday (6), set to Monday (8)
    if (tomorrow.getDay() === 6) {
      tomorrow.setDate(tomorrow.getDate() + 2);
    }
    // If tomorrow is Sunday (0), set to Monday (1)
    else if (tomorrow.getDay() === 0) {
      tomorrow.setDate(tomorrow.getDate() + 1);
    }

    return tomorrow;
  };

  const handleChangeDate = ({ date }: { date: Date | Date[] }) => {
    if (!Array.isArray(date)) {
      let finalPurchaseDate = date;
      const nextBusinessDay = getNextBusinessDay();

      if (finalPurchaseDate < nextBusinessDay) {
        finalPurchaseDate = nextBusinessDay;
      }

      setPurchaseDate(finalPurchaseDate);
    }
  };

  const handleSubmit = () => {
    if (bidRate && isBidRateValid && purchaseDate != null && +bidRate > 0) {
      const bidRateToSubmit = percentToBasisPoints(bidRate) as number;
      const purchaseDateToSubmit = DateTime.fromJSDate(purchaseDate).toISO();
      submitFundingSchedule({
        variables: {
          input: {
            deal_id: props.dealId,
            funding_schedule: {
              bid_basis_points: bidRateToSubmit,
              purchase_date: purchaseDateToSubmit,
            },
          },
        },
        refetchQueries: [
          {
            query: GET_DEAL_TIMELINE,
            variables: {
              id: props.dealId,
            },
          },
        ],
      });
    }
  };

  const action = props.actions[0];
  const submitButton =
    action != null ? (
      <>
        <Button
          disabled={!!bidRate || +bidRate <= 0 || typeof purchaseDate === 'undefined'}
          kind={
            action?.emphasis === Emphasis.STRONG ? KIND.primary : KIND.secondary
          }
          key={action?.type}
          onClick={() => handleSubmit()}
        >
          {action?.title}
        </Button>
      </>
    ) : (
      <></>
    );
  return (
    <>
      <SubjectText>Confirm the final bid and wire transfer date.</SubjectText>
      <Body>
        <Column>
          Confirm Price
          <Input
            value={bidRate}
            onChange={(event) => handleChange(event.currentTarget.value + '')}
            onBlur={(event) => handleBlur(event.currentTarget.value + '')}
            endEnhancer={'%'}
            placeholder={`${basisPointsToPercent(
              props.funding_schedule?.bid_basis_points ?? null
            )}`}
            error={!isBidRateValid}
          />
        </Column>
        <Column>
          Purchase Date
          <DatePicker
            value={purchaseDate}
            onChange={handleChangeDate}
            minDate={getNextBusinessDay()}
          />
        </Column>
        <div>{submitButton}</div>
      </Body>
    </>
  );
};

export default FundingScheduleInputCardBody;
export { default as FundingScheduleInputCardFragments } from './fragments';
