import React from 'react';
import clsx from 'clsx';
import { IconMenu2, IconCircleXFilled, IconPlus } from '@tabler/icons-react';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DraggableAttributes } from '@dnd-kit/core';

export type PillVariant = 'primary' | 'muted';

export interface PillProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  label?: string;
  value?: string;
  variant?: PillVariant;
  isLoading?: boolean;
  isActive?: boolean;
  isDraggable?: boolean;
  isRemovable?: boolean;
  isAddableToList?: boolean;
  onRemove?: (id?: string) => void;
  onAdd?: (id?: string) => void;
  listeners?: SyntheticListenerMap;
  attributes?: DraggableAttributes;
  id?: string;
}

export const Pill = React.forwardRef<HTMLDivElement, PillProps>(
  ({
    label,
    value,
    isRemovable = false,
    isAddableToList = false,
    attributes,
    onRemove,
    onAdd,
    isDraggable = false,
    className,
    isLoading,
    listeners,
    isActive,
    id,
    ...rest
  }, ref) => {
    const pillClassName = clsx(
      'px-4 py-2 flex items-center select-none flex-row gap-2 bg-grey-950 rounded-full relative border-grey-800 border-[0.5px] max-w-fit',
      {
        '!border-pink-500': isActive,
        'animate-shake': isDraggable,
      },
      className
    );

    const handleKeyDown = (event: React.KeyboardEvent, callback?: () => void) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        callback?.();
      }
    };

    if (isLoading) {
      return (
        <div className="px-4 py-2 flex items-center justify-between select-none flex-row gap-2 bg-grey-950 rounded-full border-grey-800 border-[0.5px] w-28 animate-pulse">
          <div className="w-1/5 h-4 bg-grey-700 rounded"></div>
          <div className="w-10/12 h-4 bg-grey-700 rounded"></div>
        </div>
      );
    }

    return (
      <div {...rest} ref={ref} className={pillClassName}>
        <span className="font-heebo text-xs text-grey-500 font-light">{label}</span>
        <span className="font-pt-mono text-xs text-grey-50 font-light">{value}</span>
        {isDraggable && (
          <button
            className="text-grey-600 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:ring-opacity-50 rounded"
            {...listeners}
            {...attributes}
            aria-label={`Drag ${label}`}
          >
            <IconMenu2 size={12} />
          </button>
        )}
        {isRemovable && (
          <button
            onClick={() => onRemove?.(id)}
            onKeyDown={(e) => handleKeyDown(e, () => onRemove?.(id))}
            aria-label={`Remove ${label}`}
            className="text-grey-600 -top-1 -right-1 absolute hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-pink-400 focus:ring-opacity-50 rounded-full"
          >
            <IconCircleXFilled size={16} className="hover:text-pink-400" />
          </button>
        )}
        {isAddableToList && (
          <button
            onClick={() => onAdd?.(id)}
            onKeyDown={(e) => handleKeyDown(e, () => onAdd?.(id))}
            aria-label={`Add ${label}`}
            className="text-grey-600 hover:cursor-pointer ml-1 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:ring-opacity-50 rounded-full"
          >
            <IconPlus size={16} className="hover:text-pink-400" />
          </button>
        )}
      </div>
    );
  }
);
