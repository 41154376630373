import { gql } from '@apollo/client';
import { DocumentTemplatesFragments } from 'features/pages/portfolio/DocumentLibrary/DocumentTemplates.fragments';

const GET_DEAL_DOCUMENTS_FRAGMENT = gql`
  fragment DocumentFields on Document {
    id
    __typename
    name
    type
    documentUrl
    documentSizeBytes
    uploadedAt
    is_template
    uploadedBy {
      given_name
      family_name
    }
  }
`;

export const GET_DEAL_DOCUMENTS = gql`
  query GetDealDocuments($id: ID!, $fileType: FileType, $uploader: Uploader) {
    deal(id: $id) {
      id
      documents(fileType: $fileType, uploader: $uploader) {
        id
        ...DocumentFields
        ...TemplateDocument
      }
    }
  }
  ${GET_DEAL_DOCUMENTS_FRAGMENT}
  ${DocumentTemplatesFragments.document}
`;

export { GET_DEAL_DOCUMENTS_FRAGMENT };
