import { FloatingOverlay } from '@floating-ui/react';
import { styled } from 'style/ORSNNTheme';
import { typographyBodyPrimaryMedium } from './typography';

export const FloatingOverlayStyled = styled(FloatingOverlay)`
  background: rgba(0, 0, 0, 0.8);
  display: grid;
  place-items: center;
  z-index: 1
`;

export const ContentDiv = styled.div`
  box-shadow: 0px 16px 24px 0px rgba(13, 13, 13, 0.9), 0px 0px 1px 0px #0d0d0d;
  background-color: ${(props) => props.theme.color.bgSurface};
  padding: 30px 70px 40px;
  border-radius: 6px;
  color: ${(props) => props.theme.color.fgDefault};
  text-align: center;
  position: relative;
`;

export const DialogActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 30px;

  & > button:only-child {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledHeading = styled.div`
  color: ${(props) => props.theme.color.fgDefault};
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
`;

export const DialogBodyText = styled.div`
  ${typographyBodyPrimaryMedium}
  margin-top: 20px;
  margin-bottom: 30px;
  color: ${(props) => props.theme.color.fgDefault};
`;
