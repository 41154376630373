import { gql, useMutation, useQuery } from '@apollo/client';
import { ToggleButton } from 'common-ui';
import { useState } from 'react';

import './AppPrefs.scss';
import { DisableChat } from './__generated__/DisableChat';
import { EnableChat } from './__generated__/EnableChat';

const ENABLE_CHAT_MUTATION = gql`
  mutation EnableChat {
    enableChat {
      id
      is_chat_enabled
    }
  }
`;

const DISABLE_CHAT_MUTATION = gql`
  mutation DisableChat {
    disableChat {
      id
      is_chat_enabled
    }
  }
`;

const IS_CHAT_ENABLED_QUERY = gql`
  query IsChatEnabled {
    user {
      id
      company {
        id
        is_chat_enabled
      }
    }
  }
`;

const AppPrefs = () => {
  const { data, loading } = useQuery(IS_CHAT_ENABLED_QUERY);
  const [enableChat] = useMutation<EnableChat>(ENABLE_CHAT_MUTATION);
  const [disableChat] = useMutation<DisableChat>(DISABLE_CHAT_MUTATION);

  const handleToggle = async (checked: boolean) => {
    if (checked) {
      await enableChat();
    } else {
      await disableChat();
    }
  };

  return (
    <div className='app__prefs'>
      <table>
        <tr>
          <td className='heading'>Enable chat for your company</td>
          <td className='value'>
            { loading 
              ? <div>'Loading...'</div>
              : <ToggleButton
                  initialState={data?.user?.company?.is_chat_enabled}
                  checkedLabel="On"
                  uncheckedLabel="Off"
                  onChange={handleToggle}
                />
            }
          </td>
        </tr>
      </table>
    </div>
  );
};

export default AppPrefs;
