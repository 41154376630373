// uploadConfig.ts

interface UploadConfig {
  [key: string]: {
    allowedFileTypes: string[];
    message: string | null;
  };
}

export const uploadConfig: UploadConfig = {
  general: {
    allowedFileTypes: [],
    message: null,
  },
  dealDocuments: {
    allowedFileTypes: ['application/pdf', 'image/jpeg', 'image/tiff'],
    message: 'Only PDF, JPEG, and TIFF files are allowed for upload.',
  },
};
