import { Col } from './Table';
import { styled } from 'style/ORSNNTheme';

const Label = styled(Col)`
  color: ${(props) => props.theme.color.fgLabelDefault};
`;

const Value = styled.span`
  color: ${(props) => props.theme.color.fgDefault};
  font-family: PT Mono, ui-monospace, monospace;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LabelValuePair = styled(Col)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  gap: 0.5rem;
`;

type LabelAndValueProps = {
  label: string;
  value?: string;
};

const LabelAndValue = ({
  label,
  value = `   `,
}: LabelAndValueProps): JSX.Element => {
  return (
    <LabelValuePair>
      <Label>{label}</Label>
      <Value>{value.replace(/ /g, '\xa0')}</Value>
    </LabelValuePair>
  );
};

export {
  Label,
  Value,
  LabelValuePair,
  LabelAndValue, // TODO we cant remove these until DealCard is deprecated or we update the styles there
};
