import React, { FC, useState, useEffect } from 'react';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';
import { QUERY_KEYS } from '../shared';
import { ChartSkeletonLoader } from './ChartSkeleton';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import { CustomTooltip } from './CustomTooltip';

type TreasuryYieldsData = {
  'Treasury Yields': {
    labels: string[];
    series: {
      [key: string]: number[];
    };
  };
};

type DataPoint = {
  date: string;
  [key: string]: string | number;
};

type TreasuryYieldsProps = {
  dataUrl?: string;
  width?: number;
  height?: number;
  className?: string;
};

const colors = ['#e75ee4', '#3ccece', '#8490ec'];

export const TreasuryYields: FC<TreasuryYieldsProps> = ({
  dataUrl,
  width = 620,
  height = 382,
  className = '',
}) => {
  const { data, isLoading } = useReactQuery<TreasuryYieldsData, Error, DataPoint[]>({
    queryKey: [QUERY_KEYS.TREASURY_YIELDS, dataUrl],
    queryFn: async () => {
      if (!dataUrl) throw new Error('No URL provided');
      const response = await fetch(dataUrl);
      return response.json();
    },
    enabled: !!dataUrl,
    select: (data): DataPoint[] => {
      const allData: DataPoint[] = [];
      data['Treasury Yields'].labels.forEach((label, index) => {
        const dataPoint: DataPoint = { date: label };
        Object.keys(data['Treasury Yields'].series).forEach((key) => {
          dataPoint[key] = data['Treasury Yields'].series[key][index];
        });
        allData.push(dataPoint);
      });
      return allData;
    },
  });

  const [selectedSeries, setSelectedSeries] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<DataPoint[]>([]);

  useEffect(() => {
    if (data) {
      const seriesNames = Object.keys(data[0]).filter((key) => key !== 'date');
      setSelectedSeries(seriesNames[0]);
    }
  }, [data]);

  useEffect(() => {
    if (data && selectedSeries) {
      setFilteredData(data.map((d) => ({ date: d.date, [selectedSeries]: d[selectedSeries] })));
    }
  }, [data, selectedSeries]);

  const handleSeriesChange = (selectedOption: any) => {
    setSelectedSeries(selectedOption.value);
  };

  const seriesNames = data ? Object.keys(data[0]).filter((key) => key !== 'date') : [];
  const categoryOptions = seriesNames.map((seriesName) => ({
    value: seriesName,
    label: seriesName,
  }));

  if (isLoading || !data) {
    return <ChartSkeletonLoader  width={width} height={height} />;
  }

  return (
    <div className={`bg-background-surface rounded-lg px-4 py-3 mx-auto mt-16 w-fit relative ${className}`}>
      <h3 className='text-center text-slate-100 font-heebo text-lg mb-4'>Treasury Yields</h3>
      <Dropdown
        className='w-[120px]'
        options={categoryOptions}
        onChange={handleSeriesChange}
        placeholder='Select Series'
        value={categoryOptions.find(option => option.value === selectedSeries)}
      />
      <div className="relative" style={{ width, height: height + 20 }}>
        <div
          className="absolute inset-0"
          style={{
            background: 'radial-gradient(ellipse at center, rgba(60,206,206,0.2) 0%, rgba(255,0,210,0) 70%)',
            opacity: 0.4,
          }}
        />
        <LineChart
          width={width}
          height={height}
          data={filteredData}
          margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
        >
          <XAxis
            dataKey="date"
            stroke="#BBC5D7"
            tickMargin={16}
          >
            <Label
              value="Date"
              offset={10}
              position="bottom"
              style={{ fill: '#BBC5D7', fontSize: 12, opacity: 0.7 }}
            />
          </XAxis>
          <YAxis
            domain={['auto', 'auto']}
            stroke="#BBC5D7"
            padding={{ top: 24, bottom: 24 }}
            tickMargin={10}
          >
            <Label
              value="Yield %"
              angle={-90}
              position="insideLeft"
              style={{ fill: '#BBC5D7', fontSize: 12, opacity: 0.7 }}
              offset={10}
            />
          </YAxis>
          <CartesianGrid stroke="#28303E" />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign='top' />
          {selectedSeries && (
            <Line
              type="linear"
              dataKey={selectedSeries}
              stroke={colors[0]}
              dot={false}
              strokeWidth={2}
              filter="drop-shadow(0px 0px 2px rgba(132, 144, 236, 0.53))"
            />
          )}
        </LineChart>
      </div>
    </div>
  );
};
