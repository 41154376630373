import React from 'react';
import { GetDealCounterparty_deal_counterparty_PrivateCompany_form5300LLMAnalysis as Form5300AnalysisData } from 'features/deals/DealStages/EventActionCards/CounterpartyInsightsBody/__generated__/GetDealCounterparty';

interface Form5300AnalysisProps {
  data: Form5300AnalysisData | null;
}

const Form5300Analysis: React.FC<Form5300AnalysisProps> = ({ data }) => {
  if (!data) return null;

  const emergingLendingRisks: Array<[string, string]> = [
    ['Portfolio Growth Rates', data.emergingLendingRisks?.portfolioGrowthRates || 'N/A'],
    ['Participation Exposures', data.emergingLendingRisks?.participationExposures || 'N/A'],
    ['Increasing Delinquencies', data.emergingLendingRisks?.increasingDelinquencies || 'N/A'],
    ['Benchmark Comparisons', data.emergingLendingRisks?.benchmarkComparisons || 'N/A'],
    ['Recession Sensitivity Analysis', data.emergingLendingRisks?.recessionSensitivityAnalysis || 'N/A'],
  ];

  const dataDrivenInsights: string = data.dataDrivenInsights.join(', ') || 'N/A';

  return (
    <div className='pt-2.5 rounded-lg shadow-sm mt-4'>
      <h6 className="mb-2.5">Regulatory AI Analysis</h6>
      <p className='font-semibold text-slate-200'>Emerging Lending Risks</p>
      <ol className='list-decimal pl-5 text-slate-400 font-heebo border-b border-slate-500'>
        {emergingLendingRisks.map(([key, value]) => (
          <li key={key} className='border-b border-slate-700 py-2 last:border-0'>
            <span className='font-semibold text-slate-200'>{key}: </span>{value}
          </li>
        ))}
      </ol>
      <p className='font-semibold text-slate-200 mt-3'>Data-Driven Insights</p>
      <ul className='pl-5 text-slate-400 font-heebo'>
        <li className='border-b border-slate-700 py-2'>
          {dataDrivenInsights}
        </li>
      </ul>
    </div>
  );
};

export default Form5300Analysis;
