import { useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { UserModel } from 'features/pages/account/User';
import Avatar from '../Avatar/Avatar';
import { DropdownMenuItem } from './DropdownMenuItem';
import './UserContextMenu.scss';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { AuthContext, AuthContextAction } from 'context/AuthContext';
import { GET_USER_PERMISSIONS } from 'query/permissionsQueries';

const AccountRoutePrefix = '/account';

const menuItems = [
  {
    label: 'User',
    iconName: 'user',
    link: `${AccountRoutePrefix}?section=user`,
  },
  {
    label: 'Security',
    iconName: 'lock',
    link: `${AccountRoutePrefix}?section=security`,
  },
  {
    label: 'User Role & Permissions',
    iconName: 'shield-check',
    link: `${AccountRoutePrefix}?section=permissions`,
    adminOnly: true,
  },
  {
    label: 'Application Preferences',
    iconName: 'check-mark',
    link: `${AccountRoutePrefix}?section=preferences`,
  },
  {
    label: 'Support',
    iconName: 'question-mark',
    link: `https://orsnn.zendesk.com/hc/en-us`,
    openInNewTab: true,
  },
  {
    label: 'Contact Us',
    iconName: 'phone',
    link: `https://orsnn.zendesk.com/hc/en-us/requests/new`,
    openInNewTab: true,
  },
];

interface UserContextMenuProps {
  userInfo?: UserModel;
}

export const UserContextMenu = ({ userInfo }: UserContextMenuProps) => {
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [lastLogin, setLastLogin] = useState<string>('');

  useQuery(GET_USER_PERMISSIONS, {
    variables: { requestData: { userEmail: userInfo?.email || '' } },
    skip: !userInfo?.email,
    onCompleted: (data) => {
      if (data.userManagementGetUser && data.userManagementGetUser.user) {
        setLastLogin(data.userManagementGetUser.user.lastLoginDate);
      }
    },
  });

  return (
    <div className="user_context_container">
      <div className="user-info">
        <Avatar name={`${userInfo?.givenName || ''} ${userInfo?.familyName || ''}`} />
        <div className="user-info-text">
          <span>{`${userInfo?.givenName || ''} ${userInfo?.familyName || ''}`.trim()}</span>
          <span className="email">{userInfo?.email || ''}</span>
          <div className="last-login">
            <span>Latest login</span>
            <span>{lastLogin ? new Date(parseInt(lastLogin, 10)).toLocaleString() : ''}</span>
          </div>
        </div>
      </div>
      <div className="items">
        <hr />
        {menuItems.map((m) => (
          <DropdownMenuItem
            key={m.label}
            label={m.label}
            iconType="svg"
            iconName={m.iconName}
            link={m.link}
            newTab={m.openInNewTab}
          />
        ))}
        <hr />
        <DropdownMenuItem
          label="Log Out"
          iconType="svg"
          iconName="log-out"
          onClick={() => {
            Auth.signOut().then(() => dispatch({ type: AuthContextAction.SIGN_OUT }));
            navigate('/');
          }}
        />
      </div>
    </div>
  );
};
