import React from 'react';
import './avatar.scss';

interface AvatarProps {
  name: string;
  width?: string | number;
  height?: string | number;
  fontSize?: string | number;
}

const Avatar = ({
  name,
  width = '30px',
  height = '30px',
  fontSize,
}: AvatarProps) => {
  const initial = name.charAt(0);

  return (
    <div className="avatar" style={{
      width,
      height,
      fontSize,
    }}>{initial}</div>
  )
};

export default Avatar;
