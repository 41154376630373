/**
 * SelectionStatusLine.tsx
 *
 * Component to display the status of loan selection and provide actions to select all loans or clear the selection.
 *
 * It was inspired by Gmail's selection status line (e.g. "All 50 conversations on this page are selected").
 */
import React from 'react';
import { styled } from 'style/ORSNNTheme';

/**
 * Enum representing the possible states of loan selection.
 */
export enum SelectionStatus {
  None,
  PageSelected,
  AllSelected,
}

/**
 * Props for the SelectionStatusLine component.
 */
export interface SelectionStatusLineProps {
  status: SelectionStatus;
  numberSelectedOnPage: number;
  totalItems: number;
  onSelectAll: () => void;
  onClear: () => void;
}

/* Styles */

const Button = styled.button`
  background: none;
  border: none;
  color: ${(props) => props.theme.color.fgDefault};
  cursor: pointer;
  text-decoration: underline;
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.color.infoDefault};
  text-align: center;
`;

const Content = styled.div`
  padding: 10px;
  color: ${(props) => props.theme.color.fgDefault};
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
`;

/**
 * Component to display the status of loan selection and provide actions to select all loans or clear the selection.
 */
export const SelectionStatusLine: React.FC<SelectionStatusLineProps> = ({
  status,
  numberSelectedOnPage,
  totalItems,
  onClear,
  onSelectAll,
}) => {
  const totalItemsLabel = totalItems.toLocaleString();

  // If no loans are selected, or there is only one page (all loans are selected), don't show the status line
  if (numberSelectedOnPage == totalItems || status === SelectionStatus.None) {
    return null;
  }

  const renderContent = () => {
    switch (status) {
      case SelectionStatus.PageSelected:
        return (
          <>
            <span>
              All <b>{numberSelectedOnPage}</b> loans on this page are selected.
            </span>
            <Button
              onClick={(e) => {
                e.preventDefault();
                onSelectAll();
              }}
            >
              Select all <b>{totalItemsLabel}</b> loans that match this filter?
            </Button>
          </>
        );

      case SelectionStatus.AllSelected:
        return (
          <>
            <span>
              All <b>{totalItemsLabel}</b> loans that match this filter are
              selected.
            </span>
            <Button
              onClick={(e) => {
                e.preventDefault();
                onClear();
              }}
            >
              Clear Selection
            </Button>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Container>
      <Content>{renderContent()}</Content>
    </Container>
  );
};
