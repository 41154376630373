import { PropsWithChildren } from 'react';
import { IndeterminateCheckboxProps, IndeterminateCheckbox } from './IndeterminateCheckbox';

export type CheckboxWithLabelProps = PropsWithChildren<IndeterminateCheckboxProps>;

export function CheckboxWithLabel(props: CheckboxWithLabelProps){
  const { children, disabled, ...rest } = props;
  return (
    <label className={`inline-flex items-center gap-4 cursor-pointer select-none ${disabled ? 'opacity-50' : ''}`}>
      <IndeterminateCheckbox {...rest} />
      {children}
    </label>
  );
}
