import { styled } from 'style/ORSNNTheme';

export type Alignment = 'left' | 'center' | 'right';

export type ColumnMeta = {
  alignment: Alignment;
  numeric: boolean;
  headerNoPill?: boolean;
};

const DEFAULT_CELL_PADDING = 'padding: 18px 10px 18px 8px;';
export const alignments = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export const THead = styled.thead`
  background-color: ${({ theme }) =>
    theme.color.bgSurface}; //this isn't exactly like figma
  position: sticky;
  top: 0;
  height: 48px;
`;

export const TH = styled.th<{ $alignment: Alignment; $isSortable: boolean }>`
  cursor: ${({ $isSortable }) => ($isSortable ? 'pointer' : 'default')}};
  text-align: ${({ $alignment }) => $alignment};
  ${DEFAULT_CELL_PADDING};

  svg {
      scale: 0.7;
      fill: white
  }
`;

export const Table = styled.table`
  border: 0;
  border-collapse: collapse;
  width: 100%;
  background-color: ${({ theme }) => theme.color.bgCanvas};
  overflow: auto;
`;

export const TBody = styled.tbody``;

export const TD = styled.td`
  border: none;
  ${DEFAULT_CELL_PADDING}
`;

//should the typography be different for numeric and non-numeric cells?
export const Cell = styled.div<{ $alignment: Alignment; $numeric: boolean }>`
  color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  white-space: nowrap;
  font: ${({ theme }) => theme.typography.dataP3};
  justify-content: ${({ $alignment }) => alignments[$alignment]};
`;

export const IconBtnWrapper = styled.span`
  background: none;
  border: none;
  display: none;
  padding-right: 8px;
`;

export const HoverContent = styled.div`
  display: none;
`;

export const TR = styled.tr<{
  $isSubRow: boolean;
  $isLightRow: boolean;
  $customBackgroundColor?: string;
}>`
  ${({ theme, $isSubRow, $isLightRow, $customBackgroundColor }) => {
    const subRowBackgroundColor = theme.color.black;
    const lightRowBackgroundColor = theme.color.grey950;
    const regularRowBackgroundColor = theme.color.black;

    // If $customBackgroundColor is provided, use that
    if ($customBackgroundColor) {
      return `background-color: ${$customBackgroundColor};`;
    }

    return `
      background-color: ${
        $isSubRow
          ? subRowBackgroundColor
          : $isLightRow
          ? lightRowBackgroundColor
          : regularRowBackgroundColor
      };
    `;
  }}
  &:hover {
    ${IconBtnWrapper} {
      display: flex;
    }

    // Show the HoverContent when the row is hovered
    ${HoverContent} {
      display: block;
    }
  }
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const TableContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const EmptyCell = styled.td``;
