// TODO generate this config at build time by reading values from from env variables or buildspec
// calling out to the orsnn-app-api-will stack to get the values
const config = {
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://a5q1iqmzhi.execute-api.us-west-2.amazonaws.com/prod/graphql',
  },
  logs: {
    REGION: 'us-west-2',
    URL: 'https://a5q1iqmzhi.execute-api.us-west-2.amazonaws.com/prod/log',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_MiSKYkTdO',
    APP_CLIENT_ID: 'r7fqm4d8t2jeu46v3n65ev2h6',
    IDENTITY_POOL_ID: 'us-west-2:0405a2e0-bd2b-4853-8ccc-c91fd5940fed',
  },
  fileGateway: {
    REGION: 'us-west-2',
    URL: 'https://a5q1iqmzhi.execute-api.us-west-2.amazonaws.com/prod/files',
  },
  smartHeaderGateway: {
    REGION: 'us-west-2',
    URL: 'https://a5q1iqmzhi.execute-api.us-west-2.amazonaws.com/prod/smart_header_mapping',
  },
  website: {
    URL: 'https://www.will.orsnn.com',
  },
  pinpoint: {
    REGION: 'us-west-2',
    APP_ID: 'e78b41affbd849d49f1f2c3b9ba87349',
  },
};

export default config;
