import { BaseButton, SvgIcon } from 'common-ui';
import Dropzone from 'react-dropzone';
import { styled } from 'style/ORSNNTheme';

export interface FileDropZoneProps {
  onFileDrop?: (file: File) => void;
  allowedFileTypes?: string[];
  width?: string;
  disabled?: boolean;
}

export const FileDropZone = ({ onFileDrop, allowedFileTypes = [], width, disabled = false }: FileDropZoneProps) => {
  const handleDrop = async (acceptedFiles: File[]) => {
    for (const file of acceptedFiles) {
      if (onFileDrop) {
        onFileDrop(file);
      }
    }
  };

  return (
    <Dropzone onDrop={handleDrop} multiple={true} maxFiles={20} accept={allowedFileTypes.join(',')} disabled={disabled} >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps({ 'aria-label': 'File upload', accept: allowedFileTypes.join(',') })} disabled={disabled} />
            <DropZoneContainer width={width}>
              <SvgIcon name="document-upload" />
              Drag & Drop your file(s)
              <BaseButton type="tertiary" size="medium" label="browse" disabled={disabled}>
                Browse to Upload
              </BaseButton>
            </DropZoneContainer>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

const DropZoneContainer = styled.div<{ width?: string }>`
  width: ${(props) => props.width || '744px'};
  height: 96px;
  color: ${(props) => props.theme.color.slate100};
  background-color: ${(props) => props.theme.color.bgSurface};
  border: 1px dashed ${(props) => props.theme.color.accentEmphasis};
  border-radius: 4px;

  justify-content: center;
  gap: 25px;
  align-items: center;
  display: flex;
`;
