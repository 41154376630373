import { CardWrapper } from './CardWrapper';
import {
  CounterpartyCard_CounterpartyCard_counterparty as Counterparty,
  CounterpartyCard_CounterpartyCard,
  CounterpartyCard_CounterpartyCard_counterparty_PrivateCompany as PrivateCompany,
  CounterpartyCard_CounterpartyCard_counterparty_PublicCompany as PublicCompany,
} from '../DealStages/EventActionCards/CounterpartyCardBody/__generated__/CounterpartyCard';
import { styled } from 'style/ORSNNTheme';
import _ from 'lodash';
import { gql } from '@apollo/client';
import { Spinner } from 'common-ui';

const CounterpartyBasicInfoFragment = {
  company: gql`
    fragment CounterpartyBasicInfo on Company {
      __typename
      type
      ... on PublicCompany {
        obf_assets
        obf_net_worth
      }
      ... on PrivateCompany {
        id
        name
        regulatory_number
        regulatory_agency
        t_assets
        t_liabilities
        t_net_worth
        t_interest_income
        t_noninterest_income
        t_interest_expense
        t_noninterest_expense
      }
    }
  `
};

type DesiredPublicFields = Omit<PublicCompany, '__typename' | 'id'>;
type DesiredPrivateFields = Omit<PrivateCompany, '__typename' | 'id' | 'form5300LLMAnalysis'>;

const PUBLIC_COMPANY_LABEL_DICTIONARY: Record<
  keyof DesiredPublicFields,
  string
> = {
  type: 'Institution Type',
  obf_net_worth: 'Net Worth',
  obf_assets: 'Total Assets',
} as const;

const PRIVATE_COMPANY_LABEL_DICTIONARY: Record<
  keyof DesiredPrivateFields,
  string
> = {
  type: 'Institution Type',
  name: 'Name',
  regulatory_agency: 'Federal Regulator',
  regulatory_number: 'Regulatory Number',
  t_net_worth: 'Net Worth',
  t_assets: 'Total Assets',
  t_liabilities: 'Total Liabilities',
  t_interest_income: 'Total Interest Income',
  t_noninterest_income: 'Total Non-Interest Income',
  t_interest_expense: 'Total Interest Expenses',
  t_noninterest_expense: 'Total Non-Interest Expenses',
} as const;

const InfoLine = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.color.slate400};
  padding: 7px 0px;
  color: ${({ theme }) => theme.color.fgLabelDefault};

  .value {
    color: ${({ theme }) => theme.color.slate100};
  }
`;

const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
`;

type CounterpartyCardProps = {
  loading: boolean;
  active: boolean;
  counterparty?: Counterparty;
};

function isPublicCompany(
  counterparty: Counterparty
): counterparty is PublicCompany {
  return counterparty.__typename === 'PublicCompany';
}

const CounterpartyDetails = ({
  counterparty,
}: CounterpartyCard_CounterpartyCard) => {
  let readableInfo: Array<[string, string]>;

  if (isPublicCompany(counterparty)) {
    counterparty = _.pick(
      counterparty,
      Object.keys(PUBLIC_COMPANY_LABEL_DICTIONARY)
    ) as PublicCompany;
    readableInfo = Object.entries(counterparty).map(([key, value]) => {
      const label =
        PUBLIC_COMPANY_LABEL_DICTIONARY[key as keyof DesiredPublicFields];
      return [label, value];
    });
  } else {
    const counterpartyLabels = _.pick(
      counterparty,
      Object.keys(PRIVATE_COMPANY_LABEL_DICTIONARY)
    ) as PrivateCompany;
    readableInfo = Object.entries(counterpartyLabels).map(([key, value]) => {
      const label =
        PRIVATE_COMPANY_LABEL_DICTIONARY[key as keyof DesiredPrivateFields];
      return [label, value];
    });
  }

  return (
    <>
      <Details>
        {readableInfo.map(([key, value]) => (
          <InfoLine key={key}>
            <span>{key}</span>
            <span className="value">{value}</span>
          </InfoLine>
        ))}
      </Details>
    </>
  );
};


function CounterpartyCard(props: CounterpartyCardProps) {
  if (props.loading)
    return (
      <CardWrapper {...props} name="counterparty">
        <Spinner loading={props.loading} positionRelative />
        {/* Quick blurb telling them that wait times up to 15 seconds are possible as we are doing LLM analysis of the counterParty */}
        <p>Wait times up to 15 seconds are possible as we are doing LLM analysis of the Counterparty</p>
      </CardWrapper>
    );

  if (props.counterparty == null)
    return <CardWrapper {...props} name="counterparty" />;

  const counterparty: Counterparty = props.counterparty;
  return (
    <CardWrapper {...props} name="counterparty">
      {!props.loading && props.active ? (
        <CounterpartyDetails actions={[]} counterparty={counterparty} />
      ) : null}
    </CardWrapper>
  );
}

export { CounterpartyCard, CounterpartyDetails, CounterpartyBasicInfoFragment };
