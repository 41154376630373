import { gql } from '@apollo/client';
import { DocumentDetailsFragments } from 'features/pages/portfolio/DocumentLibrary/DocumentDetails.fragments';
import { DocumentTemplatesFragments } from 'features/pages/portfolio/DocumentLibrary/DocumentTemplates.fragments';

const GET_DOCUMENT_LIBRARY = gql`
  query GetDocumentLibrary {
    user {
      id
      company {
        id
        transactionDocs: documents(fileType: TRANSACTION_DOCUMENT) {
          id
          ...Document
        }
        lendingDocs: documents(fileType: LENDING_POLICY) {
          id
          ...Document
        }
        dqDocs: documents(fileType: DQ_AND_CHARGE_OFF) {
          id
          ...Document
        }
        sampleCollateralDocs: documents(fileType: SAMPLE_COLLATERAL) {
          id
          ...Document
        }
        otherDocs: documents(fileType: OTHER) {
          id
          ...Document
        }
        templates {
          id
          ...TemplateDocument
        }
      }
    }
  }

  ${DocumentDetailsFragments.document}
  ${DocumentTemplatesFragments.document}
`;

const UPDATE_FILE_TEMPLATE = gql`
  mutation UpdateFileTemplate($id: ID!, $isTemplate: Boolean!) {
    updateFileTemplate(id: $id, isTemplate: $isTemplate) {
      id
      is_template
    }
  }
`;

export { GET_DOCUMENT_LIBRARY, UPDATE_FILE_TEMPLATE };
