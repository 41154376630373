import { gql } from '@apollo/client';

const CREATE_DILIGENCE_NOTE = gql`
  mutation CreateDiligenceNote($input: CreateDiligenceNoteInput!) {
    addDiligenceNote(input: $input) {
      id
      created_time
      diligence_id
      note
      author {
        given_name
        family_name
        role
        is_current_user
      }
    }
  }
`;

const CREATE_COLLATERAL_NOTE = gql`
  mutation CreateCollateralNote($input: CreateCollateralNoteInput!) {
    addCollateralNote(input: $input) {
      id
      created_time
      collateral_id
      note
      author {
        given_name
        family_name
        role
        is_current_user
      }
    }
  }
`;

const UPDATE_COLLATERAL_STATUS = gql`
  mutation UpdateCollateralStatus(
    $id: ID!
    $collateralStatus: CollateralStatus!
  ) {
    updateCollateralStatus(id: $id, collateralStatus: $collateralStatus) {
      id
      collateral_status
      __typename
    }
  }
`;

const UPDATE_DILIGENCE_STATUS = gql`
  mutation UpdateDiligenceStatus($id: ID!, $diligenceStatus: DiligenceStatus!) {
    updateDiligenceStatus(id: $id, diligenceStatus: $diligenceStatus) {
      id
      status
      __typename
    }
  }
`;

export {
  CREATE_DILIGENCE_NOTE,
  CREATE_COLLATERAL_NOTE,
  UPDATE_COLLATERAL_STATUS,
  UPDATE_DILIGENCE_STATUS,
};
