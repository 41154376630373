import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import normalIcon from '../../assets/svgs/radio-normal.svg';
import checkedIcon from '../../assets/svgs/radio-checked.svg';

const RadioButtonWrapper = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  &:hover .radio-icon {
    background-image: url(${normalIcon});
  }
`;

const RadioIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${normalIcon});
  background-size: cover;
  margin-right: 8px;
`;

const HiddenRadioInput = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${RadioIcon} {
    background-image: url(${checkedIcon});
  }
`;

interface RadioButtonProps {
  label: string;
  name: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
}

export const RadioButton: React.FC<RadioButtonProps> = ({ label, onChange, checked, disabled, ...props }) => {
  return (
    <RadioButtonWrapper disabled={disabled}>
      <HiddenRadioInput {...props} onChange={onChange} checked={checked} disabled={disabled} />
      <RadioIcon className="radio-icon" />
      {label}
    </RadioButtonWrapper>
  );
};

const FormikRadioButton: React.FC<RadioButtonProps> = (props) => {
  const [field] = useField({ ...props, type: 'radio' });
  return <RadioButton {...field} {...props} />;
};

export default FormikRadioButton;
