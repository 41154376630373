import { gql } from '@apollo/client';
import BidCardFragments from './BidCardBody/fragments';
import CarveDetailsCardFragments from './CarveDetailsCardBody/fragments';
import CounterpartyCardFragments from './CounterpartyCardBody/fragments';
import DealDocumentsCardFragments from './DealDocumentsCardBody/fragments';
import FundingScheduleDetailsCardFragments from './FundingScheduleDetailsCardBody/fragments';
import FundingScheduleInputCardFragments from './FundingScheduleInputCardBody/fragments';
import WireConfirmationCardFragments from './WireConfirmationCardBody/fragments';
import { StratsCardFragments } from 'features/deals/dealCards/StratsCard';
import { LoansCardFragments } from 'features/deals/dealCards/LoansCard';
import { DiligenceCardFragments } from 'features/deals/dealCards/Diligence/DiligenceCard.fragments';
import BiddingAndPricingControllerFragments from 'features/pages/marketplace/BiddingAndPricing/BiddingAndPricingController.fragments';

const PlaceholderCardFragments = {
  TimelineCard: gql`
    fragment PlaceholderCard on TimelineCard {
      ... on PlaceholderCard {
        tooltip
      }
    }
  `,
};

const EventActionCardFragments = {
  TimelineCard: gql`
    fragment EventActionCardTimelineCard on TimelineCard {
      last_updated_date
      ...CounterpartyCard
      ...LoansCard
      ...StratsCard
      ...DealDocumentsCard
      ...CarveDetailsCard
      ...BidCard
      ...BiddingAndPricingControllerCard
      ...FundingScheduleInputCard
      ...FundingScheduleDetailsCard
      ...WireConfirmationCard
      ...DiligenceCard
      ...PlaceholderCard
    }
    ${CounterpartyCardFragments.TimelineCard}
    ${LoansCardFragments.TimelineCard}
    ${StratsCardFragments.TimelineCard}
    ${DealDocumentsCardFragments.TimelineCard}
    ${CarveDetailsCardFragments.TimelineCard}
    ${BidCardFragments.TimelineCard}
    ${BiddingAndPricingControllerFragments.TimelineCard}
    ${FundingScheduleInputCardFragments.TimelineCard}
    ${FundingScheduleDetailsCardFragments.TimelineCard}
    ${WireConfirmationCardFragments.TimelineCard}
    ${DiligenceCardFragments.TimelineCard}
    ${PlaceholderCardFragments.TimelineCard}
  `,
};

export default EventActionCardFragments;
