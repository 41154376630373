import {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from 'react';
import { Message } from './Message';
import { ListDealMessages_dealMessages_entities } from './__generated__/ListDealMessages';

interface MessageStreamProps {
  messages?: ListDealMessages_dealMessages_entities[];
  isLoading?: boolean;
}

export interface MessageStreamHandle {
  getFirstMessageElement: () => HTMLDivElement | null;
}

const UnwrappedMessageStream: ForwardRefRenderFunction<
  MessageStreamHandle,
  MessageStreamProps
> = ({ messages, isLoading }, ref) => {
  const firstMessage = useRef<HTMLDivElement | null>(null);

  useImperativeHandle(ref, () => ({
    getFirstMessageElement() {
      return firstMessage.current;
    },
  }));
  return (
    <div className='flex flex-col gap-5 h-max overflow-hidden'>
      {isLoading && (
        <Message
          key="loading"
          dealMessage={{
            author: {
              family_name: 'null',
              given_name: 'null',
              is_current_user: false,
            },
            id: 'null',
            message: 'null',
            created_time: 'null',
          }}
          isLoading
        />
      )}
      {messages?.map((message, i) => (
        <Message
          key={message.id}
          dealMessage={message}
          ref={i === 0 ? firstMessage : void 0}
        />
      ))}
    </div>
  );
};

export const MessageStream = forwardRef(UnwrappedMessageStream);
