import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_COMPANY_REGULATORY_METRICS } from 'query/companyRegulatoryMetrics';
import { Link, useNavigate } from 'react-router-dom';
import { formatCurrency, formatPercent } from 'configs/columns';
import { GetCompanyRegulatoryMetrics, GetCompanyRegulatoryMetricsVariables } from 'query/__generated__/GetCompanyRegulatoryMetrics';
import { Spinner } from 'baseui/spinner';
import { BaseButton } from 'common-ui';
import { CREATE_DEAL_MUTATION } from 'features/loanPool/PoolHeader';
import { RequestAccessPublicListing } from './__generated__/RequestAccessPublicListing';

type RequestAccessProps = {
  listingId: string;
  listingDetails: RequestAccessPublicListing;
};

const RequestAccess: React.FC<RequestAccessProps> = ({ listingDetails, listingId }) => {
  const navigate = useNavigate();
  const [createDeal, { loading: creatingDeal }] = useMutation(CREATE_DEAL_MUTATION);

  const { loading, data } = useQuery<GetCompanyRegulatoryMetrics, GetCompanyRegulatoryMetricsVariables>(GET_COMPANY_REGULATORY_METRICS, {
    variables: { id: listingId },
  });

  const company = data?.listing?.company;

  const handleAccept = () => {
    createDeal({ variables: { listingId } }).then((response) => {
      const dealId = response.data?.createDeal.id;

      if (dealId) {
        navigate(`/marketplace/deal/${dealId}`);
      }
    });
  };

  const stats = useMemo(() => [
    { label: 'UPB', value: formatCurrency(listingDetails.t_current_balance_cents) },
    { label: 'GWAC', value: formatPercent(listingDetails.wa_coupon ?? 0, 3) },
    { label: 'NWAC', value: formatPercent((listingDetails.wa_coupon ?? 0) - (listingDetails.servicing_fee_rate ?? 0), 3) },
    { label: 'WAM', value: listingDetails.wa_remaining_loan_terms_months ? `${listingDetails.wa_remaining_loan_terms_months} Mo` : 'NO DATA' },
    { label: 'WALA', value: listingDetails.wa_loan_age_months ? `${listingDetails.wa_loan_age_months} Mo` : 'NO DATA' },
  ], [listingDetails]);

  const metrics = useMemo(() => ({
    'Loans/Shares': company?.regulatory_metrics.loans_shares_rank,
    'Delinquency': company?.regulatory_metrics.delinquency_rank,
    'Charge Offs': company?.regulatory_metrics.charge_off_ratio_rank,
    'Net Worth Ratio': company?.regulatory_metrics.net_worth_ratio_rank,
    'Expense Ratio': company?.regulatory_metrics.expense_ratio_rank,
    'ROA': company?.regulatory_metrics.roa_rank,
    'ROE': company?.regulatory_metrics.roe_rank,
    'Allowance/Total Loans': company?.regulatory_metrics.allowance_total_loans_rank,
    'Efficiency Ratio': company?.regulatory_metrics.efficiency_ratio_rank,
    'IEA/Total Assets': company?.regulatory_metrics.iea_total_assets_rank
  }), [company]);

  const renderOfferDetails = () => {
    return (
      <div className="mt-3 max-w-[290px] w-full">
        <h3 className="text-slate-200 text-sm uppercase mb-3">Offer Details</h3>
        {stats.map(({label, value}) => (
          <div key={label} className='flex justify-between border-t border-slate-600 py-2 px-3'>
            <span className='text-slate-200 text-sm'>{label}</span>
            <span className='text-slate-200 text-sm'>{value}</span>
          </div>
        ))}
      </div>
    );
  };

  const generateChart = (label: string, rank: number | undefined) => {
    let render = <p className='text-sm mr-2 uppercase'>No Data</p>;

    if (rank) {
      const textPositionClass = rank > 70 ? 'left-[-35px] pr-2' : 'left-0 pl-2';
      render = <>
        <div className="relative w-[200px] h-[18px] rounded-sm bg-custom-gradient">
          <div className='relative h-full top-0' style={{ left: `${rank}%` }}>
            <div className="absolute left-0 top-0 h-full w-1 bg-white"></div>
            <div className={`absolute h-full flex items-center text-white text-sm font-semibold ${textPositionClass}`}>{rank} %</div>
          </div>
        </div>
      </>;
    }

    return (
      <div className="flex items-center justify-between mb-4" key={label}>
        <p className='text-slate-400 text-sm'>{label}</p>
        <div className='flex items-center'>
          {render}
        </div>
      </div>
    );
  };

  const averageIndex = company ?
    Object.values(metrics).reduce((acc: number, rank) => acc + (rank ?? 0), 0) / Object.values(metrics).length : 0;

  return (
    <div className="relative">
      <div className="grid grid-cols-2 gap-6">
        <div>
          {
            loading ?
                <div className='flex justify-center items-center h-full w-full'>
                  <Spinner size={34} />
                </div> :
                <>
                  <div className="border-t border-b border-slate-500 pt-[7px] pb-[9px] flex justify-between mb-2">
                    <div className='flex'>
                      <p className='text-slate-200 text-sm uppercase'>Regulatory Index</p>
                    </div>
                    <p className='text-slate-200 text-sm'>{averageIndex}th Index</p>
                  </div>

                  {Object.entries(metrics).map(([label, rank]) => generateChart(label, rank))}
                </>
          }
        </div>
        <div>
          <div>
            <p className='text-slate-200 text-sm uppercase mb-3'>Pool Publish Settings</p>
            <p className="text-slate-200 text-sm mb-2">Once the seller approves your data request for data, you will be able to access the loan tape, generate strats and run cashflows on this pool of loans. You can place a bid at anytime.</p>
            <p className="text-slate-200 text-sm">Track your buy-side deals in the <Link to="../active_trades" className="text-purple-500 uppercase underline underline-offset-2">Active Trades</Link> section.</p>
          </div>
          <div className="mt-4 flex justify-start gap-2">
            <BaseButton
              className='text-white bg-green-600 border-green-600 hover:bg-green-700 hover:border-green-700 hover:text-white'
              type="primary"
              size='small'
              label="Accept"
              onClick={handleAccept}
            >
              Request Access
            </BaseButton>
            <BaseButton
              className='text-white border-green-600 hover:bg-green-700 hover:border-green-700 hover:text-white'
              label="View"
              size='small'
              type="secondary"
              onClick={() => navigate(`/marketplace/listing/${listingId}`)}
            >
              View Stratifications
            </BaseButton>
          </div>
          <div className="mt-3 max-w-[290px] w-full">
            {renderOfferDetails()}
          </div>
        </div>
      </div>
      {creatingDeal && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <Spinner size={70} />
        </div>
      )}
    </div>
  );
};

export default RequestAccess;
