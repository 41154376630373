import classNames from 'classnames';
import { SvgIcon } from '../Icons/SvgIcon/SvgIcon';
import './DropdownMenuItem.scss';

type Icon = 'svg';

export interface DropdownMenuItemProps {
  label: string;
  iconType?: Icon;
  iconName?: string;
  link?: string;
  active?: boolean;
  onClick?: () => void;
  newTab?: boolean; // If true, the link will be opened in a new tab.
}

export const DropdownMenuItem = ({
  label,
  iconType,
  iconName,
  link,
  active,
  onClick,
  newTab,
}: DropdownMenuItemProps) => {
  const getIconContent = () => {
    if (iconType === 'svg' && iconName) {
      return (
        <span className="dropdown-menu-item__icon">
          <SvgIcon name={iconName} />
        </span>
      );
    }
  };

  const EntryTag = link ? 'a' : 'button';

  return (
    <div className={classNames('dropdown-menu-item', active ? 'active' : '')}>
      <EntryTag
        {...(link && { href: link })}
        {...(link &&
          newTab && { target: '_blank', rel: 'noopener noreferrer' })}
        onClick={(ev) => onClick?.()}
      >
        {getIconContent()}
        <span>{label}</span>
      </EntryTag>
    </div>
  );
};
