import {
  ExecutionType,
  PerformanceClass,
  PricingType,
  ServicingPreference,
} from '__generated__/globalTypes';
import { Button } from 'baseui/button';
import { DatePicker } from 'baseui/datepicker';
import { CheckboxWithLabel } from 'common-ui';
import ErrorMessage from 'common-ui/form/ErrorMessage';
import FormikRadioButton from 'common-ui/form/RadioButtonField';
import { NumberInputWithUnits } from 'common-ui/inputs/Inputs-styled';
import { Form, Formik, useField, useFormikContext } from 'formik';
import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import closeIcon from '../../../assets/svgs/close-gray.svg';
import { useDocumentLibrary } from 'common-ui/hooks/useDocumentLibrary';
import DocumentList from 'features/common-elements/DocumentList';
import { Tooltip } from 'react-tooltip';

export interface FormValues {
  // listingId is set in parent component.
  allowBanks: boolean;
  allowCreditUnions: boolean;
  executionType: ExecutionType;
  expirationDate: string;
  isPublic: boolean;
  isServicingNegotiable: boolean;
  minimumCarve?: number; // TODO(kentskinner): what does this map to?
  performanceClass: PerformanceClass;
  pricingType: PricingType;
  pricingRate?: number;
  servicingPreference: ServicingPreference;
  servicingFeeRate?: number;

  selectedDocuments: string[]; // TODO(kentskinner): what does this map to?
}

const initialValues = {
  allowBanks: false,
  allowCreditUnions: true,
  executionType: ExecutionType.ALL_OR_NOTHING,
  expirationDate: '',
  isPublic: false,
  isServicingNegotiable: false,
  minimumCarve: 0.0,
  performanceClass: PerformanceClass.PERFORMING,
  pricingType: PricingType.NONE,
  pricingRate: 0.0,
  servicingPreference: ServicingPreference.NONE,
  servicingFeeRate: 0.0,

  selectedDocuments: [],
};

const validationSchema = Yup.object().shape({
  allowBanks: Yup.boolean(),
  allowCreditUnions: Yup.boolean(),
  executionType: Yup.string()
    .oneOf(Object.values(ExecutionType))
    .required('Execution type is required'),

  minimumCarve: Yup.number()
    .when('executionType', {
      is: (val: string) => val === ExecutionType.BUYER_CHOICE,
      then: Yup.number()
        .required('Minimum carve is required when execution is Carving Allowed')
        .min(0, 'Minimum carve must be equal to or greater than zero')
        .nullable(),
    })
    .nullable(),

  expirationDate: Yup.string()
    .nullable()
    .required('Expiration date is required'),
  isPublic: Yup.boolean(),
  isServicingNegotiable: Yup.boolean(),
  performanceClass: Yup.string()
    .oneOf(Object.values(PerformanceClass))
    .required('Performance class is required'),
  pricingType: Yup.string()
    .oneOf(Object.values(PricingType))
    .required('Pricing type is required'),
  pricingRate: Yup.number()
    .when('pricingType', {
      is: (val: string) => val !== PricingType.NONE,
      then: Yup.number()
        .required('Pricing rate is required')
        .min(0, 'Pricing rate must be greater or equal to zero')
        .nullable(),
    })
    .nullable(),
  servicingPreference: Yup.string()
    .oneOf(Object.values(ServicingPreference))
    .required('Servicing preference is required'),
  servicingFeeRate: Yup.number()
    .required('Servicing fee rate is required')
    .min(0, 'Servicing fee must be greater or equal to zero')
    .nullable(),
});

const CreateOfferingFormContainer = styled.div`
  position: relative;
  font-size: 14px;
  color: #ffffff;
  background: #131316;
  padding: 8px 24px;

  border: 1px solid #e028dd;
  border-radius: 8px;
`;

const OptionCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 1175px;
  margin-top: 10px;
`;

const OptionCard = styled.div`
  border-radius: 4px;
  background: var(--theme-fg-opacity-70, rgba(31, 31, 31, 0.7));
  padding: 10px;
  width: 380px;
  color: #f4f4fd;
`;

const OptionCardTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  height: 20px;
  margin-bottom: 8px;
  color: #868e9f;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CreateOfferingButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const CustomDatePicker = styled(DatePicker)`
  z-index: 10000 !important;
`;

const OfferExpiration = () => {
  const [field, meta, helpers] = useField('expirationDate');
  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const handleChangeDate = ({ date }: { date: Date | Date[] }) => {
    if (Array.isArray(date)) {
      helpers.setValue(date[0]);
    } else {
      helpers.setValue(date);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <label style={{ marginRight: '8px' }}>Select Offer Expiration</label>
      <div style={{ width: 110 }}>
        <CustomDatePicker
          size="compact"
          formatString="MM/dd/yyyy"
          placeholder="MM/DD/YYYY"
          mask="99/99/9999"
          onChange={handleChangeDate}
          filterDate={isWeekday}
          range={false}
          minDate={new Date()}
          value={field.value ? [new Date(field.value)] : []}
        />
      </div>
      {meta.touched && meta.error ? (
        <ErrorMessage>
          <span>{meta.error}</span>
        </ErrorMessage>
      ) : null}
    </div>
  );
};

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  children: React.ReactNode;
}

const CheckboxesOrRadioButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 46px;
`;

const Checkbox: React.FC<CheckboxProps> = ({ children, ...props }) => {
  const [field, _meta] = useField({ ...props, type: 'checkbox' });
  return (
    <CheckboxWithLabel {...field} {...props}>
      {children}
    </CheckboxWithLabel>
  );
};

export interface PercentageFieldProps
  extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const PercentageField: React.FC<PercentageFieldProps> = ({ ...props }) => {
  const [field, meta] = useField({ ...props });
  return (
    <div>
      <NumberInputWithUnits
        unit="%"
        leftAligned={false}
        {...field}
        {...props}
        error={!!(meta.touched && meta.error)}
      />
      {meta.touched && meta.error ? (
        <ErrorMessage>
          <span>{meta.error}</span>
        </ErrorMessage>
      ) : null}
    </div>
  );
};

const CurrencyField: React.FC<PercentageFieldProps> = ({ ...props }) => {
  const [field, meta] = useField({ ...props });
  return (
    <div>
      <NumberInputWithUnits
        unit="$"
        leftAligned={true}
        {...field}
        {...props}
        error={!!(meta.touched && meta.error)}
      />
      {meta.touched && meta.error ? (
        <ErrorMessage>
          <span>{meta.error}</span>
        </ErrorMessage>
      ) : null}
    </div>
  );
};

const Counterparty = () => {
  return (
    <OptionCard>
      <div>
        <OptionCardTitle>Select Preferred Counterparty</OptionCardTitle>
        <CheckboxesOrRadioButtonsContainer>
          <Checkbox name="allowCreditUnions">Credit Unions</Checkbox>
          <Checkbox name="allowBanks">Banks</Checkbox>
          <Checkbox name="nonDepositoryInvestors">
            Non-depository Investors
          </Checkbox>
        </CheckboxesOrRadioButtonsContainer>
      </div>
    </OptionCard>
  );
};

const LoanPoolExecution = () => {
  const { values } = useFormikContext<FormValues>();
  return (
    <OptionCard>
      <OptionCardTitle>Loan Pool Execution</OptionCardTitle>
      <CheckboxesOrRadioButtonsContainer>
        <FormikRadioButton
          name="executionType"
          value={ExecutionType.ALL_OR_NOTHING}
          label="AON"
        />
        <FormikRadioButton
          name="executionType"
          value={ExecutionType.BUYER_CHOICE}
          label="Carving Allowed"
        />
      </CheckboxesOrRadioButtonsContainer>
      <OptionCardTitle>Define Minimum Carve</OptionCardTitle>
      <CurrencyField
        name="minimumCarve"
        disabled={values.executionType !== ExecutionType.BUYER_CHOICE}
      />
    </OptionCard>
  );
};

const ServicingFee = () => {
  const { values } = useFormikContext<FormValues>();

  return (
    <OptionCard>
      <OptionCardTitle>Servicing</OptionCardTitle>
      <CheckboxesOrRadioButtonsContainer>
        <FormikRadioButton
          name="servicingPreference"
          value={ServicingPreference.RELEASED}
          label="Released"
        />
        <FormikRadioButton
          name="servicingPreference"
          value={ServicingPreference.RETAINED}
          label="Retained"
        />
        <FormikRadioButton
          name="servicingPreference"
          value={ServicingPreference.NONE}
          label="No Preference"
        />
      </CheckboxesOrRadioButtonsContainer>
      <OptionCardTitle>
        Servicing Fee
        <Checkbox name="isServicingNegotiable">Negotiable</Checkbox>
      </OptionCardTitle>
      <PercentageField 
        name="servicingFeeRate"
        disabled={values.servicingPreference === ServicingPreference.RELEASED}
      />
    </OptionCard>
  );
};

const PricedOffer = () => {
  const { values } = useFormikContext<FormValues>();
  return (
    <OptionCard>
      <OptionCardTitle>Priced Offer</OptionCardTitle>
      <CheckboxesOrRadioButtonsContainer>
        <FormikRadioButton
          name="pricingType"
          value={PricingType.RESERVED}
          label="Yes - Reserved"
        />
        <FormikRadioButton
          name="pricingType"
          value={PricingType.PUBLISHED}
          label="Yes - Published"
        />
        <FormikRadioButton
          name="pricingType"
          value={PricingType.NONE}
          label="No"
        />
      </CheckboxesOrRadioButtonsContainer>
      <OptionCardTitle>Price</OptionCardTitle>
      <PercentageField
        name="pricingRate"
        disabled={values.pricingType === PricingType.NONE}
      />
    </OptionCard>
  );
};

const PerformanceClassCard = () => {
  return (
    <OptionCard style={{ width: 550 }}>
      <OptionCardTitle>Performance Indicator</OptionCardTitle>
      <CheckboxesOrRadioButtonsContainer>
        <FormikRadioButton
          name="performanceClass"
          value={PerformanceClass.PERFORMING}
          label="Performing"
        />
        <FormikRadioButton
          name="performanceClass"
          value={PerformanceClass.NON_PERFORMING}
          label="Non-performing"
        />
        <FormikRadioButton
          name="performanceClass"
          value={PerformanceClass.SUB_PERFORMING}
          label="Sub-performing"
        />
        <FormikRadioButton
          name="performanceClass"
          value={PerformanceClass.RE_PERFORMING}
          label="Re-performing"
        />
      </CheckboxesOrRadioButtonsContainer>
    </OptionCard>
  );
};

const Documents = () => {
  const { documents, error, loading } = useDocumentLibrary(undefined, true);
  const { values, setFieldValue } = useFormikContext<FormValues>();
  return (
    <div style={{ color: '#909199', width: '86%', marginBottom: 15 }}>
      <div style={{ margin: '12px 0' }}>
        Select applicable documents below to meet mutual acceptance at
        settlement
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error loading documents</div>
      ) : (
        <DocumentList
          documents={documents}
          numColumns={2}
          selectedDocuments={values.selectedDocuments}
          onDocumentSelect={(documentId) => {
            setFieldValue('selectedDocuments', [
              ...values.selectedDocuments,
              documentId,
            ]);
          }}
          onDocumentDeselect={(documentId) => {
            setFieldValue(
              'selectedDocuments',
              values.selectedDocuments.filter((id) => id !== documentId)
            );
          }}
        />
      )}
    </div>
  );
};

const Header = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  padding: 8px 0;
`;

const Subheader = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0;
  color: #bbc5d7;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 16px;
  width: 24px;
  height: 24px;
  background-image: url(${closeIcon});
  background-size: cover;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const Explanatory = styled(Subheader)``;

export interface CreateOfferingFormProps {
  createOffering: (formValues: FormValues) => void;
  closeModal: () => void;
}

const CreateOfferingForm: React.FC<CreateOfferingFormProps> = ({
  createOffering,
  closeModal,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        createOffering(values);
        closeModal();
      }}
    >
      { ({ values }) => (
        <Form>
          <CreateOfferingFormContainer>
            <CloseButton onClick={closeModal} />
            <Header>Create An Offering</Header>
            <Subheader>POOL PUBLISH SETTINGS</Subheader>
            <Explanatory>
              Automatically grant full data access to newly published loan pools
              to institutions you trust and would like to work with
            </Explanatory>
            <OfferExpiration />
            <OptionCards>
              <Counterparty />
              <LoanPoolExecution />
              <ServicingFee />
              <PricedOffer />
              <PerformanceClassCard />
            </OptionCards>
            <Documents />
            <CreateOfferingButtonContainer>
              <Button data-tooltip-id='create-offering-button' disabled={values.selectedDocuments.length === 0} type="submit">Create Offering</Button>
            </CreateOfferingButtonContainer>
          </CreateOfferingFormContainer>
          {
            values.selectedDocuments.length === 0 &&
              (<Tooltip id={'create-offering-button'}>Select template documents to publish your offering</Tooltip>)
          }
        </Form>
      )}
    </Formik>
  );
};

export default CreateOfferingForm;
