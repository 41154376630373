import { ChangeEvent, useCallback, useMemo } from 'react';
import { styled } from 'style/ORSNNTheme';
import { RadioButton } from 'common-ui/form/RadioButtonField';
import { PercentageInput } from 'common-ui/inputs/Inputs-styled';
import { formatCentsToDollars, toPercentage } from './formatting';
import { FIELD_CONFIG } from 'configs/columns';
import { useBiddingPricingContext } from './BiddingPricingContext';
import { BidDetails } from './BidDetails';
import { StipulationFieldName } from '__generated__/globalTypes';
import { basisPointsToPercent, percentToBasisPoints } from 'features/deals/DealStages/EventActionCards/utils';

type CarveTableProps = {
  bidDetailsList: BidDetails[];
  selectedCarveId: string | null;
  setSelectedCarveId: (carveId: string) => void;
}
const CarveTable = ({ bidDetailsList, selectedCarveId, setSelectedCarveId }: CarveTableProps) => {
  const { 
    bidCard,
    updateBidDetails,
  } = useBiddingPricingContext();

  const selectedBidDetails = bidDetailsList.find((bidDetails) => bidDetails.carveId === selectedCarveId);

  const handleBidPxInputChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const price = event.target.value ? event.target.value : null;
      if (price === null || !isNaN(parseFloat(price))) {
        const basisPoints = percentToBasisPoints(price || '0');
        if (selectedBidDetails) {
          updateBidDetails({
            ...selectedBidDetails,
            carve: {
              ...selectedBidDetails.carve,
                stipulations: {
                ...selectedBidDetails.stipulations,
                bid_basis_points: basisPoints,
              },
            }
          }, StipulationFieldName.bid_basis_points, `${basisPoints}`
          );
        }
      }
    },
    [updateBidDetails, selectedBidDetails]
  );

  const handleServicingRateInputChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const price = event.target.value ? event.target.value : null;
      if (price === null || !isNaN(parseFloat(price))) {
        const rate = price ? parseFloat(price) : 0;
        console.log(rate);
        if (selectedBidDetails && bidCard.is_servicing_negotiable) {
          updateBidDetails({
            ...selectedBidDetails,
            stipulations: {
              ...selectedBidDetails.stipulations,
              servicingRate: {
                ...selectedBidDetails.stipulations.servicingRate,
                isCustom: ![.25, .5].includes(rate),
                value: rate.toString(),
                draftValue: rate.toString(),
              },
              servicing_rate: rate,
            },
          }, StipulationFieldName.servicing_rate, price || '0',
          );
        }
      }
    },
    [updateBidDetails, selectedBidDetails]
  );
  return (
    <StyledCarveTable>
      <thead className='text-left size-9'>
        <tr>
          <th></th>
          <th>Carve Name</th>
          <th className="pr-2 text-right">Bid Px</th>
          <th className="pr-1">Servicing Fee</th>
          <th className="pr-1">UPB</th>
          <th className="pr-1">GWAC</th>
          <th className="pr-1"># Loans</th>
          <th className="pr-1">WALA</th>
          <th className="pr-1">WAM</th>
          <th className="pr-1">LTV</th>
          <th className="pr-1">DTI</th>
          <th className="pr-1">DQ</th>
        </tr>
      </thead>
      <tbody>
        {bidDetailsList.map((bid) => {
          const { carve } = bid;
          const isSelected = carve.id === selectedCarveId;
          const bidBasisPoints = carve.stipulations?.bid_basis_points;
          const bidPercent = basisPointsToPercent(bidBasisPoints || 0);
          const servicingRateValue = bid.stipulations.is_servicing_retained ? bid.stipulations.servicingRate.value : '0';
          const servicingRate = parseFloat(servicingRateValue);

          return (
            <tr
              key={carve.id}
              onClick={() => setSelectedCarveId(carve.id)}
              aria-selected={isSelected ? 'true' : 'false'}
              className={`hover:bg-[#9a1698] even:bg-gray-800 ${isSelected ? 'bg-[#4f1450] text-slate-50' : ''}`}
            >
              <td>
                <RadioButton
                  checked={carve.id === selectedCarveId}
                  onChange={() => setSelectedCarveId(carve.id)}
                  label={''}
                  name={''}
                  value={''}
                />
              </td>
              <td className="py-1">{carve.name}</td>
              <td className="pr-1 w-24 text-right">
                {carve.id === selectedCarveId ? (
                  <PercentageInput
                    value={bidBasisPoints != null && parseFloat(bidPercent) != 0 ? parseFloat(bidPercent) : null}
                    onChange={(event) =>
                      handleBidPxInputChanged(event)
                    }
                  />
                ) : (
                  <span className="pr-3">
                    {carve.stipulations?.bid_basis_points != null && parseFloat(bidPercent) != 0
                      ? `${bidPercent}%`
                      : '-'}
                  </span>
                )}
              </td>
              <td className="pr-1 w-24 text-right">
                {carve.id === selectedCarveId && bidCard.is_servicing_negotiable && bid.stipulations?.is_servicing_retained ? (
                  <PercentageInput
                    value={servicingRate != null && servicingRate != 0 ? servicingRate : null}
                    onChange={(event) =>
                      handleServicingRateInputChanged(event)
                    }
                  />
                ) : (
                  <span className="pr-3">
                    { servicingRate != null && servicingRate != 0
                      ? `${servicingRate}%`
                      : '-'}
                  </span>
                )}
              </td>
              <td className="py-1">{formatCentsToDollars(carve.carve_summary?.unpaidBalance)}</td>
              <td className="py-1">{FIELD_CONFIG.servicing_rate.display(carve.carve_summary?.netCoupon)}</td>
              <td className="py-1">{carve.carve_summary?.loanCount}</td>
              <td className="py-1">{carve.carve_summary?.averageAge}</td>
              <td className="py-1">{carve.carve_summary?.averageMaturity}</td>
              <td className="py-1">{FIELD_CONFIG.ltv.display(carve.carve_summary?.ltv)}</td>
              <td className="py-1">{FIELD_CONFIG.dti.display(carve.carve_summary?.dti)}</td>
              <td className="py-1">{'-'}</td>
            </tr>
          );
        })}
      </tbody>
    </StyledCarveTable>
  );
};

const StyledCarveTable = styled.table`
  color: ${(props) => props.theme.color.slate200};
  border-collapse: collapse;
  width: 100%;

  tbody {
    input {
      background-color: transparent;
      border-color: #fff;
      border-radius: 4px;
    }
  }
`;

export default CarveTable;

