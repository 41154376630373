import { useQuery } from '@apollo/client';
import useQueryParams from '../useQueryParams';
import {
  AssetClass,
  SortDirection,
  SortableField,
} from '__generated__/globalTypes';
import {
  PortfolioFilterConfig,
  getPortfolioFiltersConfig,
} from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import AssetClassConfig from 'models/AssetClass';
import { FilterValue, filtersToQueryFilters } from 'features/common-elements';
import { GET_DEAL_DILIGENCE } from 'query/diligence';
import {
  GetDealDiligence,
  GetDealDiligenceVariables,
} from 'query/__generated__/GetDealDiligence';
import useTableQueryParams from 'functions/useTableQueryParams';

export const useLoansSelectedForDiligence = (
  dealId: string,
  assetClass: AssetClass
) => {
  const { params: queryParams, updateParams } = useTableQueryParams<
    FilterValue<PortfolioFilterConfig>,
    SortableField
  >();

  const portfolioFiltersConfig = assetClass
    ? getPortfolioFiltersConfig(AssetClassConfig[assetClass].id)
    : null;
  const queryFilters = portfolioFiltersConfig
    ? filtersToQueryFilters<PortfolioFilterConfig>(
        queryParams.filters,
        portfolioFiltersConfig
      )
    : [];

  const { loading, error, data, refetch } = useQuery<
    GetDealDiligence,
    GetDealDiligenceVariables
  >(GET_DEAL_DILIGENCE, {
    variables: {
      deal_id: dealId,
      sort: {
        sort_direction: queryParams.sortings[0]?.desc
          ? SortDirection.desc
          : SortDirection.asc,
        sort_field: queryParams.sortings[0]?.id,
      },
      pagination: {
        offset: (queryParams.pageNumber - 1) * queryParams.pageSize,
        page_size: queryParams.pageSize,
      },
      filters: queryFilters,
    },
    // TODO(kentskinner): figure out what this should be.
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
  });

  return { loading, error, data, refetch, queryParams, updateParams };
};
