import { useState, useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_NOTIFICATIONS_QUERY } from 'query/notifications';
import { AuthContext } from 'context/AuthContext';

interface NotificationMetadata {
  userID: string;
  notificationID: string;
  type: string;
  status: string;
  createdAt: string;
  sentAt: string;
  readAt: string;
  content: {
    s3Key: string;
    s3Bucket: string;
    s3Version: string;
  };
}

interface Notification {
  notificationMetadata: NotificationMetadata;
  content: string;
}

export const useNotifications = () => {
  const { state: authState } = useContext(AuthContext);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [fetchNotifications, { called, data, refetch }] = useLazyQuery(GET_NOTIFICATIONS_QUERY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const loadNotifications = () => {
      if (!called) {
        fetchNotifications();
      }
    };

    if (authState.user) {
      loadNotifications();
    }

    const intervalId = setInterval(() => {
      if (authState.user) {
        refetch();
      }
    }, 15000);

    const timeoutId = setTimeout(() => clearInterval(intervalId), 3600000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [fetchNotifications, called, refetch, authState.user]);

  useEffect(() => {
    if (data && data.userNotifications && data.userNotifications.notifications && data.userNotifications.notifications.length > 0) {
      setNotifications((prevNotifications) => {
        const newNotifications: Notification[] = data.userNotifications.notifications.filter(
          (notification: Notification) => notification.notificationMetadata.type === 'app'
        );

        const mergedNotifications = [...prevNotifications];
        newNotifications.forEach((newNotification: Notification) => {
          const existingIndex = mergedNotifications.findIndex(
            n => n.notificationMetadata.notificationID === newNotification.notificationMetadata.notificationID
          );
          if (existingIndex === -1) {
            mergedNotifications.push(newNotification);
          } else {
            mergedNotifications[existingIndex] = newNotification;
          }
        });

        mergedNotifications.sort(
          (a, b) => Number(a.notificationMetadata.createdAt) - Number(b.notificationMetadata.createdAt)
        );

        return mergedNotifications.filter(n => n.notificationMetadata.type === 'app');
      });
    }
  }, [data]);

  return notifications;
};
