import { AssetClass as GqlAssetClass } from '__generated__/globalTypes';
// TODO: Consolidate this with FIELD_CONFIG

export const ASSET_CLASS_IDS = {
  all: null,
  auto: GqlAssetClass.AUTO,
  home: GqlAssetClass.HOME,
  cre: GqlAssetClass.CRE,
  mbl: GqlAssetClass.MBL,
  aircraft: GqlAssetClass.AIRCRAFT,
  warehouse: GqlAssetClass.WAREHOUSE,
  marine: GqlAssetClass.MARINE,
  motorcycle: GqlAssetClass.MOTORCYCLE,
  revolving: GqlAssetClass.REVOLVING,
  solar: GqlAssetClass.SOLAR,
  rv: GqlAssetClass.RV,
  boat: GqlAssetClass.BOAT,
  unimplemented: null,
};

export type AssetClassId = keyof typeof ASSET_CLASS_IDS;

export type AssetClassModel = {
  id: AssetClassId;
  label: string;
  title: string;
  dispatch: GqlAssetClass | undefined;
  balanceBucketSize: number;
};

// Keep these in sync with app/api/src/libs/configurations.ts
const AssetClassConfig: { [key in GqlAssetClass | 'ALL']: AssetClassModel } = {
  ALL: {
    id: 'all',
    label: 'all',
    title: 'Executive Summary',
    dispatch: undefined,
    balanceBucketSize: 100_000_00,
  },
  AUTO: {
    id: 'auto',
    label: 'Auto',
    title: 'Auto Summary',
    dispatch: GqlAssetClass.AUTO,
    balanceBucketSize: 10_000_00,
  },
  HOME: {
    id: 'home',
    label: 'Residential',
    title: 'Mortgage Summary',
    dispatch: GqlAssetClass.HOME,
    balanceBucketSize: 25_000_00,
  },
  CRE: {
    id: 'cre',
    label: 'CRE',
    title: 'Commercial Real Estate',
    dispatch: GqlAssetClass.CRE,
    balanceBucketSize: 10000000,
  },
  SOLAR: {
    id: 'solar',
    label: 'Solar',
    title: 'Solar',
    dispatch: undefined,
    balanceBucketSize: 10000000,
  },
  REVOLVING: {
    id: 'revolving',
    label: 'Revolving',
    title: 'Revolving',
    dispatch: GqlAssetClass.REVOLVING,
    balanceBucketSize: 10000000,
  },
  RV: {
    id: 'rv',
    label: 'RV',
    title: 'RV',
    dispatch: GqlAssetClass.RV,
    balanceBucketSize: 10000000,
  },
  MARINE: {
    id: 'marine',
    label: 'Marine',
    title: 'Marine',
    dispatch: GqlAssetClass.MARINE,
    balanceBucketSize: 10000000,
  },
  WAREHOUSE: {
    id: 'warehouse',
    label: 'Warehouse',
    title: 'Warehouse',
    dispatch: GqlAssetClass.WAREHOUSE,
    balanceBucketSize: 10000000,
  },
  MOTORCYCLE: {
    id: 'motorcycle',
    label: 'Motorcycle',
    title: 'Motorcycle',
    dispatch: GqlAssetClass.MOTORCYCLE,
    balanceBucketSize: 10000000,
  },
  AIRCRAFT: {
    id: 'aircraft',
    label: 'Aircraft',
    title: 'Aircraft',
    dispatch: GqlAssetClass.AIRCRAFT,
    balanceBucketSize: 10000000,
  },
  MBL: {
    id: 'mbl',
    label: 'MBL',
    title: 'Member Business Loans',
    dispatch: GqlAssetClass.MBL,
    balanceBucketSize: 10000000,
  },
  //TODO: these are invented values
  BOAT: {
    id: 'boat',
    label: 'Boat',
    title: 'Boat',
    dispatch: GqlAssetClass.BOAT,
    balanceBucketSize: 10000000,
  },
};

export function getAssetClassFromId(
  id: string | GqlAssetClass | null
): AssetClassModel {
  switch (id ? id.toLowerCase() : id) {
    case AssetClassConfig.AUTO.id:
      return AssetClassConfig.AUTO;
    case AssetClassConfig.HOME.id:
      return AssetClassConfig.HOME;
    case AssetClassConfig.CRE.id:
      return AssetClassConfig.CRE;
    case AssetClassConfig.MBL.id:
      return AssetClassConfig.MBL;
    default:
      return AssetClassConfig.ALL;
  }
}

export default AssetClassConfig;
