import { RowsPerPageContainer } from 'features/deals/dealCards/Diligence/commonStyles';
import React, { useEffect } from 'react';
import { Dropdown } from './Dropdown/Dropdown';
import PageNumberBoxes from './PageNumberBoxes';
import { styled } from 'style/ORSNNTheme';
import { typographyHeebo12 } from './typography';

// The backend can only handle 10k loans at a time
const MAXIMUM_TOTAL_ITEMS = 10000;

const rowsPerPageOptions = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export interface PaginationControlsProps {
  currentPage: number;
  pageSize: number;
  totalItems?: number;
  update: (newPage: number, newPageSize: number) => void;
}

const ControlsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-content: center;
  align-items: center;
`;

const TotalItemsText = styled.div`
  color: ${(props) => props.theme.color.fgMuted};
  ${typographyHeebo12}
`;

const NoteText = styled.div`
  text-align: right;
  ${typographyHeebo12}
  font-style: italic;
  color: ${(props) => props.theme.color.fgMuted};
  padding-top: 5px;
  padding-right: 25px;
`;

const PaginationControls: React.FC<PaginationControlsProps> = ({
  currentPage,
  pageSize,
  totalItems,
  update,
}) => {
  const constrainedTotalItems = totalItems
    ? Math.min(totalItems, MAXIMUM_TOTAL_ITEMS)
    : 0;

  const calculatedTotalPages = constrainedTotalItems
    ? Math.ceil(constrainedTotalItems / pageSize)
    : 0;

  const hasPages = calculatedTotalPages > 1;

  useEffect(() => {
    if (!calculatedTotalPages) {
      return;
    }
    if (currentPage > calculatedTotalPages) {
      const newPage = Math.max(1, calculatedTotalPages);
      if (newPage !== currentPage) {
        update(1, pageSize);
      }
    }
  }, [calculatedTotalPages, currentPage, update]);

  const handlePageChange = (newPage: number) => {
    update(newPage, pageSize);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number | undefined) => {
    if (!newRowsPerPage) {
      return;
    }
    update(1, newRowsPerPage);
  };

  if (!hasPages) {
    return null;
  }

  return (
    <div>
      <ControlsContainer>
        <RowsPerPageContainer>
          <div>Rows Per Page</div>
          <Dropdown
            menuPlacement="top"
            isMulti={false}
            options={rowsPerPageOptions}
            value={rowsPerPageOptions.find((opt) => opt.value === pageSize)}
            onChange={(option) => handleRowsPerPageChange(option?.value)}
            isDisabled={!calculatedTotalPages}
          />
        </RowsPerPageContainer>
        <TotalItemsText>(of {totalItems?.toLocaleString()})</TotalItemsText>
        <PageNumberBoxes
          currentPage={currentPage}
          totalPages={calculatedTotalPages}
          onPageChange={handlePageChange}
        />
      </ControlsContainer>
    </div>
  );
};

export default PaginationControls;
