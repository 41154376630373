import { CashFlows } from 'features/drilldown/cashflows/CashFlows';
import * as navigation from 'app-level/navigation';
import { getUsePortfolioCashflow } from 'features/drilldown/cashflows/gql/usePortfolioCashflows_gql';
import { filtersToQueryFilters, PillFilters } from 'features/common-elements';
import { PortfolioFilterConfig } from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import { PortfolioLayout } from '../Portfolio/PortfolioLayout';
import { usePortfolioFilters } from '../Portfolio/usePortfolioFilters';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import { useGetListingsList } from './getListingsList_gql';
import {  useEffect, useMemo, useState } from 'react';
import { getUseSelectedPoolCashflow } from './getSelectedPoolCashflow_gql';
import { transformSelectedPoolsData, usePortfolioContext } from '../PortfolioContext';
import { GetListings_user_company_listings as Listing, } from 'query/__generated__/GetListings';

const CashFlowModeling = () => {
  const assetClass = navigation.usePortfolioAssetClass();
  const { setSelectedPoolsData} = usePortfolioContext();
  const filterProps = usePortfolioFilters();
  const [selectedPool, setSelectedPool] = useState<{
    label: string;
    value: string;
    poolData: Listing;
  } | null>(null);
  const { data: listingsList } = useGetListingsList();

  const poolOptions = useMemo(() => {
    return listingsList?.user?.company?.listings?.map((listing) => ({
      label: listing.provided_name || listing.name,
      value: listing.id,
      poolData: listing,
    }));
  },[listingsList?.user?.company?.listings]);

  useEffect(() => {
    if (selectedPool) {
      setSelectedPoolsData(transformSelectedPoolsData([selectedPool.poolData]));
      filterProps.setCurrentFilters([])
    } else {
      setSelectedPoolsData([]);
      filterProps.setCurrentFilters([])
    }
  }, [selectedPool, setSelectedPoolsData])


  if (!assetClass || !filterProps.filtersConfig) {
    return <PortfolioLayout />;
  }

  const queryFilters = filtersToQueryFilters<PortfolioFilterConfig>(filterProps.currentFilters, filterProps.filtersConfig);
  const useCashflowSummery = getUsePortfolioCashflow(assetClass, queryFilters);
  const userSettingKey = `cashflowsportfolio${assetClass}`;
  const useSelectedPoolCashflow = getUseSelectedPoolCashflow(assetClass, queryFilters);


  return (
    <PortfolioLayout
      filterRow={(
        <div className='flex flex-col gap-2 w-full'>
          <PillFilters {...filterProps} filtersConfig={filterProps.filtersConfig} />
          <div className='flex flex-row justify-end'>
            <Dropdown
              options={poolOptions || []}
              value={selectedPool}
              isMulti={false}
              className='w-1/6'
              isClearable
              onChange={(option) => setSelectedPool(option)}
              placeholder='Select Pool'
            />
          </div>
        </div>
      )}
    >
      <CashFlows
        key={assetClass}
        settingKey={userSettingKey}
        useSummaryData={useCashflowSummery}
        selectedPoolId={selectedPool?.value}
        selectedPoolCashflow={useSelectedPoolCashflow}
      />
    </PortfolioLayout>
  );
};

export  {CashFlowModeling};
