import styled from 'styled-components';
import {
  typographyHeebo12,
  typographyMonoParagraphSmall,
  typographyParagraphSmallPrimaryRegular,
} from 'common-ui/typography';

export const CarveName = styled.label`
  ${typographyParagraphSmallPrimaryRegular}
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  height: 40px;
`;

export const CarveDetailsContainer = styled.div`
  ${typographyParagraphSmallPrimaryRegular}
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.color.slate200};
`;

export const CarveDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 12px;
  border-bottom: 1px solid #28303e;

  &:nth-child(odd) {
    background-color: #222429;
  }
`;

export const CarveDetailsCell = styled.div`
  ${typographyMonoParagraphSmall}
  padding: 4px 0;

  &:nth-child(2) {
    color: ${(props) => props.theme.color.slate100};
    text-align: right;
  }
`;

export const Subheader = styled.div`
  ${typographyHeebo12}
`;

export const CarveActions = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
`;

export const CarveCardContainer = styled.div<{ highlight?: boolean }>`
  border-style: solid;
  border-color: ${(props) =>
    props.highlight ? props.theme.color.accentMuted : props.theme.color.white};
  border-width: 1px;
  border-radius: 8px;
  flex-grow: 1;
  min-width: 222px;
  max-width: 355px;
  color: ${(props) => (props.highlight ? '#f093ee' : '#fff')};
`;
