import { Dialog, DialogProps } from 'common-ui/Dialog';
import {
  typographyPTSansNarrow10,
  typographyParagraphSmallPrimaryRegular,
} from 'common-ui/typography';
import { styled } from 'style/ORSNNTheme';
import { useEffect, useState } from 'react';
import { BaseButton } from 'common-ui';
import {
  CloseButton,
  CompactDialogContainer,
  CompactDialogHeader,
} from './commonStyles';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  GetDiligenceNotes,
  GetDiligenceNotesVariables,
} from 'query/__generated__/GetDiligenceNotes';
import { GET_DILIGENCE_NOTES } from 'query/diligence';
import {
  CreateDiligenceNote,
  CreateDiligenceNoteVariables,
} from 'mutation/__generated__/CreateDiligenceNote';
import { CREATE_DILIGENCE_NOTE } from 'mutation/diligenceMutations';
import { NoteStatus } from '__generated__/globalTypes';
import { GetDealDiligence_deal_diligence } from 'query/__generated__/GetDealDiligence';

interface DiligenceNotesDialogProps {
  documentName: string;
  diligenceId?: string;
  dialog: DialogProps;
}

const DiligenceNotesDialog: React.FC<DiligenceNotesDialogProps> = ({
  documentName,
  diligenceId,
  dialog,
}) => {
  const [noteText, setNoteText] = useState<string>('');

  const { data, loading, refetch } = useQuery<
    GetDiligenceNotes,
    GetDiligenceNotesVariables
  >(GET_DILIGENCE_NOTES, {
    variables: { diligence_id: diligenceId! },
    skip: !dialog.isOpen || !diligenceId,
    pollInterval: 10000,
  });

  const [createDiligenceNote] = useMutation<
    CreateDiligenceNote,
    CreateDiligenceNoteVariables
  >(CREATE_DILIGENCE_NOTE);

  const handleAddNote = (text: string) => {
    createDiligenceNote({
      variables: {
        input: {
          diligence_id: diligenceId!,
          note: text,
        },
      },
      update: (cache) => {
        // Read the current diligence entity from the cache
        const id = `Diligence:${diligenceId}`;
        const diligence = cache.readFragment<GetDealDiligence_deal_diligence>({
          id,
          fragment: gql`
            fragment Diligence on Diligence {
              note_status
            }
          `,
        });

        if (!diligence) {
          return;
        }

        // Write the updated diligence entity back to the cache
        cache.writeFragment({
          id,
          fragment: gql`
            fragment Diligence on Diligence {
              note_status
            }
          `,
          data: { ...diligence, note_status: NoteStatus.READ },
        });
      },
    }).then(() => {
      refetch();
      dialog.setIsOpen(false);
    });
  };

  useEffect(() => {
    if (dialog.isOpen) {
      // If the dialog is opened, clear the note
      setNoteText('');
    }
  }, [dialog.isOpen]);

  return (
    <Dialog dialog={dialog} contentComponent={CompactDialogContainer}>
      <CompactDialogHeader>
        Add a Note for "{documentName}"
        <CloseButton onClick={() => dialog.setIsOpen(false)} />
      </CompactDialogHeader>
      <NoteHistory>
        {loading && <div>Loading...</div>}
        {data &&
          data.diligenceNotes?.entities?.map((note) => (
            <div key={note!.id}>
              <NoteHeader>
                <span>
                  ({note!.author.given_name}{' '}
                  {note!.author.family_name?.substring(0, 1)})
                </span>
              </NoteHeader>
              <NoteDate>
                {new Date(parseInt(note!.created_time) * 1000).toDateString()}
              </NoteDate>
              <NoteText>{note!.note}</NoteText>
            </div>
          ))}
      </NoteHistory>
      <NoteEditor
        aria-label="Add Note"
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
      />
      <ButtonsContainer>
        <BaseButton
          type="secondary"
          label="cancel"
          size="medium"
          onClick={() => dialog.setIsOpen(false)}
        >
          Cancel
        </BaseButton>
        <BaseButton
          label="add-note"
          size="medium"
          disabled={noteText.length === 0}
          onClick={() => {
            handleAddNote(noteText);
          }}
        >
          Add Note
        </BaseButton>
      </ButtonsContainer>
    </Dialog>
  );
};

export default DiligenceNotesDialog;

const NoteHistory = styled.div`
  max-height: 444px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 20px;
`;

const NoteHeader = styled.div`
  color: ${(props) => props.theme.color.grey500};
`;

const NoteDate = styled.div`
  ${typographyPTSansNarrow10}
  color: ${(props) => props.theme.color.slate200};
  margin-bottom: 5px;
`;

const NoteText = styled.div`
  ${typographyParagraphSmallPrimaryRegular}
`;

const NoteEditor = styled.textarea`
  ${typographyParagraphSmallPrimaryRegular}
  border: 1px solid #e85ee5;
  border-radius: 4px;
  margin: 15px 0;
  padding: 5px 10px;
  width: 100%;
  height: 118px;
  color: ${(props) => props.theme.color.fgDefault};
  background-color: ${(props) => props.theme.color.bgSurface};

  &:focus {
    outline: none;
    border: 1px solid #e85ee5;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;
