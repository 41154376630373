import { NoteStatus } from '__generated__/globalTypes';
import { SvgIcon } from 'common-ui';
import { styled } from 'style/ORSNNTheme';

interface AddNoteIconProps {
  onNoteClick: () => void;
  noteStatus: NoteStatus;
}

const NOTE_STATUS_COLOR_MAP = {
  [NoteStatus.NONE]: '#444',
  [NoteStatus.NEW]: '#f2c94c',
  [NoteStatus.READ]: '#ffffff',
};

const AddNoteIcon: React.FC<AddNoteIconProps> = ({
  onNoteClick,
  noteStatus,
}) => {
  return (
    <InvisibleButton
      aria-label="View and add notes"
      onClick={() => onNoteClick()}
    >
      <SvgIcon
        name="icon-sticky-note"
        fill={NOTE_STATUS_COLOR_MAP[noteStatus]}
      />
    </InvisibleButton>
  );
};

export default AddNoteIcon;

const InvisibleButton = styled.button`
  background: none;
  border: none;
  padding: 4px 5px 0 0;
  margin: 0;
  cursor: pointer;
  outline: none;
`;
