import { Auth } from 'aws-amplify';
import { PasswordInput, TextInput } from 'common-ui/inputs/Inputs-styled';
import { useFormik } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { styled } from 'style/ORSNNTheme';
import * as yup from 'yup';
import {
  Button,
  ErrorText,
  Form,
  Grid,
  GridLabel,
  InfoText,
  linkStyle,
} from './styles';
import { YUP_PASSWORD_MATCH_MESSAGE, YUP_PASSWORD_MATCH_REGEX } from './validation';
import ErrorMessage from 'common-ui/form/ErrorMessage';

const StyledLink = styled.span`
  ${linkStyle}
`;

function userFriendlyErrorMessage(msg: string) {
  return msg.replace(
    'Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$',
    'Use lowercase and uppercase letters, numbers, and symbols'
  );
}

type StartResetFormProps = {
  setEmail: Dispatch<SetStateAction<string>>;
  setIsResettingPassword: Dispatch<SetStateAction<boolean>>;
};

const StartResetForm = (props: StartResetFormProps): JSX.Element => {
  const [error, setError] = useState<string>();
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: yup
      .object()
      .shape({ email: yup.string().required().email() }),
    onSubmit: async (e) => {
      Auth.forgotPassword(e.email)
        .then((_res) => {
          props.setEmail(e.email);
          props.setIsResettingPassword(true);
        })
        .catch((err) => {
          setError(userFriendlyErrorMessage(err.message));
        });
    },
  });

  return (
    <>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Form onSubmit={formik.handleSubmit}>
        <Grid>
          <GridLabel htmlFor="email">Email</GridLabel>
          <TextInput
            withPrefix={false}
            autoComplete="email"
            autoFocus
            placeholder="Institution Email Address"
            id="email"
            required
            {...formik.getFieldProps('email')}
          />
        </Grid>
        <Button disabled={!formik.isValid} type="submit">
          Next
        </Button>
      </Form>
    </>
  );
};

const initialValues = {
  email: '',
  code: '',
  password: '',
  confirm_password: '',
};

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
  code: yup.string().required('Code sent to your email inbox'),
  password: yup
    .string()
    .required()
    .matches(YUP_PASSWORD_MATCH_REGEX, YUP_PASSWORD_MATCH_MESSAGE),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), null], "Passwords don't match")
    .required('Confirm Password is required'),
});

type SubmitResetFormProps = {
  email: string;
  setIsResetSuccess: Dispatch<SetStateAction<boolean>>;
};

const SubmitResetForm = (props: SubmitResetFormProps): JSX.Element => {
  const [error, setError] = useState<string>();
  const formik = useFormik({
    initialValues: { ...initialValues, email: props.email },
    validationSchema,
    onSubmit: async (e) => {
      Auth.forgotPasswordSubmit(e.email, e.code, e.password)
        .then((_res) => {
          props.setIsResetSuccess(true);
        })
        .catch((err) => {
          setError(userFriendlyErrorMessage(err.message));
        });
    },
  });

  return (
    <>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Form onSubmit={formik.handleSubmit}>
        <Grid>
          <GridLabel htmlFor="email">Email</GridLabel>
          <TextInput
            withPrefix={false}
            autoComplete="email"
            placeholder="Institution Email Address"
            error={formik.errors.email != null}
            id="email"
            required
            {...formik.getFieldProps('email')}
          />
          <GridLabel htmlFor="code">Verification Code</GridLabel>
          <div>
            <TextInput
              withPrefix={false}
              required
              id="code"
              placeholder="Email Verification Code"
              error={formik.errors.code != null}
              autoFocus
              {...formik.getFieldProps('code')}
            />
            <InfoText>{formik.errors.code}</InfoText>
          </div>
          <GridLabel htmlFor="password">New Password</GridLabel>
          <PasswordInput
            withPrefix={false}
            required
            id="password"
            placeholder="New Password"
            error={formik.errors.password != null}
            {...formik.getFieldProps('password')}
          />
          <InfoText>{formik.errors.password}</InfoText>
          <GridLabel htmlFor="confirm_password">Confirm New Password</GridLabel>
          <div>
            <PasswordInput
              withPrefix={false}
              required
              id="confirm_password"
              placeholder="Confirm Password"
              error={formik.errors.confirm_password != null}
              {...formik.getFieldProps('confirm_password')}
            />
            <InfoText>{formik.errors.confirm_password}</InfoText>
          </div>
        </Grid>
        <Button disabled={!formik.isValid} type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
};

type Props = {
  onFlip: () => void;
};

const ForgotPassword = ({ onFlip }: Props): JSX.Element => {
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isResetSuccess, setIsResetSuccess] = useState(false);
  const [email, setEmail] = useState(initialValues.email);

  const renderForm = () => {
    return isResettingPassword ? (
      <SubmitResetForm email={email} setIsResetSuccess={setIsResetSuccess} />
    ) : (
      <StartResetForm
        setEmail={setEmail}
        setIsResettingPassword={setIsResettingPassword}
      />
    );
  };

  return (
    <>
      <h5>Forgot Password</h5>
      {isResetSuccess ? (
        <div>SUCCESS! Your password has been reset.</div>
      ) : (
        renderForm()
      )}
      <div>
        <StyledLink onClick={onFlip}>{'Back to Sign In'}</StyledLink>
      </div>
    </>
  );
};

export default ForgotPassword;
