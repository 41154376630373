type Match<T, U> = {
  on: (pred: boolean | ((x?: T) => boolean), fn: (x?: T) => U) => Match<T, U>;
  otherwise: (fn: (x?: T) => U) => U;
};

const matched = <T>(x: T): Match<never, T> => ({
  on: () => matched<T>(x),
  otherwise: () => x,
});

export const match = <T, U>(x?: T): Match<T, U> => ({
  on: (predicate, fn): Match<T, U> =>
    (typeof predicate === 'boolean' && predicate) ||
    (typeof predicate === 'function' && predicate(x))
      ? matched(fn(x))
      : match(x),
  otherwise: (fn) => fn(x),
});