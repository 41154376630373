import { useLazyQuery } from '@apollo/client';
import { uploadConfig } from 'app-level/config/uploadConfig';
import { BaseButton, Modal } from 'common-ui';
import { GET_DEAL_DOCUMENTS } from 'query/deal';
import {
  GetDealDocuments,
  GetDealDocumentsVariables,
} from 'query/__generated__/GetDealDocuments';
import { styled } from 'style/ORSNNTheme';
import { FileType } from '__generated__/globalTypes';
import DocumentTemplates from 'features/pages/portfolio/DocumentLibrary/DocumentTemplates';
import { useState } from 'react';
import FileUpload from 'features/pages/portfolio/DocumentLibrary/FileUpload/FileUpload';
import { TemplateDocument } from 'features/pages/portfolio/DocumentLibrary/__generated__/TemplateDocument';
import { DealDocumentsCard_DealDocumentsCard } from './__generated__/DealDocumentsCard';

const CustomButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

type Props = { dealId: string } & DealDocumentsCard_DealDocumentsCard;

const DealDocumentsCardBody = (props: Props): JSX.Element => {
  const variables = { id: props.dealId };
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [_getDealDocuments, { data }] = useLazyQuery<
    GetDealDocuments,
    GetDealDocumentsVariables
  >(GET_DEAL_DOCUMENTS, {
    variables,
  });

  const refetchQueries = [
    {
      query: GET_DEAL_DOCUMENTS,
      variables: { id: props.dealId },
    },
  ];

  const documents = data?.deal?.documents ?? props.documents;

  const optionalUploadSection = props.can_upload ? (
    <CustomButtonGroup>
      <div>
        <p className='mb-2 text-xs text-grey-300'>{uploadConfig.dealDocuments.message}</p>
        <BaseButton
          icon="pill-plus"
          iconFill="#BDBDBD"
          isIconRight
          label="Document Upload"
          size="medium"
          type="tertiary"
          onClick={() => setIsModalOpen(true)}
        >
          Add a document
        </BaseButton>
      </div>
    </CustomButtonGroup>
  ) : (
    <></>
  );

  return (
    <>
      {optionalUploadSection}
      <div className='w-full'>
        <DocumentTemplates
          docs={documents as TemplateDocument[] || []}
          label='Uploaded documents'
          showDelete={props.can_upload}
          dealId={props.dealId}
          refetchQueries={refetchQueries}
        />
      </div>
      {isModalOpen && (
        <Modal
          headerText="Add A Document"
          subText=""
          caption=""
          setIsOpen={setIsModalOpen}
          type="document-library"
        >
          <FileUpload
            companyId={props.dealId}
            fileType={FileType.DEAL_FILE}
            setIsOpen={setIsModalOpen}
            allowedFileTypes={uploadConfig.dealDocuments.allowedFileTypes || []}
          />
        </Modal>
      )}
    </>
  );
};

export default DealDocumentsCardBody;
export { default as DealDocumentsCardFragments } from './fragments';
