import { useSortable } from '@dnd-kit/sortable';
import {FC} from 'react';
import { Pill, PillProps } from './Pill';

export interface SortablePillProps extends PillProps {
 id: string;
}

export const SortablePill: FC<SortablePillProps> = ({ id, onRemove, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    transition,
  } : undefined;

  return (
    <div ref={setNodeRef} style={style}>
      <Pill
        {...props}
        id={id}
        onRemove={onRemove}
        listeners={listeners}
        attributes={attributes}
      />
    </div>
  );
};
