import { gql } from '@apollo/client';

export const GET_COMPANY_REGULATORY_METRICS = gql`
  query GetCompanyRegulatoryMetrics($id: ID!) {
    listing(id: $id) {
      id
      company {
        type
        regulatory_metrics {
          loans_shares_rank
          delinquency_rank
          charge_off_ratio_rank
          net_worth_ratio_rank
          expense_ratio_rank
          roa_rank
          roe_rank
          allowance_total_loans_rank
          efficiency_ratio_rank
          iea_total_assets_rank
        }
      }
    }
  }
`;

