import React from 'react';
import { DateTime } from 'luxon';
import { useMutation } from '@apollo/client';
import { MARK_NOTIFICATION_READ_MUTATION } from 'mutation/notifications';
import { GET_NOTIFICATIONS_QUERY } from 'query/notifications';
import { MarkNotificationRead, MarkNotificationReadVariables } from 'mutation/__generated__/MarkNotificationRead';
import styles from './NotificationsDropdown.module.scss';

interface Notification {
  notificationMetadata: {
    userID: string;
    notificationID: string;
    type: string;
    status: string;
    createdAt: string;
    sentAt: string;
    readAt: string;
    content: {
      s3Key: string;
      s3Bucket: string;
      s3Version: string;
    };
  };
  content: string;
}

const NotificationsDropdown: React.FC<{ notifications: Notification[], className?: string }> = ({ notifications, className }) => {
  const [updateNotificationStatus] = useMutation<MarkNotificationRead, MarkNotificationReadVariables>(MARK_NOTIFICATION_READ_MUTATION, {
    refetchQueries: [{ query: GET_NOTIFICATIONS_QUERY }],
  });

  const handleDismissNotification = (notificationID: string) => {
    updateNotificationStatus({ variables: { requestData: { notificationID } } });
  };

  if (!notifications || !notifications.length) {
    return <p className="text-slate-400">No new notifications.</p>;
  }

  return (
    <div className={`shadow-lg rounded-lg p-4 pt-0 mt-5 overflow-y-auto font-heebo ${className}`}>
      {notifications.map((notification) => {
        const createdAt = Number(notification.notificationMetadata.createdAt);
        const readAt = Number(notification.notificationMetadata.readAt);

        return (
          <div key={notification.notificationMetadata.notificationID}
            className="border-b border-gray-600 pt-5 pb-4 mb-4 last:mb-0 flex items-end justify-between first:pt-0">
            <div className='pr-2'>
              <div className="text-white font-heebo text-lg font-normal leading-5 text-left mb-1 capitalize">
                {notification.notificationMetadata.type}
              </div>
              <div className="text-xs font-medium text-gray-400 mb-1">
                {DateTime.fromSeconds(createdAt).toLocaleString(DateTime.DATETIME_SHORT)}
              </div>
              <div className="mt-2">
                <p className={`text-white ${styles.notificationContent}`} dangerouslySetInnerHTML={{ __html: notification.content }}></p>
              </div>
            </div>
            {
              !readAt || readAt < 0 ?
                  <a
                    href='#'
                    className='border border-pink-500 py-[6px] px-[16px] rounded-md text-pink-500 no-underline text-sm font-medium gap-[5px]'
                    onClick={()=> handleDismissNotification(notification.notificationMetadata.notificationID)}
                  >
                    Read
                  </a>
                :
                  null
            }
          </div>
        );
      })}
    </div>
  );
};

export default NotificationsDropdown;
