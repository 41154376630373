import { FC } from 'react';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';
import { chartColors, chartGradientDataUri, QUERY_KEYS } from '../shared';
import { ChartSkeletonLoader } from './ChartSkeleton';
import { CustomTooltip } from './CustomTooltip';

type ConsumerLoanSpreadsData = {
  'Consumer Loan Spreads': {
    labels: string[];
    series: {
      [key: string]: number[];
    };
  }
}

type DataPoint = {
  date: string;
  [key: string]: string | number;
}

type SeriesData = {
  name: string;
  data: number[];
  color: string;
}

type ConsumerLoanSpreadsProps = {
  dataUrl?: string;
  width?: number;
  height?: number;
  className?: string;
}

export const ConsumerLoanSpreads: FC<ConsumerLoanSpreadsProps> = ({ dataUrl, width=620, height=382, className='', }) => {
  const { data, isLoading } = useReactQuery<ConsumerLoanSpreadsData, Error, DataPoint[]>({
    queryKey: [QUERY_KEYS.CONSUMER_LOAN_SPREADS, dataUrl],
    queryFn: async () => {
      if (!dataUrl) throw new Error('No URL provided');
      const response = await fetch(dataUrl);
      return response.json();
    },
    enabled: !!dataUrl,
    select: (data): DataPoint[] => {
      const series: SeriesData[] = Object.keys(data['Consumer Loan Spreads'].series).map(
        (key, index) => ({
          name: key,
          data: data['Consumer Loan Spreads'].series[key],
          color: chartColors[index % chartColors.length],
        })
      );

      return data['Consumer Loan Spreads'].labels.map((label, index) => {
        const dataPoint: DataPoint = { date: label };
        series.forEach((seriesData) => {
          dataPoint[seriesData.name] = parseFloat(seriesData.data[index].toFixed(2));
        });
        return dataPoint;
      });
    },
  });

  if (isLoading || !data) {
    return <ChartSkeletonLoader  width={width} height={height} />;
  }

  const seriesNames = Object.keys(data[0]).filter((key) => key !== 'date');

  const maxDataValue = Math.max(...data.flatMap(point => seriesNames.map(name => point[name] as number)));
  const maxTick = Math.ceil(maxDataValue);
  const yAxisTicks = Array.from({ length: maxTick }, (_, i) => i + 1);

  return (
    <div className={`bg-background-surface rounded-lg px-4 py-3 mx-auto mt-16 w-fit ${className}`}>
      <h3 className='text-center text-slate-100 font-heebo text-lg'>Consumer Loan Spreads</h3>
      <div className='relative'>
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url("${chartGradientDataUri}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <LineChart width={width} height={height} data={data} margin={{ bottom: 32 }} >
          <XAxis dataKey="date" stroke="#BBC5D7" tickMargin={8}>
            <Label
              value="Date"
              offset={-10}
              position="insideBottom"
              style={{ fill: '#BBC5D7', fontSize: 12 }}
            />
          </XAxis>
          <YAxis domain={['dataMin', 'dataMax']} stroke="#BBC5D7" padding={{top: 24, bottom: 24}} tickMargin={8} ticks={yAxisTicks}>
            <Label
              value="Value"
              angle={-90}
              position="insideLeft"
              style={{ fill: '#BBC5D7', fontSize: 12 }}
            />
          </YAxis>
          <CartesianGrid stroke="#28303E" />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign='top' />
          {seriesNames.map((seriesName, index) => (
            <Line
              key={seriesName}
              type="monotone"
              dataKey={seriesName}
              stroke={chartColors[index % chartColors.length]}
              dot={false}
              filter="drop-shadow(0px 0px 2px rgba(132, 144, 236, 0.53))"
            />
          ))}
        </LineChart>
      </div>
    </div>
  );
};
