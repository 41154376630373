import {
  Dispatch,
  createContext,
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useQuery } from '@apollo/client';
import { GET_USER_COMPANY } from 'query/company';
import { GetUserCompany, GetUserCompany_user_company } from 'query/__generated__/GetUserCompany';

enum AuthState {
  SignedIn = 'signedIn',
  SignedOut = 'signedOut',
}

export type AuthChallengeName =
  | 'NEW_PASSWORD_REQUIRED'
  | 'NEW_USER_REQUIRED'
  | 'SMS_MFA'
  | 'SOFTWARE_TOKEN_MFA'
  | 'MFA_SETUP';

export type AuthUser = CognitoUser & {
  // https://github.com/aws-amplify/amplify-js/issues/6974
  challengeName?: AuthChallengeName;
  signInUserSession?: any; // TODO strengthen this type
  challengeParam?: any; // TODO strengthen this type
};

type AuthContextState = {
  user?: AuthUser;
  company: GetUserCompany_user_company | null;
  authState: AuthState;
  authError: Error | string | null;
};

const init = {
  user: undefined,
  company: null,
  authState: AuthState.SignedIn,
  authError: null,
};

type AuthContextType = {
  state: AuthContextState;
  dispatch: Dispatch<AuthContextActionTypes>;
};

const AuthContext = createContext<AuthContextType>({
  state: init,
  dispatch: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

enum AuthContextAction {
  SET_SIGNED_IN_USER = 'SET_SIGNED_IN_USER',
  SET_USER_COMPANY = 'SET_USER_COMPANY',
  AUTH_ERROR = 'AUTH_ERROR',
  SIGN_OUT = 'SIGN_OUT',
  INIT = 'INIT',
}

export interface SetSignedInUserAction {
  type: typeof AuthContextAction.SET_SIGNED_IN_USER;
  payload: CognitoUser;
}

export interface SetUserCompanyAction {
  type: typeof AuthContextAction.SET_USER_COMPANY;
  payload: GetUserCompany_user_company;
}

export interface SetAuthErrorAction {
  type: typeof AuthContextAction.AUTH_ERROR;
  payload: {
    error: Error | string;
    user?: AuthUser;
  };
}

export interface SignOutAction {
  type: typeof AuthContextAction.SIGN_OUT;
}

export interface InitAction {
  type: typeof AuthContextAction.INIT;
}

export type AuthContextActionTypes =
  | SetSignedInUserAction
  | SetUserCompanyAction
  | SetAuthErrorAction
  | SignOutAction
  | InitAction;

function reducer(
  state: AuthContextState,
  action: AuthContextActionTypes
): AuthContextState {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(action);
  }
  switch (action.type) {
    case AuthContextAction.SET_SIGNED_IN_USER:
      return {
        ...state,
        user: action.payload,
        authState: AuthState.SignedIn,
      };
    case AuthContextAction.SET_USER_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case AuthContextAction.AUTH_ERROR:
      return {
        ...state,
        user: action.payload.user,
        authError: action.payload.error,
        authState: AuthState.SignedOut,
      };
    case AuthContextAction.SIGN_OUT:
      return {
        ...state,
        user: undefined,
        authError: null,
        authState: AuthState.SignedOut,
      };
    case AuthContextAction.INIT:
      return init;
  }
}

const AuthContextProvider = (
  props: PropsWithChildren<Record<never, never>>
): JSX.Element => {
  const [state, dispatch] = useReducer<
    Reducer<AuthContextState, AuthContextActionTypes>
  >(reducer, init);
  const { data } = useQuery<GetUserCompany>(GET_USER_COMPANY);
  useEffect(() => {
    if (state.user != null && data?.user.company) {
      dispatch({
        type: AuthContextAction.SET_USER_COMPANY,
        payload: data?.user.company,
      });
    }
  }, [state.user, data]);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider, AuthState, AuthContextAction };
