import { useQuery } from '@apollo/client';
import { BaseButton } from 'common-ui';
import Dinero from 'dinero.js';
import {FC} from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_PORTFOLIO_OVERVIEW_QUERY } from './PortfolioOverview.fragments';
import { GetPortfolioOverview } from './__generated__/GetPortfolioOverview';

const KPIComponent: FC<{value?: number, label: string}> = ({
  label, value
}) => {
  return (
    <div className='bg-kip-gradient px-2 flex flex-col gap-1 py-2 rounded text-center min-w-36'>
      <p className='font-heebo font-bold text-sm text-slate-50'>
        {Dinero({ amount: value ?? 0, currency: 'USD' }).toFormat()}
      </p>
      <span className='font-heebo text-sm font-black'>
        {label}
      </span>
    </div>
  );
};

export const PortfolioOverview: FC = () => {
  const { data, loading } = useQuery<GetPortfolioOverview>(GET_PORTFOLIO_OVERVIEW_QUERY);
  const navigate = useNavigate();
  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row justify-between items-center'>
        <h2 className='font-heebo font-medium text-lg text-white'>My Portfolio At A Glance</h2>
        <BaseButton label='Tape upload' size='small' type="secondary" className='w-fit h-fit' onClick={() => {
          navigate('/portfolio/tape-upload');
        }}>Upload Tape</BaseButton>
      </div>
      <div className='flex flex-row justify-between gap-4 overflow-x-auto'>
        <KPIComponent label='ALL' value={data?.user.company.totalPerformance.unpaidBalance} />
        <KPIComponent label='AUTO' value={data?.user.company.autoPerformance.unpaidBalance} />
        <KPIComponent label='HOME' value={data?.user.company.homePerformance.unpaidBalance} />
      </div>
    </div>
  );
};
