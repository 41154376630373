import { DropdownMenuItem, SvgIcon } from 'common-ui';
import { ReactElement, ReactText } from 'react';
import User from './User';
import Security from './Security';
import Permissions from './Permissions';

import './Account.scss';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import AppPrefs from './AppPrefs';

const menuItems = [
  {
    label: 'User',
    iconName: 'user',
    id: 'user',
    content: <User />,
  },
  {
    label: 'Security',
    iconName: 'lock',
    id: 'security',
    content: (
      <Security />
    ),
  },
  {
    label: 'User Role & Permissions',
    id: 'permissions',
    iconName: 'shield-check',
    content: <Permissions />,
    adminOnly: true,
  },
  {
    label: 'Application Preferences',
    id: 'preferences',
    iconName: 'check-mark',
    content: (
      <AppPrefs />
    ),
  },
  {
    label: 'Support',
    id: 'support',
    iconName: 'question-mark',
  },
];

export interface AccordionItemProps {
  title: string;
  iconName: string;
  children?: ReactElement | ReactText;
  expanded?: boolean;
  onChange: () => void;
}

const AccordionItem = ({ iconName, title, children, expanded = true, onChange }: AccordionItemProps) => {
  return (
    <button className={classNames('account__accordion__accordion-item', expanded ? 'expanded' : '')}>
      <header className="account__accordion__header" onClick={onChange}>
        <div className="account__accordion__icon">
          <SvgIcon name={iconName} />
        </div>
        <span>
          {title}
        </span>
        <div className="account__accordion__spacer" />
        <div className={classNames('account__accordion__arrow', expanded ? '' : 'collapsed')}>
          <SvgIcon name="chevron-down" />
        </div>
      </header>
      {
        expanded && (
          <div>
            {children}
          </div>
        )
      }
    </button>
  );
};

const Account = () => {
  let selected = 0;
  const [search, setSearch] = useSearchParams();
  const sectionFocus = search.get('section');

  if (sectionFocus) {
    const idx = menuItems.findIndex(m => m.id === sectionFocus);
    if (idx >= 0 && selected !== idx) {
      selected = idx;
    }
  }

  return (
    <div className="account">
      <header className='account__header'>
        User Profile
      </header>
      <div className='account__container'>
        <section className='menu'>
          {
            menuItems.map((m, index) => (<DropdownMenuItem
              key={m.label}
              label={m.label}
              iconType="svg"
              iconName={m.iconName}
              active={index === selected}
              onClick={() => {
                setSearch(new URLSearchParams([['section', m.id || '']]));
              }}
            />))
          }
        </section>
        <section className="main">
          {
            menuItems.map((m, index) => (<AccordionItem
              key={m.label}
              title={m.label}
              iconName={`${m.iconName}-20`}
              expanded={index === selected}
              onChange={() => {
                setSearch(new URLSearchParams([['section', m.id || '']]));
              }}
            >
              {m.content}
            </AccordionItem>))
          }
        </section>
      </div>
    </div>
  );
};

export default Account;
