import { gql, useQuery } from '@apollo/client';
import { BlockProps } from 'baseui/block';
import { DatePicker } from 'baseui/datepicker';
import { FlexGridItem } from 'baseui/flex-grid';
import { Input } from 'baseui/input';
import {
  CURRENCY_REGEX,
  formatCurrency,
  getFieldConfig,
  parseCurrencyToCents,
  parsePercentToRate,
  PERCENT_REGEX,
} from 'configs/columns';
import { FilterContext, FilterContextActionType } from 'context/FilterContext';
import { DateTime } from 'luxon';
import {
  Dispatch,
  FormEventHandler,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { styled } from 'style/ORSNNTheme';
import uuid from 'uuid';
import * as yup from 'yup';
import { FilterableField, FilterOperator } from '__generated__/globalTypes';
import {
  GetFilterBounds,
  GetFilterBoundsVariables,
  GetFilterBounds_deal_performance_summary,
} from './__generated__/GetFilterBounds';

export const GET_FILTER_BOUNDS = gql`
  query GetFilterBounds($id: ID!) {
    deal(id: $id) {
      id
      performance_summary {
        max_age_months
        max_borrower_credit_score
        max_coupon
        max_current_balance_cents
        max_dti
        max_ltv
        max_maturity_date
        max_first_payment_date
        max_remaining_loan_terms_months
        max_servicing_rate
        min_age_months
        min_borrower_credit_score
        min_coupon
        min_current_balance_cents
        min_dti
        min_ltv
        min_maturity_date
        min_first_payment_date
        min_remaining_loan_terms_months
        min_servicing_rate
      }
    }
  }
`;

const BoundedInputSeparator = styled.span`
  padding: 0 20px;
`;

type InputProps = {
  field: FilterableField;
  filterBounds?: GetFilterBounds_deal_performance_summary;
  canEdit: boolean;
};

const CsvInput = ({ field, canEdit }: InputProps): JSX.Element => {
  const { state, dispatch } = useContext(FilterContext);
  const filters = state.filters.filter((filter) => filter.field_name === field);
  const values = filters?.reduce(
    (accumulator, current) =>
      accumulator.concat(current.operandList?.join(', ') ?? ''),
    ''
  );
  const [value, setValue] = useState(values ?? '');

  const toCsv = () => {
    return value.split(',').map((v) => v.trim());
  };

  const handleChange: FormEventHandler<HTMLInputElement> = (event) => {
    canEdit && setValue(event.currentTarget.value);
  };

  const updateFilter: FormEventHandler<HTMLInputElement> = () => {
    const filterId = filters.length > 0 ? filters[0].id : uuid.v4();
    const payload = {
      id: filterId,
      field_name: field,
      operator: FilterOperator.IS,
      operandList: toCsv(),
    };
    dispatch({
      type: FilterContextActionType.UPDATE_FILTER,
      payload,
    });
  };

  const fieldConfig = getFieldConfig(field);

  return (
    <Input
      value={value}
      onChange={handleChange}
      onBlur={updateFilter}
      placeholder={`Enter ${fieldConfig?.string} options separated by commas`}
    />
  );
};

const BooleanInput = ({ field, canEdit }: InputProps): JSX.Element => {
  const { state, dispatch } = useContext(FilterContext);
  const filters = state.filters.filter((filter) => filter.field_name === field);
  const filter = filters[0];
  const [value, setValue] = useState(filter?.operand ?? '');
  const [prevValue, setPrevValue] = useState(filter?.operand ?? '');
  const fieldConfig = getFieldConfig(field);
  const placeholderString = `Enter ${fieldConfig?.string} options (true or false only)`;

  const handleChange: FormEventHandler<HTMLInputElement> = (event) => {
    canEdit && setValue(event.currentTarget.value);
  };

  const updateFilter: FormEventHandler<HTMLInputElement> = (event) => {
    if (value !== 'true' && value !== 'false') {
      // if it's not true or false, then reset the value to empty string
      setValue(prevValue);
    } else {
      setPrevValue(value);
      const filterId = filter ? filter.id : uuid.v4();
      const payload = {
        id: filterId,
        field_name: field,
        operator: FilterOperator.IS,
        operand: value,
      };
      dispatch({
        type: FilterContextActionType.UPDATE_FILTER,
        payload,
      });
    }
  };

  return (
    <Input
      value={value}
      onChange={handleChange}
      onBlur={updateFilter}
      placeholder={placeholderString}
    />
  );
};

/**
 * Handles inputs of type: currency, months, date, credit score, interest rate.
 * Uses boundary style input, i.e. from <lower> to <upper>.
 * @param field id of the filterable field.
 * @param filterBounds All the min and max values for the pool we're filtering over.
 * @returns input components for a loan pool filter.
 */
const BoundaryInput = ({
  field,
  filterBounds,
  canEdit,
}: InputProps): JSX.Element => {
  const { state, dispatch } = useContext(FilterContext);
  const filters = state.filters.filter((filter) => filter.field_name === field);
  const [min, setMin] = useState(
    filters?.find(
      (filter) => filter.operator === FilterOperator.GREATER_THAN_OR_EQUALS
    )?.operand ?? ''
  );
  const [max, setMax] = useState(
    filters?.find(
      (filter) => filter.operator === FilterOperator.LESS_THAN_OR_EQUALS
    )?.operand ?? ''
  );

  useEffect(() => {
    if (filterBounds && min === '' && max === '') {
      const fieldSpecial =
        field === 'interest_rate' ? 'coupon' : field.replaceAll('_seconds', '');
      const minKey = `min_${fieldSpecial}` as keyof typeof filterBounds;
      const maxKey = `max_${fieldSpecial}` as keyof typeof filterBounds;
      if (filterBounds[minKey]) {
        setMin(filterBounds[minKey] + '');
      }
      if (filterBounds[maxKey]) {
        setMax(filterBounds[maxKey] + '');
      }
    }
  }, []);

  const handleSubmit = (nextLower: string, nextUpper: string) => {
    const lowerFilterId =
      filters.find(
        (filter) =>
          filter.field_name === field &&
          filter.operator === FilterOperator.GREATER_THAN_OR_EQUALS
      )?.id ?? uuid.v4();
    const upperfilterId =
      filters.find(
        (filter) =>
          filter.field_name === field &&
          filter.operator === FilterOperator.LESS_THAN_OR_EQUALS
      )?.id ?? uuid.v4();
    dispatch({
      type: FilterContextActionType.UPDATE_FILTERS,
      payload: [
        {
          id: lowerFilterId,
          field_name: field,
          operator: FilterOperator.GREATER_THAN_OR_EQUALS,
          operand: nextLower,
        },
        {
          id: upperfilterId,
          field_name: field,
          operator: FilterOperator.LESS_THAN_OR_EQUALS,
          operand: nextUpper,
        },
      ],
    });
  };

  let BoundedInput: (props: BoundedInputProps) => JSX.Element;
  if (field.includes('cents')) {
    BoundedInput = CurrencyBoundedInput;
  } else if (field.includes('months') || field.includes('credit')) {
    BoundedInput = MonthsBoundedInput;
  } else if (field.includes('date')) {
    BoundedInput = DateBoundedInput;
  } else if (field.includes('rate') || ['ltv', 'dti'].includes(field)) {
    BoundedInput = PercentageBoundedInput;
  } else {
    return <div>not supported</div>;
  }

  return (
    <BoundedInput
      min={min}
      max={max}
      canEdit={canEdit}
      onValidSubmit={handleSubmit}
      fieldName={field}
    />
  );
};

type BoundedInputProps = {
  min: string;
  max: string;
  onValidSubmit: (lower: string, upper: string) => void;
  fieldName: FilterableField;
  canEdit: boolean;
};

const CurrencyBoundedInput = ({
  min,
  max,
  onValidSubmit,
  fieldName,
  canEdit,
}: BoundedInputProps) => {
  const [lower, setLower] = useState('');
  const [upper, setUpper] = useState('');
  const [isLowerValid, setIsLowerValid] = useState(true);
  const [isUpperValid, setIsUpperValid] = useState(true);
  const CURRENCY_VALIDATOR = yup.string().required().matches(CURRENCY_REGEX);

  const fieldConfig = getFieldConfig(fieldName);

  useEffect(() => {
    if (min && max) {
      setLower(formatCurrency(min, false));
      setUpper(formatCurrency(max, false));
    }
  }, [min, max]);

  useEffect(() => {
    CURRENCY_VALIDATOR.isValid(lower).then((isValid) =>
      setIsLowerValid(isValid)
    );
  }, [lower]);

  useEffect(() => {
    CURRENCY_VALIDATOR.isValid(upper).then((isValid) =>
      setIsUpperValid(isValid)
    );
  }, [upper]);

  useEffect(() => {
    if (
      lower.length !== 0 &&
      upper.length !== 0 &&
      isLowerValid &&
      isUpperValid
    ) {
      const nextLowerCents = parseCurrencyToCents(lower);
      const nextUpperCents = parseCurrencyToCents(upper);
      if (nextLowerCents != null && nextUpperCents != null) {
        onValidSubmit(nextLowerCents.toString(), nextUpperCents.toString());
      }
    }
  }, [isLowerValid, isUpperValid, upper, lower]);

  const handleChange = (
    value: string,
    setValue: Dispatch<SetStateAction<string>>
  ) => {
    canEdit && setValue(value);
  };

  const handleBlur = (
    value: string,
    setValue: Dispatch<SetStateAction<string>>
  ) => {
    if (value != null) {
      const cents = parseCurrencyToCents(value);
      if (cents != null) {
        canEdit && setValue(formatCurrency(cents, false));
      } else {
        canEdit && setValue(value);
      }
    }
  };

  return (
    <>
      <Input
        value={lower}
        onChange={(event) =>
          handleChange(event.currentTarget.value + '', setLower)
        }
        onBlur={(e) => handleBlur(e.target.value + '', setLower)}
        startEnhancer={fieldConfig?.inputFormat?.prefix}
        placeholder={lower ?? 'Lower Limit'}
        error={!isLowerValid}
      />
      <BoundedInputSeparator>to</BoundedInputSeparator>
      <Input
        value={upper}
        onChange={(event) =>
          handleChange(event.currentTarget.value + '', setUpper)
        }
        onBlur={(e) => handleBlur(e.target.value + '', setUpper)}
        startEnhancer={fieldConfig?.inputFormat?.prefix}
        placeholder={upper ?? 'Upper Limit'}
        error={!isUpperValid}
      />
    </>
  );
};

const MonthsBoundedInput = ({
  min,
  max,
  onValidSubmit,
  fieldName,
  canEdit,
}: BoundedInputProps) => {
  const [lower, setLower] = useState('');
  const [upper, setUpper] = useState('');
  const [isLowerValid, setIsLowerValid] = useState(true);
  const [isUpperValid, setIsUpperValid] = useState(true);
  const MONTHS_VALIDATOR = yup
    .string()
    .required()
    .matches(/^\d{1,3}(?:,?\d{3})*$/);

  useEffect(() => {
    if (min && max) {
      setLower(min);
      setUpper(max);
    }
  }, [min, max]);

  useEffect(() => {
    MONTHS_VALIDATOR.isValid(lower).then((isValid) => setIsLowerValid(isValid));
  }, [lower]);

  useEffect(() => {
    MONTHS_VALIDATOR.isValid(upper).then((isValid) => setIsUpperValid(isValid));
  }, [upper]);

  useEffect(() => {
    if (
      lower.length !== 0 &&
      upper.length !== 0 &&
      isLowerValid &&
      isUpperValid
    ) {
      const nextLower = lower.replaceAll(',', '');
      const nextUpper = upper.replaceAll(',', '');
      onValidSubmit(nextLower, nextUpper);
    }
  }, [lower, upper, isLowerValid, isUpperValid]);

  const handleChange = (
    value: string,
    setValue: Dispatch<SetStateAction<string>>
  ) => {
    canEdit && setValue(value);
  };

  const fieldConfig = getFieldConfig(fieldName);

  return (
    <>
      <Input
        value={lower}
        onChange={(event) =>
          handleChange(event.currentTarget.value + '', setLower)
        }
        startEnhancer={fieldConfig?.inputFormat?.prefix}
        endEnhancer={fieldConfig?.inputFormat?.suffix}
        placeholder="Lower Limit"
        error={!isLowerValid}
      />
      <BoundedInputSeparator>to</BoundedInputSeparator>
      <Input
        value={upper}
        onChange={(event) =>
          handleChange(event.currentTarget.value + '', setUpper)
        }
        startEnhancer={fieldConfig?.inputFormat?.prefix}
        endEnhancer={fieldConfig?.inputFormat?.suffix}
        placeholder="Upper Limit"
        error={!isUpperValid}
      />
    </>
  );
};

const PercentageBoundedInput = ({
  min,
  max,
  onValidSubmit,
  fieldName,
  canEdit,
}: BoundedInputProps) => {
  const [lower, setLower] = useState('');
  const [upper, setUpper] = useState('');
  const [isLowerValid, setIsLowerValid] = useState(true);
  const [isUpperValid, setIsUpperValid] = useState(true);
  const PERCENT_VALIDATOR = yup.string().required().matches(PERCENT_REGEX);
  const fieldConfig = getFieldConfig(fieldName);

  useEffect(() => {
    if (min && max) {
      setLower((+min * 100).toFixed(4).toString());
      setUpper((+max * 100).toFixed(4).toString());
    }
  }, [min, max]);

  useEffect(() => {
    PERCENT_VALIDATOR.isValid(lower).then((isValid) =>
      setIsLowerValid(isValid)
    );
  }, [lower]);

  useEffect(() => {
    PERCENT_VALIDATOR.isValid(upper).then((isValid) =>
      setIsUpperValid(isValid)
    );
  }, [upper]);

  useEffect(() => {
    if (
      lower.length !== 0 &&
      upper.length !== 0 &&
      isLowerValid &&
      isUpperValid
    ) {
      const nextLowerRate = parsePercentToRate(lower);
      const nextUpperRate = parsePercentToRate(upper);
      if (nextLowerRate != null && nextUpperRate != null) {
        onValidSubmit(nextLowerRate.toString(), nextUpperRate.toString());
      }
    }
  }, [isLowerValid, isUpperValid, upper, lower]);

  const handleChange = (
    value: string,
    setValue: Dispatch<SetStateAction<string>>
  ) => {
    canEdit && setValue(value);
  };

  const handleBlur = (
    value: string,
    setValue: Dispatch<SetStateAction<string>>
  ) => {
    canEdit && setValue(value); // TODO autoformat the stuff when they blur
  };

  return (
    <>
      <Input
        value={lower}
        onChange={(event) =>
          handleChange(event.currentTarget.value + '', setLower)
        }
        onBlur={(event) => handleBlur(event.currentTarget.value + '', setLower)}
        endEnhancer={fieldConfig?.inputFormat?.suffix}
        placeholder="Lower Limit"
        error={!isLowerValid}
      />
      <BoundedInputSeparator>to</BoundedInputSeparator>
      <Input
        value={upper}
        onChange={(event) =>
          handleChange(event.currentTarget.value + '', setUpper)
        }
        onBlur={(event) => handleBlur(event.currentTarget.value + '', setUpper)}
        endEnhancer={fieldConfig?.inputFormat?.suffix}
        placeholder="Upper Limit"
        error={!isUpperValid}
      />
    </>
  );
};

const getJSDate = (date: string) => {
  if (date) {
    const dateTime = DateTime.fromFormat(date, 'yyyy-MM-dd');
    if (dateTime.isValid) {
      return dateTime.toJSDate();
    } else {
      return DateTime.fromMillis(parseInt(date) * 1000).toJSDate();
    }
  } else {
    return null;
  }
};

const DateBoundedInput = ({
  min,
  max,
  onValidSubmit,
  canEdit,
}: BoundedInputProps) => {
  const [value, setValue] = useState<Date[]>([]);

  const minJSDate = getJSDate(min);
  const maxJSDate = getJSDate(max);

  useEffect(() => {
    if (Array.isArray(value) && value.length > 1) {
      const lowerDate = DateTime.fromJSDate(value[0])
        .toUTC()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toSeconds()
        .toString();
      const upperDate = DateTime.fromJSDate(value[1])
        .toUTC()
        .plus({ days: 1 })
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toSeconds()
        .toString();
      onValidSubmit(lowerDate, upperDate);
    }
  }, [value]);

  const handleChange = ({ date }: { date: Date | Date[] }) => {
    canEdit && setValue(Array.isArray(date) ? date : [date]);
  };

  return (
    <DatePicker
      value={
        value.length === 0 && minJSDate && maxJSDate
          ? [minJSDate, maxJSDate]
          : value
      }
      onChange={handleChange}
      quickSelect
      range
      minDate={minJSDate ?? undefined}
      maxDate={maxJSDate ?? undefined}
    />
  );
};

const INPUT_FLEX_PROPS: BlockProps = {
  height: 'scale1000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overrides: {
    Block: {
      style: {
        display: 'flex',
      },
    },
  },
};

const FilterFlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
`;

const getFilterComponent = (filter: FilterableField) => {
  switch (filter) {
    case 'postal_code':
    case 'city':
    case 'state':
    case 'account_id':
    case 'listing_id':
    case 'asset_class':
      return CsvInput;
    case 'cra':
      return BooleanInput;
    default:
      return BoundaryInput;
  }
};

type Props = {
  dealId: string;
  canEdit: boolean;
};

const FilterInputs = ({ dealId, canEdit }: Props): JSX.Element | null => {
  const { state } = useContext(FilterContext);
  const { data } = useQuery<GetFilterBounds, GetFilterBoundsVariables>(
    GET_FILTER_BOUNDS,
    {
      variables: { id: dealId },
      fetchPolicy: 'cache-and-network',
    }
  );

  const inputs = state.selectedFieldNames.map((fieldName) => {
    const InputComponent = getFilterComponent(fieldName);
    const fieldConfig = getFieldConfig(fieldName);
    return (
      <Fragment key={fieldName}>
        <FlexGridItem>
          {canEdit ? 'Define ' : ''}
          {fieldConfig?.string}
        </FlexGridItem>
        <FlexGridItem key={fieldName} {...INPUT_FLEX_PROPS}>
          <InputComponent
            canEdit={canEdit}
            field={fieldName}
            filterBounds={data?.deal?.performance_summary}
          />
        </FlexGridItem>
      </Fragment>
    );
  });

  if (inputs.length > 0) {
    return <FilterFlexBox>{inputs}</FilterFlexBox>;
  } else {
    return <></>;
  }
};

export default FilterInputs;
