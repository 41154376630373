import { useApolloClient } from '@apollo/client';
import GET_FILE_POST_URLS_MUTATION from 'mutation/getFilePostUrls';
import { GetFilePostUrls, GetFilePostUrlsVariables } from 'mutation/__generated__/GetFilePostUrls';
import { useCallback, useEffect, useRef } from 'react';
import { AssetClass, FileType, ProductType } from '__generated__/globalTypes';
import useFileUuid from './useFileUuid';

type UseS3UploaderProps = {
  onSuccess?: (file: File, versionId: string, uuid?: string) => void;
  onProgress?: (progress: number, file?: File) => void;
  onError?: (error: any) => void;
  fileType: FileType;
  parentId?: string;
  assetClass?: AssetClass;
  productType?: ProductType;
  allowedMimeTypes?: string[];
};

const usePreSignedUrlQuery = (fileType: FileType, parentId?: string, assetClass?: AssetClass, productType?: ProductType) => {
  const client = useApolloClient();

  const getPresignedUrl = async (
    fileName: string,
    contentType: string,
  ) => {
    const query = {
      mutation: GET_FILE_POST_URLS_MUTATION,
      variables: {
        parentId,
        fileType,
        fileName,
        contentType,
        assetClass,
        productType,
      },
    };

    const result = await client.mutate<
      GetFilePostUrls,
      GetFilePostUrlsVariables
    >(query);
    if (!result.data) {
      throw new Error('Could not obtain a pre-signed upload URL.');
    }
    return result.data.getFilePostUrl;
  };

  return {
    getPresignedUrl,
  };
};

export const useS3Uploader = ({
  onSuccess,
  onProgress,
  onError,
  fileType,
  parentId,
  allowedMimeTypes,
  ...rest
}: UseS3UploaderProps) => {
  const { fetchFileUuid } = useFileUuid();
  const { getPresignedUrl } = usePreSignedUrlQuery(fileType, parentId, rest.assetClass, rest.productType);
  const getPresignedUrlRef = useRef<(file: File) => Promise<string>>(async () => '');
  useEffect(() => {
    getPresignedUrlRef.current = async (file: File ) => {
      return getPresignedUrl(file.name, file.type, );
    };
  }, [getPresignedUrl]);


  const upload = useCallback(
    async (file: File) => {
      if (allowedMimeTypes != null && !allowedMimeTypes.includes(file.type)) {
        const error = new Error('Invalid file type. Only CSV, TSV, and Excel files are allowed.');
        console.error(error.message);
        onError?.(error);
        return;
      }

      try {
        const presignedUrl = await getPresignedUrlRef.current(file);
        const versionId = await performUpload(file, presignedUrl, onProgress);
        try {
          let uuid;
          if ('assetClass' in rest) {
            uuid = await fetchFileUuid(
              file,
              fileType,
              versionId,
              parentId,
              rest.assetClass,
              rest.productType
            );
          } else {
            uuid = await fetchFileUuid(file, fileType, versionId);
          }
          onSuccess?.(file, versionId, uuid);
        } catch (error) {
          console.error('Failed to fetch file UUID:', error);
          onSuccess?.(file, versionId);
        }
        return versionId;
      } catch (error) {
        console.error('Failed to upload file:', error);
        onError?.(error);
      }
    },
    [onSuccess, onProgress, onError, getPresignedUrlRef, fileType, parentId, rest, fetchFileUuid]
  );

  return {
    upload,
  };
};

const performUpload = (
  file: File,
  presignedUrl: string,
  onProgress?: (progress: number, file?: File) => void
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', presignedUrl, true);
    xhr.setRequestHeader('Content-Type', file.type);

    xhr.onerror = () => reject(new Error('Upload failed.'));
    xhr.onabort = () => reject(new Error('Upload aborted.'));
    xhr.onload = () => {
      if (xhr.status === 200) {
        const versionId = xhr.getResponseHeader('x-amz-version-id');
        if (versionId === null) {
          reject(new Error('Upload failed - no version ID returned.'));
        } else {
          resolve(versionId);
        }
      } else {
        reject(new Error(`Upload failed with status ${xhr.status} .`));
      }
    };
    xhr.upload.onprogress = event => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        onProgress?.(percentComplete, file);
      }
    };

    xhr.send(file);
  });
};
