import { gql } from '@apollo/client';
import { PoolHeaderFragments } from 'features/loanPool/PoolHeader';
import { CounterpartyBasicInfoFragment } from '../CounterpartyCard';
import { StatusTrackerFragments } from 'features/pages/marketplace/StatusTracker';

export const GET_DEAL_GENERAL_INFO_QUERY = gql`
  query GetDealGeneralInfo($id: ID!) {
    deal(id: $id) {
      dealId: id
      is_chat_enabled
      counterparty {
        ...CounterpartyBasicInfo
      }
      carvingEnabled
      createdDate: created_date
      listing {
        id
        assetClass: asset_class
        ...PoolHeaderListing
      }
      ...StatusTrackerDeal
    }
  }
  ${CounterpartyBasicInfoFragment.company}
  ${PoolHeaderFragments.listing}
  ${StatusTrackerFragments.deal}
`;

export const GET_DEAL_COUNTERPARTY_INFO_QUERY = gql`
  query GetDealCounterpartyInfo($id: ID!) {
    deal(id: $id) {
      id
      counterparty {
        type
          ... on PublicCompany {
            obf_assets
            obf_net_worth
          }
          ... on PrivateCompany {
            id
            name
            regulatory_number
            regulatory_agency
            t_assets
            t_liabilities
            t_net_worth
            t_interest_income
            t_noninterest_income
            t_interest_expense
            t_noninterest_expense
          }
      }
    }
}`;
