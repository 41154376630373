import React from 'react';
import styled, { useTheme } from 'styled-components';
import { SvgIcon } from 'common-ui';
import Select, {
  GroupBase,
  OptionProps,
  SingleValueProps,
  components,
} from 'react-select';
import { DiligenceStatus } from '__generated__/globalTypes';

export type LoanDiligenceStatus = DiligenceStatus;

interface StatusIconMap {
  [key: string]: string;
}

const statusIconMap: StatusIconMap = {
  PendingDocuments: 'icon-file-required',
  Incomplete: 'icon-file-in-progress',
  Complete: 'icon-file-received',
  Rejected: 'icon-file-rejected',
};

const StatusContainer = styled.span`
  display: inline-flex;
  gap: 8px;

  // This seems to be the only way to get the icon to align with the text
  align-items: flex-end;
`;

interface LoanStatusLabelProps {
  status: LoanDiligenceStatus;
  [key: string]: any;
}

const LoanStatusLabel: React.FC<LoanStatusLabelProps> = ({
  status,
  ...props
}) => {
  return (
    <StatusContainer {...props}>
      <SvgIcon name={statusIconMap[status]} />
      {status}
    </StatusContainer>
  );
};

export { LoanStatusLabel };

interface LoanStatusDropdownProps {
  value: LoanDiligenceStatus;
  onChange?: (value: LoanDiligenceStatus) => void;
  isInSelectedRow?: boolean;
  borderColor?: string;
}

export const LoanStatusDropdown = ({
  isInSelectedRow,
  onChange,
  value,
  borderColor,
}: LoanStatusDropdownProps) => {
  const theme = useTheme();
  return (
    <span
      onClick={(e) => e.stopPropagation()}
      style={{ fontFamily: 'Heebo', fontSize: '14px' }}
    >
      <Select
        unstyled
        isMulti={false}
        isSearchable={false}
        isClearable={false}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        options={options}
        value={options.find((option) => option.value === value)}
        onChange={(option) => {
          onChange?.((option as LoanStatusOptionType).value);
        }}
        components={{
          Option: CustomOption,
          SingleValue: CustomSingleValue,
        }}
        styles={{
          control: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            fontSize: 14,
            color: state.hasValue ? theme.color.slate50 : theme.color.slate200,
            minHeight: '30px',
            minWidth: '140px',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor:
              borderColor ||
              (state.menuIsOpen
                ? isInSelectedRow
                  ? '#fff'
                  : '#9A1698'
                : 'transparent'),
            borderRadius: '4px',
            padding: '0px 8px 0px 8px',
            '&:hover': {
              borderColor: isInSelectedRow ? '#fff' : '#9A1698',
            },
          }),
          singleValue: (provided, state) => ({
            ...provided,
            lineHeight: 1,
          }),
          dropdownIndicator: (provided, state) => ({
            color: isInSelectedRow ? '#fff' : theme.color.accentDefault,
          }),
          menu: (provided, state) => ({
            ...provided,
            backgroundColor: theme.color.bgSurface,
            color: '#fff',
            border: '1px solid #9A1698',
            borderRadius: '6px',
            fontSize: 14,
            boxShadow: '0px 16px 24px 0px rgba(13, 13, 13, 0.9)',
            marginTop: '2px',
            overflow: 'hidden',
            padding: '2px 0',
          }),
          option: (provided, state) => ({
            ...provided,
            padding: '6px 12px',
            backgroundColor: state.isFocused ? '#9A1698' : 'transparent',
            lineHeight: 1,
          }),
        }}
      />
    </span>
  );
};

interface LoanStatusOptionType {
  value: LoanDiligenceStatus;
  label: LoanDiligenceStatus;
}

const statuses: LoanDiligenceStatus[] = [DiligenceStatus.PENDING_DOCUMENTS, DiligenceStatus.IN_PROGRESS, DiligenceStatus.REVIEWED, DiligenceStatus.REJECTED];
const options: LoanStatusOptionType[] = statuses.map((status) => ({
  value: status,
  label: status,
}));

const CustomOption: React.FC<
  OptionProps<LoanStatusOptionType, boolean, GroupBase<LoanStatusOptionType>>
> = (props) => {
  return (
    <components.Option {...props}>
      <LoanStatusLabel status={props.label as LoanDiligenceStatus} />
    </components.Option>
  );
};

const CustomSingleValue: React.FC<SingleValueProps<LoanStatusOptionType>> = (
  props
) => {
  return (
    <components.SingleValue {...props}>
      <LoanStatusLabel status={props.data.label} {...props.innerProps} />
    </components.SingleValue>
  );
};
