import { ColumnDef } from '@tanstack/react-table';
import { IndeterminateCheckbox } from 'common-ui';
import { RadioButton } from 'common-ui/form/RadioButtonField';

export const radioButtonSelectionColumn: ColumnDef<any> = {
  id: 'selection',
  size: 40,
  cell: (cellContext) => (
    <RadioButton
      label={''}
      name={''}
      value={''}
      checked={cellContext.row.getIsSelected()}
      onChange={cellContext.row.getToggleSelectedHandler()}
    />
  ),
  meta: {
    headerNoPill: true,
  },
};

export const createCheckBoxSelectionColumn = (
  onHeaderCheckboxChange: (checked: boolean) => void
): ColumnDef<any> => {
  return {
    id: 'selection',
    size: 40,
    cell: (cellContext) => (
      <IndeterminateCheckbox
        checked={cellContext.row.getIsSelected()}
        onChange={cellContext.row.getToggleSelectedHandler()}
        disabled={!cellContext.row.getCanSelect()}
      />
    ),
    header: (headerContext) => {
      if (headerContext.table.options.enableMultiRowSelection !== false) {
        const originalOnChangeHandler =
          headerContext.table.getToggleAllRowsSelectedHandler();

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          // Call the original onChange handler from TanStack Table
          originalOnChangeHandler(event);
          onHeaderCheckboxChange(event.target.checked);
        };

        return (
          <IndeterminateCheckbox
            checked={headerContext.table.getIsAllRowsSelected()}
            indeterminate={headerContext.table.getIsSomeRowsSelected()}
            onChange={handleChange}
          />
        );
      }
      return null;
    },
    meta: {
      headerNoPill: true,
    },
  };
};