import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { SvgIcon } from 'common-ui';

import { SIDE_NAV_CONFIG } from './SideNav.config';

export const SideNav: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={clsx('inline-flex flex-col gap-3 mt-24 p-2 ml-3', className)}>{SIDE_NAV_CONFIG.map((navConfig) => (
      <li className="list-none text-center" key={navConfig.routeSegment}>
        <NavLink
          to={`${navConfig.routeSegment}`}
          className={({ isActive }) => clsx('flex flex-col text-white no-underline text-[8px] items-center p-1 pt-3 px-2', {
            'cursor-not-allowed pointer-events-none opacity-20': navConfig.disabled,
            'bg-grey-950 rounded border border-accent-emphasis': isActive
          })}
          tabIndex={navConfig.disabled ? -1 : 0}
        >
          <SvgIcon name={navConfig.iconName} />
          <div className="side-nav__label">{navConfig.text}</div>
        </NavLink>
      </li>
    ))}
    </div>
  );
};
