import { FC } from 'react';

export const CustomTooltip: FC<{ active?: boolean, payload?: any, label?: string }> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-grey-900 bg-opacity-80 border-none rounded-lg p-2">
        <p className="m-0 font-bold text-white">{`${label}`}</p>
        {payload.map((entry: any, index: number) => (
          <p key={`item-${index}`} className="m-0 font-bold" style={{ color: entry.color }}>{`${entry.name}: ${entry.value}`}</p>
        ))}
      </div>
    );
  }

  return null;
};
