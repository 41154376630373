import { createContext, useContext } from 'react';
import { BidDetails } from './BidDetails';
import { CashFlowsFormWithDataDisplayProps } from 'features/drilldown/cashflows/CashFlows';
import { BiddingAndPricingControllerCard_PlaceBidCard } from './__generated__/BiddingAndPricingControllerCard';
import { StipulationFieldName, StipulationToggleName } from '__generated__/globalTypes';
import { GetCarves_deal_carves } from 'query/__generated__/GetCarves';

export interface BiddingPricingContextProps {
  dealId: string;
  bidDetails?: BidDetails;
  updateBidDetails: (bidDetails: BidDetails, fieldName?: StipulationFieldName, value?: string) => void;
  updateCustomStips: (carveId: string, customStipulations: string[]) => void;
  selectedCarve?: GetCarves_deal_carves;
  cashFlowsDataDisplayProps: CashFlowsFormWithDataDisplayProps;
  bidCard: BiddingAndPricingControllerCard_PlaceBidCard;
  setStipFlag: (fieldName: StipulationToggleName, value: boolean) => void;
}

const BiddingPricingContext = createContext<
  BiddingPricingContextProps | undefined
>(undefined);

export const useBiddingPricingContext = () => {
  const context = useContext(BiddingPricingContext);
  if (!context) {
    throw new Error(
      'This component must be used within a BiddingPricingContext.Provider'
    );
  }
  return context;
};

export default BiddingPricingContext;
