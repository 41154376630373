import { PropsWithChildren } from 'react';
import { styled } from 'style/ORSNNTheme';
import { AssetClass } from '__generated__/globalTypes';
import { cardsConfig, CardsKey } from '../deals.config';

export const Card = styled.div<{ disabled?: boolean; active?: boolean }>`
  background: ${({ theme, active }) =>
    active ? theme.color.bgCanvas : theme.color.bgSurface};
  color: ${({ theme, active }) =>
    active ? theme.color.fgDefault : theme.color.fgMuted};
  border: 1px solid ${({ theme }) => theme.color.borderDefault};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  border-radius: 4px;
  padding: 12px 16px;
  margin-bottom: 4px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const Tab = styled.div<{ disabled?: boolean; active?: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  .arrow {
    transform: ${({ active }) => (active ? 'rotate(90deg)' : 'initial')};
  }
`;

const CardName = styled.h5`
  margin-bottom: 10px;
`;


export type CardCommonProps = {
  name: CardsKey;
  active?: boolean;
  onClick?: (cardKey: CardsKey) => void;
  dealId?: string;
  assetClass?: AssetClass;
};

export function CardWrapper(props: PropsWithChildren<CardCommonProps>){
  const config = cardsConfig[props.name];

  return (
    <Card active={props.active}>
      <Tab onClick={() => props.onClick && props.onClick(props.name)} active={props.active}>
        <CardName>{config.name}</CardName>
        <span className="arrow">❯</span>
      </Tab>
      {props.children}
    </Card>
  );
}
