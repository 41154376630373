import { styled } from 'style/ORSNNTheme';
import { ReactNode, useState } from 'react';
import React from 'react';
import { typographyLabelSmallPrimaryRegular } from './typography';

export interface TabsProps {
  children: ReactNode;
  activeTab?: number; // Controlled mode: active tab is controlled by parent
  initialActiveTab?: number; // Uncontrolled mode: initial active tab
  onTabChange?: (index: number) => void;
}

const TabHeader = styled.button<{ isActive: boolean; disabled?: boolean }>`
  ${typographyLabelSmallPrimaryRegular}
  padding: 3px 33px 3px 33px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) =>
    props.isActive ? props.theme.color.slate50 : 'transparent'};
  color: ${(props) =>
    props.disabled
      ? props.theme.color.slate100
      : props.isActive
      ? props.theme.color.bgCanvas
      : props.theme.color.slate200};
  border: 1px solid
    ${(props) =>
      props.isActive ? props.theme.color.slate50 : 'rgba(189, 189, 189, 1)'};

  border-right-width: ${({ isActive }) => (isActive ? '1px' : '0px')};

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

const Tabs: React.FC<TabsProps> = ({
  children,
  activeTab,
  initialActiveTab = 0,
  onTabChange,
}) => {
  const [internalActiveTab, setInternalActiveTab] = useState(initialActiveTab);

  const tabs = React.Children.toArray(children) as React.ReactElement[];

  const handleTabClick = (index: number) => {
    // Check if the tab is disabled
    if (!tabs[index].props.disabled) {
      if (activeTab === undefined) {
        // Uncontrolled mode
        setInternalActiveTab(index);
      }
      if (onTabChange) {
        onTabChange(index);
      }
    }
  };

  const currentActiveTab =
    activeTab !== undefined ? activeTab : internalActiveTab;

  return (
    <div>
      <TabHeaders>
        {tabs.map((tab, index) => (
          <TabHeader
            key={index}
            role="tab"
            aria-selected={currentActiveTab === index}
            aria-controls={`tab-panel-${index}`}
            id={`tab-${index}`}
            tabIndex={currentActiveTab === index ? 0 : -1}
            isActive={currentActiveTab === index}
            disabled={tab.props.disabled}
            onClick={() => handleTabClick(index)}
            className='hover:bg-pink-800 hover:text-white'
          >
            {tab.props.label}
          </TabHeader>
        ))}
      </TabHeaders>
      <div
        role="tabpanel"
        id={`tab-panel-${currentActiveTab}`}
        aria-labelledby={`tab-${currentActiveTab}`}
        tabIndex={0}
      >
        {tabs[currentActiveTab]?.props.children}
      </div>
    </div>
  );
};

const TabHeaders = styled.div.attrs({
  role: 'tablist',
  'aria-label': 'Tab List',
})`
  display: flex;
  padding-bottom: 12px;
`;

interface TabProps {
  label: string;
  children: ReactNode;
  disabled?: boolean;
}

const Tab: React.FC<TabProps> = ({ children }) => {
  return <>{children}</>;
};

export { Tabs, Tab };
