import { Spinner } from 'common-ui';
import EventActionCards from 'features/deals/DealStages/EventActionCards';
import { useQueryWithPollingWhileVisible } from 'functions/useQueryWithPollingWhileVisible';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DealStatus } from '__generated__/globalTypes';
import { GET_DEAL_TIMELINE } from './fragments';
import {
  GetTimeline,
  GetTimelineVariables,
  GetTimeline_deal_cards,
} from './__generated__/GetTimeline';
import { useDealRoomContext } from 'features/pages/marketplace/DealRoomContext';
import equal from 'fast-deep-equal';

const Layout = styled.div`
  display: flex;
  overflow: hidden;
`;

function getCardId(card: { title: string }, index: number): string {
  // TODO fix the logic here to handle repeated cards where the title and index could be the same
  return `${card.title.replaceAll(' ', '_')}_${index}`;
}

const POLLING_INTERVAL = 2000;

const DealStages = (): JSX.Element => {
  const { dealId } = useDealRoomContext();
  const memoizedDealId = useMemo(() => dealId, [dealId]);

  const [previousCards, setPreviousCards] = useState<GetTimeline_deal_cards[]>([]);

  const { loading, data, error, startPolling, stopPolling } = useQueryWithPollingWhileVisible<
  GetTimeline,
  GetTimelineVariables
  >(GET_DEAL_TIMELINE, {
    variables: { id: memoizedDealId },
    fetchPolicy: 'cache-and-network',
    pollInterval: POLLING_INTERVAL,
  });

  useEffect(() => {
    startPolling(POLLING_INTERVAL);
    if (DealStatus.COMPLETE === data?.deal?.state.status) {
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [data, startPolling, stopPolling]);

  const cards = useMemo(() => data?.deal?.cards || [], [data?.deal?.cards]);

  useEffect(() => {
    if (!equal(previousCards, cards)) {
      setPreviousCards(cards);
    }
  }, [cards, previousCards]);

  return (
    <>
      {error && <div>{error.message}</div>}
      <Spinner loading={loading} />
      {cards.length > 0 && (
        <Layout>
          <EventActionCards dealId={memoizedDealId} cards={previousCards} />
        </Layout>
      )}
    </>
  );
};

export default DealStages;
export { getCardId };
