import { mapValues, map, isEmpty } from 'lodash';
import { MutableRefObject, PropsWithChildren, createContext, useContext, useRef } from 'react';
import { PathMatch, useMatch, useSearchParams } from 'react-router-dom';
import { ASSET_CLASS_IDS, AssetClassId } from 'models/AssetClass';
import { MAIN_ROUTES_CONFIG, MainRouteKeys, PORTFOLIO_ROUTES_CONFIG, MainKeyTo2ndLevelKeys, mainRouteMatch, PortfolioRouteKeys, MarketplaceRouteKeys, PAGE_PARAM, ALL_PAGES, FILTERS_PARAM } from './navigation.config';
import { getStringAsKeyOf } from 'functions/typeUtils';

type LocalSearchParam<T extends string> = T extends typeof FILTERS_PARAM ? never : T;

class NavigationState
  implements Record<MainRouteKeys, { [paramName: string]: unknown }>
{
  home = {};
  portfolio: {
    asset_class?: AssetClassId;
    filterStr?: string | null;
  } = {};
  marketplace: {
    deal_id?: string;
    listing_id?: string;
  } = {};
  'market-data' = {};
  account = {};
  local: { [page in PortfolioRouteKeys | MarketplaceRouteKeys]?: { [key: LocalSearchParam<string>]: string } } = {};
}

const NavigationStateContext = createContext<MutableRefObject<NavigationState>>({ current: new NavigationState() });

function updatePortfolioState(portfolioMatch: PathMatch<string> | null, search: URLSearchParams, state: NavigationState['portfolio']) {
  const asset_classParam = getStringAsKeyOf(ASSET_CLASS_IDS, portfolioMatch?.params['*']?.toLowerCase());
  if (asset_classParam) {
    state.asset_class = asset_classParam;
  }
  if (search.has(FILTERS_PARAM)) {
    state.filterStr = search.get(FILTERS_PARAM);
  }
}

function updateLocalState(match: PathMatch<string>, search: URLSearchParams, state: NavigationState['local']) {
  const page = match.params[PAGE_PARAM];
  const pageRoute = getStringAsKeyOf(ALL_PAGES, page);
  if (!pageRoute) return;

  const searchObj = Object.fromEntries(search.entries());
  if (isEmpty(searchObj)) return;
  delete searchObj[FILTERS_PARAM];

  state[pageRoute] = searchObj;
}

function updateMarketplaceState(match: PathMatch<string>, state: NavigationState['marketplace']) {
  const page = match.params[PAGE_PARAM];
  if (page === 'deal' && match.params['deal_id']) {
    state.deal_id = match.params['deal_id'];
  } else if (page === 'listing' && match.params['listing_id']) {
    state.listing_id = match.params['listing_id'];
  }
}

export function NavigationStateProvider(props: PropsWithChildren<unknown>) {
  const navigationState = useRef<NavigationState>(new NavigationState());
  const [searchParams] = useSearchParams();

  const portfolioMatch = useMatch(mainRouteMatch.portfolio);
  if (portfolioMatch) {
    updatePortfolioState(portfolioMatch, searchParams, navigationState.current.portfolio);
    updateLocalState(portfolioMatch, searchParams, navigationState.current.local);
  }

  const marketplaceMatch = useMatch(mainRouteMatch.marketplace);
  if (marketplaceMatch) {
    updateMarketplaceState(marketplaceMatch, navigationState.current.marketplace);
  }

  return (<NavigationStateContext.Provider value={navigationState}>
    {props.children}
  </NavigationStateContext.Provider>);
}

type UrlBuilder<T extends MainRouteKeys> = (
  key: MainKeyTo2ndLevelKeys[T],
  state: NavigationState[T],
  localState: NavigationState['local']
) => string

const urlBuilders: { [mainKey in MainRouteKeys]: UrlBuilder<mainKey> } =
{
  ...mapValues(MAIN_ROUTES_CONFIG, (_, mainKey) => () => { throw new Error(`url builder not implemented for ${mainKey}`); }),

  portfolio: (routeSegment, portfolioState, localState) => {
    const config = PORTFOLIO_ROUTES_CONFIG[routeSegment];
    let url = routeSegment;
    if (config.usesAssetClass && portfolioState.asset_class) {
      url += `/${portfolioState.asset_class}`;
    }
    if (config.usesFilters && portfolioState.filterStr) {
      url += `?${FILTERS_PARAM}=${portfolioState.filterStr}`;
    }
    return appendLocalStateSearch(routeSegment, localState, url);
  },
  marketplace: (routeKey, marketplaceState, localState) => {
    if (routeKey === 'deal' && marketplaceState.deal_id) {
      return `${routeKey}/${marketplaceState.deal_id}`;
    } else if (routeKey === 'listing' && marketplaceState.listing_id) {
      return `${routeKey}/${marketplaceState.listing_id}`;
    }
    return routeKey;
  },
};

function appendLocalStateSearch(
  routeSegment: PortfolioRouteKeys | MarketplaceRouteKeys,
  localState: NavigationState['local'],
  url: string
) {
  const routeState = localState[routeSegment];
  const search = map(routeState, (val, key) => `${key}=${val}`).join('&');

  const joinChar = url.includes('?') ? '&' : '?';
  return `${url}${joinChar}${search}`;
}

export function useGet2ndLevelPageUrl() {
  const { current } = useContext(NavigationStateContext);
  const url = <T extends MainRouteKeys>(mainPageKey: T, key: MainKeyTo2ndLevelKeys[T]) =>
    urlBuilders[mainPageKey](key, current[mainPageKey], current.local);

  return url;
}

export function useGetMainPageUrl() {
  return (routeKey: MainRouteKeys) => routeKey;
}

