import { GetListings_user_company_listings as Listing, } from 'query/__generated__/GetListings';
import React, { createContext, useState, useContext, useMemo } from 'react';
import { GetPortfolioSummary_user_company_performanceData } from './__generated__/GetPortfolioSummary';

type PortfolioContextType = {
  selectedPoolsData: SelectedPoolData[];
  setSelectedPoolsData: (v: SelectedPoolData[]) => void;
  totalFilteredPoolData: Record<keyof GetPortfolioSummary_user_company_performanceData, number> | null;
  setTotalFilteredPoolData: (v:  Record<keyof GetPortfolioSummary_user_company_performanceData, number> | null) => void;
};

export const PortfolioContext = createContext<PortfolioContextType | null>(null);

export const usePortfolioContext = (): PortfolioContextType => {
  const portfolioContext = useContext(PortfolioContext);

  if (!portfolioContext) {
    throw new Error('portfolioContext must be used within a PortfolioContextProvider');
  }

  return portfolioContext;
};

export interface SelectedPoolData {
  unpaidBalance: number | null;
  loanCount: number | null;
  averageAge: number | null;
  averageMaturity: number | null;
  netCoupon: number | null;
  fico: number | null;
  ltv: number | null;
  dti: number | null;
  // NOTE: those types added for compatibility with the original generateMetrics param type.
  // Will be reviewed and simplified in the future.
  originalBalance?: null;
  averageBalance?: null;
}

export const transformSelectedPoolsData = (selectedPoolsData: Listing[]): SelectedPoolData[] => {
  return selectedPoolsData.map(pool => ({
    unpaidBalance: pool.t_current_balance_cents,
    loanCount: pool.loan_count,
    averageAge: pool.wa_loan_age_months,
    averageMaturity: pool.wa_remaining_loan_terms_months,
    netCoupon: pool.wa_coupon,
    fico: pool.wa_borrower_credit_score,
    ltv: pool.wa_ltv,
    dti: pool.wa_dti,
  }));
};

export const PortfolioContextProvider: React.FC = ({ children }) => {
  const [selectedPoolsData, setSelectedPoolsData] = useState<SelectedPoolData[]>([]);
  const [totalFilteredPoolData, setTotalFilteredPoolData] = useState<Record<keyof GetPortfolioSummary_user_company_performanceData, number> | null>(null);
  const value = useMemo(() => {
    return {
      selectedPoolsData,
      setSelectedPoolsData,
      totalFilteredPoolData,
      setTotalFilteredPoolData
    };
  }, [selectedPoolsData, totalFilteredPoolData]);

  return (
    <PortfolioContext.Provider
      value={value}>
      {children}
    </PortfolioContext.Provider>
  );
};
