import { styled } from 'style/ORSNNTheme';

const YieldToPriceChartContainer = styled.div`
  width: 577px;
  height: 456px;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid var(--colors-slate-300, #3b4351);
  background: var(--theme-bg-surface, #1f1f1f);

  padding: 12px;
`;

const YieldToPriceChartTitle = styled.div`
  color: var(--colors-slate-200, #868e9f);
  font-feature-settings: 'clig' off, 'liga' off;

  /* typography/ParagraphSmall/Primary/Regular */
  font-family: Heebo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const YieldBidButtonGroup = styled.div`
  display: inline-flex;
  color: #e8e9ec;
  margin: 15px 0;

  .c-button {
    padding: 6px 33px;
    font-family: Heebo;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    background-color: transparent;
    border-color: #e8e9ec;
    border-radius: 0px;

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0px 4px 4px 0;
    }
  }

  .c-button--selected {
    color: #171717;
    background-color: #e8e9ec;
  }
`;

const BidButtonsGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  margin: 20px 0 15px;

  button:last-child {
    margin-left: auto;
  }
`;

export {
  YieldToPriceChartContainer,
  YieldToPriceChartTitle,
  YieldBidButtonGroup,
  BidButtonsGroup,
};
