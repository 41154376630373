import { LoanTable } from 'features/drilldown/LoanDatatable/LoanTable';
import { AssetClass } from '__generated__/globalTypes';
import { TableQueryParameters } from 'functions/useTableQueryParams';

interface SelectedDiligenceTableProps {
  data: any;
  assetClass: AssetClass;
  selection: any;
  pagination: {
    updateParams: (newParams: Partial<TableQueryParameters<any, any>>) => void;
    queryParams: TableQueryParameters<any, any>;
    totalItems?: number;
  };
}

const SelectLoansForDiligenceTable = ({
  data,
  assetClass,
  selection,
  pagination,
}: SelectedDiligenceTableProps) => {
  return (
    <LoanTable
      data={data}
      strings={{ empty: 'No Loans Found' }}
      sorting={{
        state: pagination.queryParams.sortings,
        onSortingChanged: (newSortings) => {
          pagination.updateParams({ sortings: newSortings });
        },
      }}
      assetClass={assetClass}
      selection={selection}
      pagination={pagination}
      // Do not show the listing (POOL) column for the buyer
      omitColumnIds={['listing']}
    />
  );
};

export default SelectLoansForDiligenceTable;
