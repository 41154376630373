import { FC } from 'react';
import { DocumentLibraryUpload } from './components/DocumentLibraryUpload';
import { MarketColor } from './components/MarketColor';
import { MarketplaceBlotter } from './components/MarketplaceBlotter';
import { NotificationsSection } from './components/NotificationsSection';
import { PortfolioOverview } from './components/PortfolioOverview';
import { InterestRates } from './components/InterestRates';
import { LatestNews } from './News';

export const HomePage: FC = () => {
  return (
    <div className='mt-3 bg-background-surface rounded-lg p-3 h-full overflow-y-auto' style={{ 'gridArea': 'page' }}>
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-[24px]'>
        <div>
          <div className='h-[400px]'>
            <MarketColor />
          </div>
          <div className='h-[800px] mt-[24px]'>
            <InterestRates />
          </div>
        </div>
        <div>
          <div>
            <PortfolioOverview />
          </div>
          <div className='mt-[24px]'>
            <MarketplaceBlotter />
          </div>
          <div className='h-[460px] overflow-hidden mt-[24px]'>
            <NotificationsSection />
          </div>
        </div>
        <div>
          <div className='h-[360px] overflow-hidden'>
            <LatestNews />
          </div>
          <div className='min-h-[360px] mt-[24px]'>
            <DocumentLibraryUpload />
          </div>
        </div>
      </div>
    </div>
  );
};
