import React from 'react';
import styled from 'styled-components';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumberInputWithUnits } from 'common-ui/inputs/Inputs-styled';
import ErrorMessage from '../../../common-ui/form/ErrorMessage';
import { useField } from 'formik';

interface CashFlowModelingInputProps {
  name: string;
  fieldName: string;
  value: string;
  onValueChange?: (value: string) => void;
  unit?: string;
  CustomInputComponent?: React.ComponentType<any>;
  onBlur?: () => void;
}

const CashFlowModelingInput: React.FC<CashFlowModelingInputProps> = ({
  name,
  fieldName,
  value,
  onValueChange,
  unit = '%',
  CustomInputComponent,
  ...props
}) => {
  const [field, meta] = useField({ name, ...props });
  const isValid = !(meta.touched && meta.error);
  const InputComponent = CustomInputComponent || StyledNumberInputWithUnits;

  return (
    <Container>
      <Header>
        <Label>{fieldName}</Label>
        <ValidationIcon>
          <FontAwesomeIcon
            icon={isValid ? faCircleCheck : faCircleXmark}
            color={isValid ? '#9BDB72' : '#EB5757'}
          />
        </ValidationIcon>
      </Header>
      <InputComponent
        {...field}
        unit={unit}
        error={!isValid}
        onBlur={props.onBlur}
      />
      {!isValid && <ErrorMessage>{meta.error}</ErrorMessage>}
    </Container>
  );
};

const ValidationIcon = styled.div``;

const Container = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledNumberInputWithUnits = styled(NumberInputWithUnits)`
  width: 142px;
  height: 28px;
  border-radius: 3px;
  font-size: 12px;
`;

const Label = styled.label`
  flex: 1;
  text-align: left;
`;

export default CashFlowModelingInput;
