import { gql } from '@apollo/client';

export const CREATE_LISTING = gql`
  mutation CreateListing(
    $name: String
    $assetClass: AssetClass!
    $filters: [Filter!]
    $originationType: OriginationType!
  ) {
    createListing(
      input: {
        provided_name: $name
        asset_class: $assetClass
        filters: $filters
        origination_type: $originationType
      }
    ) {
      id
      name
    }
  }
`;