import { useState } from 'react';
import classNames from 'classnames';
import './ToggleButton.scss';

interface ToggleButtonProps {
  initialState?: boolean;
  disabled?: boolean;
  checkedLabel?: string;
  uncheckedLabel?: string;
  onChange?: (checked: boolean) => void;
}

export function ToggleButton({ initialState, disabled, checkedLabel, uncheckedLabel, onChange }: ToggleButtonProps) {
  const [checked, setChecked] = useState(initialState || false);

  return (
    <div className='toggle-container'>
      <label className={classNames('toggle-button', checked ? 'checked' : '', disabled ? 'disabled' : '')}>
        <input type="checkbox" disabled={!!disabled} defaultChecked={!!checked} onChange={() => {
          setChecked(!checked);
          onChange && onChange(!checked);
        }} />
      </label>
      {checked && checkedLabel && (<span>{checkedLabel}</span>)}
      {!checked && uncheckedLabel && (<span>{uncheckedLabel}</span>)}
    </div>
  );
}
