import { GetNewsArticles_newsArticles as NewsArticle } from 'query/__generated__/GetNewsArticles';
import { Byline } from './News';
import { ReadFullStoryLink, Subject } from './Home.styles';
import { BaseButton, SvgIcon } from 'common-ui';
import { styled } from 'style/ORSNNTheme';
import { CloseButton } from 'common-ui/form/StandardForm';

interface ArticleDialogProps {
  currentArticle: NewsArticle | null;
  currentArticleIndex: number;
  allArticles: NewsArticle[];
  hasMore: boolean;
  closeDialog: () => void;
  loadMoreArticles: () => void;
  handleNavigateArticle: (direction: 'next' | 'previous') => void;
}

const ArticleDialog: React.FC<ArticleDialogProps> = ({
  currentArticle,
  currentArticleIndex,
  allArticles,
  closeDialog,
  hasMore,
  loadMoreArticles,
  handleNavigateArticle,
}) => {
  return (
    <div className='flex flex-col h-full text-left'>
      <CloseButton title="Close" onClick={() => closeDialog()} />
      {currentArticle && (
        <>
          <div className='flex-1'>
            <Byline
              source={currentArticle.news_source}
              publication_date={currentArticle.publication_date}
              url={currentArticle.url}
            />
            <Subject>
              {currentArticle.subject}
            </Subject>
            <div className='my-4 font-light leading-normal text-grey-400 overflow-y-auto'>
              {currentArticle.summary}
            </div>
            <ReadFullStoryLink
              href={currentArticle.url}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => {
                e.stopPropagation();
              }}
              title="Read the full story"
            >
              Read the full story <SvgIcon name="arrow-circle-right" />
            </ReadFullStoryLink>
            <PreviousAndNextLinks style={{ textAlign: 'center' }}>
              <p>– END –</p>
            </PreviousAndNextLinks>
          </div>
          <DialogActionsContainer>
            <div>
              {currentArticleIndex > 0 && (
                <>
                  <a onClick={() => handleNavigateArticle('previous')}>
                    <PreviousAndNextLinks>READ PREVIOUS</PreviousAndNextLinks>
                    <Border>
                      <Byline
                        source={
                          allArticles[currentArticleIndex - 1].news_source
                        }
                        publication_date={
                          allArticles[currentArticleIndex - 1].publication_date
                        }
                        url={allArticles[currentArticleIndex - 1].url}
                      />
                      <PreviousAndNextSubjects>
                        {allArticles[currentArticleIndex - 1].subject}
                      </PreviousAndNextSubjects>
                    </Border>
                  </a>
                </>
              )}
            </div>
            <div>
              {/* Add the 'See More Articles' button here if on the last article */}
              {currentArticleIndex === allArticles.length - 1 && hasMore && (
                <BaseButton
                  type="secondary"
                  size="small"
                  label="See More Articles"
                  onClick={() => {
                    loadMoreArticles();
                    // Close the dialog or move to the first new article as you see fit
                  }}
                >
                  See More Articles
                </BaseButton>
              )}
              {currentArticleIndex < allArticles.length - 1 && (
                <>
                  <a onClick={() => handleNavigateArticle('next')}>
                    <PreviousAndNextLinks style={{ textAlign: 'right' }}>
                      READ NEXT
                    </PreviousAndNextLinks>
                    <Border>
                      <Byline
                        source={
                          allArticles[currentArticleIndex + 1].news_source
                        }
                        publication_date={
                          allArticles[currentArticleIndex + 1].publication_date
                        }
                        url={allArticles[currentArticleIndex + 1].url}
                      />
                      <PreviousAndNextSubjects>
                        {allArticles[currentArticleIndex + 1].subject}
                      </PreviousAndNextSubjects>
                    </Border>
                  </a>
                </>
              )}
            </div>
          </DialogActionsContainer>
        </>
      )}
    </div>
  );
};

const DialogActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 100px;
  margin-top: 16px;
  & > div {
    flex: 1;
  }
  a {
    display: block;
    cursor: pointer;
  }
`;

const Border = styled.div`
  height: 108px;
  padding: 12px 16px 0 16px;
  border: 1px solid rgba(21, 85, 236, 0.2);
  border-radius: 8px;
`;

export default ArticleDialog;

const Typography = {
  BodyPrimaryMedium: styled.p`
    font-family: Heebo;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
  `,
  ParagraphXSmall: {
    Primary: {
      Medium: styled.p`
        font-family: Heebo;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
      `,
    },
  },
};

const PreviousAndNextLinks = styled(Typography.ParagraphXSmall.Primary.Medium)`
  color: ${(props) => props.theme.color.slate200};
`;
const PreviousAndNextSubjects = styled(Typography.BodyPrimaryMedium)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;
