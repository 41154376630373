import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconViewfinder } from '@tabler/icons-react';
import clsx from 'clsx';

import RequestAccess from './RequestAccess';
import { ModalNotStyled } from 'common-ui/modal/ModalNotStyled';
import { GetMarketplaceListings_listings } from './__generated__/GetMarketplaceListings';

type DealLinkProps = {
  isMyListing: boolean;
  listingHref: string;
  listingId: string;
  listingDetails: GetMarketplaceListings_listings;
};

const DealLink: React.FC<DealLinkProps> = ({ isMyListing, listingHref, listingId, listingDetails }) => {
  const navigate = useNavigate();

  const handleTriggerClick = (e: React.MouseEvent) => {
    if (!isMyListing) {
      e.stopPropagation();
      e.preventDefault();
    } else {
      navigate(listingHref);
    }
  };

  const Trigger = (
    <div
      onClick={(e) => {
        handleTriggerClick(e);
      }}
      className={clsx('py-2 pr-2 pl-[6px] border-l-2 border-l-slate-700 bg-slate-700 rounded cursor-pointer', {
        '!border-l-blue-500': isMyListing,
      })}
    >
      <IconViewfinder size={20} className="text-slate-200" />
    </div>
  );

  return (
    <>
      {isMyListing ? (
        Trigger
      ) : (
        <ModalNotStyled trigger={Trigger}>
          {({closeModal}) => (
            <div className="p-6 bg-background-canvas rounded-lg border border-pink-500 shadow-lg text-white max-w-[985px] w-full overflow-y-auto max-h-[540px] font-heebo">
              <div className="flex justify-between items-center">
                <h2 className="text-2xl font-semibold">{listingDetails.name}</h2>
                <button className="text-grey-500 hover:text-grey-300" onClick={closeModal}>✕</button>
              </div>
              <RequestAccess listingId={listingId} listingDetails={listingDetails} />
            </div>
          )}
        </ModalNotStyled>
      )}
    </>
  );
};

export default DealLink;
