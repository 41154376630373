import { useEffect } from 'react';
import classnames from 'classnames';
import './modal.scss';

interface PassedProps {
  setIsOpen: (isOpen: boolean) => void;
  headerText: string;
  subText: string;
  caption: string;
  children: React.ReactNode;
  type?: string;
  className?: string;
}

export const Modal = ({
  headerText,
  subText,
  caption,
  children,
  setIsOpen,
  type,
  className,
}: PassedProps) => {

  useEffect(() => {
    const closeModal = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };
    window.addEventListener('keydown', closeModal);
    return () => window.removeEventListener('keydown', closeModal);
  }, []);

  return (
    <>
      <div className='modal__backdrop' onClick={() => setIsOpen(false)} />
      <div className={classnames('modal', {
        'modal__document_library': type === 'document-library'
      })}>
        <div className={`modal__container ${className}`}>
          <div className="modal__header_container">
            <div className="modal__header_text">
              {headerText}
            </div>
            <div className="modal__header_subtext">
              {subText}
            </div>
            <div className="modal__header_caption">
              {caption}
            </div>
          </div>
          <div className="modal__body">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

