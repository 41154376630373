import { gql } from '@apollo/client';

export const DiligenceCardFragments = {
  TimelineCard: gql`
    fragment DiligenceCard on TimelineCard {
      ... on DiligenceCard {
        assetClass: asset_class
        role
        is_complete
        deal {
          id 
          listing {
            id
            name
            provided_name
          }
        }
      }
    }
  `,
};
