import React from 'react';
import styled, { useTheme } from 'styled-components';
import { SvgIcon } from 'common-ui';
import Select, {
  GroupBase,
  OptionProps,
  SingleValueProps,
  components,
} from 'react-select';
import { CollateralStatus } from '__generated__/globalTypes';

export type FileDiligenceStatus =
  | 'File Required'
  | 'File Received'
  | 'In Progress'
  | 'Additional QA Required'
  | 'File Reviewed'
  | 'File Rejected';

interface StatusIconMap {
  [key: string]: string;
}

const statusIconMap: StatusIconMap = {
  'File Required': 'icon-file-required',
  'File Received': 'icon-file-received',
  'In Progress': 'icon-file-in-progress',
  'Additional QA Required': 'icon-additional-qa-required',
  'File Reviewed': 'icon-file-reviewed',
  'File Rejected': 'icon-file-rejected',
};

const StatusContainer = styled.span`
  display: inline-flex;
  gap: 8px;

  // This seems to be the only way to get the icon to align with the text
  align-items: flex-end;
`;

interface FileStatusLabelProps {
  status: FileDiligenceStatus;
  [key: string]: any;
}

const FileStatusLabel: React.FC<FileStatusLabelProps> = ({
  status,
  ...props
}) => {
  return (
    <StatusContainer {...props}>
      <SvgIcon name={statusIconMap[status]} />
      {status}
    </StatusContainer>
  );
};

export { FileStatusLabel };

interface FileStatusDropdownProps {
  value: CollateralStatus | null;
  onChange?: (value: FileDiligenceStatus) => void;
  isInSelectedRow?: boolean;
  borderColor?: string;
}

export const FileStatusDropdown = ({
  isInSelectedRow,
  onChange,
  value,
  borderColor,
}: FileStatusDropdownProps) => {
  const theme = useTheme();

  if (value === null) return null;

  const convertedValue = fromCollateralStatus(value);

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <Select
        unstyled
        isMulti={false}
        isSearchable={false}
        isClearable={false}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        options={options}
        value={options.find((option) => option.value === convertedValue)}
        onChange={(option) => {
          onChange && onChange((option as FileStatusOptionType).value);
        }}
        components={{
          Option: CustomOption,
          SingleValue: CustomSingleValue,
        }}
        styles={{
          control: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            fontSize: 14,
            color: state.hasValue ? theme.color.slate50 : theme.color.slate200,
            minHeight: '30px',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor:
              borderColor ||
              (state.menuIsOpen
                ? isInSelectedRow
                  ? '#fff'
                  : '#9A1698'
                : 'transparent'),
            borderRadius: '4px',
            padding: '0px 8px 0px 8px',
            '&:hover': {
              borderColor: isInSelectedRow ? '#fff' : '#9A1698',
            },
          }),
          singleValue: (provided, state) => ({
            ...provided,
            lineHeight: 1,
          }),
          dropdownIndicator: (provided, state) => ({
            color: isInSelectedRow ? '#fff' : theme.color.accentDefault,
          }),
          menuPortal: (provided, state) => ({
            ...provided,
            zIndex: 100,
          }),
          menu: (provided, state) => ({
            ...provided,
            backgroundColor: theme.color.bgSurface,
            color: '#fff',
            border: '1px solid #9A1698',
            borderRadius: '6px',
            fontSize: 14,
            boxShadow: '0px 16px 24px 0px rgba(13, 13, 13, 0.9)',
            marginTop: '2px',
            overflow: 'hidden',
            padding: '2px 0',
            zIndex: 100,
          }),
          option: (provided, state) => ({
            ...provided,
            padding: '6px 12px',
            backgroundColor: state.isFocused ? '#9A1698' : 'transparent',
            lineHeight: 1,
          }),
        }}
      />
    </span>
  );
};

interface FileStatusOptionType {
  value: FileDiligenceStatus;
  label: FileDiligenceStatus;
}

const statuses: FileDiligenceStatus[] = [
  'File Required',
  'File Received',
  'In Progress',
  'Additional QA Required',
  'File Reviewed',
  'File Rejected',
];
const options: FileStatusOptionType[] = statuses.map((status) => ({
  value: status,
  label: status,
}));

const CustomOption: React.FC<
  OptionProps<FileStatusOptionType, boolean, GroupBase<FileStatusOptionType>>
> = (props) => {
  return (
    <components.Option {...props}>
      <FileStatusLabel status={props.label as FileDiligenceStatus} />
    </components.Option>
  );
};

const CustomSingleValue: React.FC<SingleValueProps<FileStatusOptionType>> = (
  props
) => {
  return (
    <components.SingleValue {...props}>
      <FileStatusLabel status={props.data.label} {...props.innerProps} />
    </components.SingleValue>
  );
};

export const fromCollateralStatus = (
  status: CollateralStatus | null
): FileDiligenceStatus => {
  if (status === null) return 'File Received';
  switch (status) {
    case CollateralStatus.IN_PROGRESS:
      return 'In Progress';
    case CollateralStatus.ADDITIONAL_QA_REQUIRED:
      return 'Additional QA Required';
    case CollateralStatus.RECEIVED:
      return 'File Received';
    case CollateralStatus.REQUIRED:
      return 'File Required';
    case CollateralStatus.COMPLETED:
      return 'File Reviewed';
    case CollateralStatus.REJECTED:
      return 'File Rejected';
    default:
      throw new Error(`Invalid status: ${status}`);
  }
};
