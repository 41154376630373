import { styled } from 'style/ORSNNTheme';
import {
  FileDiligenceStatus,
  FileStatusDropdown,
  fromCollateralStatus,
} from './FileStatus';
import { typographyHeebo14Bold } from 'common-ui/typography';
import { DateTime } from 'luxon';
import { RadioButton } from 'common-ui/form/RadioButtonField';
import AddNoteIcon from './AddNoteIcon';
import { GetDealDiligence_deal_diligence_collateral as Collateral } from 'query/__generated__/GetDealDiligence';
import { DealRole } from '__generated__/globalTypes';

interface DocumentsTableProps {
  role: DealRole;
  collateral?: Collateral[];
  selectedDocId?: string;
  onDocSelected: (docId: string) => void;
  handleNoteClick: (docId: string) => void;
  onFileStatusSelect?: (docId: string, status: FileDiligenceStatus) => void;
  hasNote: (docId: string) => boolean;
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({
  role,
  collateral,
  selectedDocId,
  onDocSelected,
  handleNoteClick,
  onFileStatusSelect,
  hasNote,
}) => {
  return (
    <DocumentListContainer>
      {collateral && collateral.length === 0 && <div>No documents found</div>}
      {collateral && collateral.length > 0 && (
        <StyledDocumentTable>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>Index</th>
              <th>Name</th>
              <th>Status</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {collateral &&
              collateral.map((doc, idx) => {
                const isSelected = selectedDocId == doc.id;
                return (
                  <tr
                    key={doc.id}
                    aria-selected={isSelected ? 'true' : 'false'}
                    className={isSelected ? 'checked-row' : ''}
                    onClick={() => onDocSelected(doc.id)}
                  >
                    <td style={{ width: '32px' }}>
                      <RadioButton
                        label={''}
                        name={''}
                        value={''}
                        checked={isSelected}
                        onChange={() => onDocSelected(doc.id)}
                      />
                    </td>
                    <td>
                      {hasNote(doc.id) && (
                        <AddNoteIcon
                          onNoteClick={() => handleNoteClick(doc.id)}
                          noteStatus={doc.note_status}
                        />
                      )}
                    </td>
                    <td>{idx + 1}</td>
                    <td>
                      <FileName>{doc.name}</FileName>
                    </td>
                    <td style={{ width: '230px', position: 'relative' }}>
                      {role === DealRole.BUYER && (
                        <FileStatusDropdown
                          value={doc.collateral_status}
                          onChange={(option) =>
                            onFileStatusSelect &&
                            onFileStatusSelect(doc.id, option)
                          }
                          isInSelectedRow={isSelected}
                        />
                      )}
                      {role === DealRole.SELLER && (
                        <div>{fromCollateralStatus(doc.collateral_status)}</div>
                      )}
                    </td>
                    <td>{formatDate(doc.uploadedAt)}</td>
                  </tr>
                );
              })}
          </tbody>
        </StyledDocumentTable>
      )}
    </DocumentListContainer>
  );
};

export default DocumentsTable;

const DocumentListContainer = styled.div`
  height: 240px;
  overflow-y: auto;
  background-color: #151518;
  border: 1px solid rgba(39, 46, 59, 1);
  border-radius: 4px;
`;

const formatDate = (date: string): string => {
  if (!date) return '-';
  const luxonDate = DateTime.fromISO(date);
  return luxonDate.toFormat('yyyy-MM-dd h:mma');
};

const StyledDocumentTable = styled.table`
  color: ${(props) => props.theme.color.slate200};
  border-collapse: collapse;
  width: 100%;

  thead {
    ${typographyHeebo14Bold}
    text-align: left;
  }

  tbody {
    tr:nth-child(even) {
      background-color: #1c1c1d;
    }

    tr.checked-row {
      color: ${(props) => props.theme.color.slate50};
      background-color: #4f1450;
    }

    tr:hover {
      background-color: #9a1698;
    }
  }
`;

const FileName = styled.span`
  color: ${(props) => props.theme.color.slate50};
`;
