import { useState, useEffect } from 'react';
import { RowSelectionState } from '@tanstack/react-table';
import { SelectionStatus } from 'common-ui/SelectionStatusLine';

interface Params {
  pageNumber: number;
  pageSize: number;
}

export const useSelection = (params: Params, data?: unknown) => {
  const [selectedLoans, setSelectedLoans] = useState<RowSelectionState>({});
  const [selectionStatus, setSelectionStatus] = useState<SelectionStatus>(
    SelectionStatus.None
  );

  const onClear = () => {
    setSelectedLoans({});
    setSelectionStatus(SelectionStatus.None);
  };

  const onSelectPage = () => {
    setSelectionStatus(SelectionStatus.PageSelected);
  };

  const onSelectAll = () => {
    setSelectionStatus(SelectionStatus.AllSelected);
  };

  useEffect(() => {
    setSelectedLoans({});
    setSelectionStatus(SelectionStatus.None);
  }, [params.pageNumber, params.pageSize, data]);

  return {
    selectedLoans,
    selectionStatus,
    setSelectionStatus,
    onClear,
    onSelectPage,
    onSelectAll,
    setSelectedLoans,
  };
};
