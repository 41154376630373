import { useState, useEffect } from 'react';

interface IconProps {
  name?: string;
  fill?: string | undefined;
}

interface Module {
  ReactComponent: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const SvgIcon = ({ name, ...rest }: IconProps) => {
  const [iconModule, setIconModule] = useState<Module | undefined>(undefined);

  useEffect(() => {
    let isMounted = true;

    if (!name) return;

    import(`../assets/svgs/${name}.svg`)
      .then((module) => {
        if (isMounted) {
          setIconModule(module);
        }
      })
      .catch((error) => {
        console.error(`${error}: Icon with name: ${name} not found!`);
      });

    return () => {
      isMounted = false;
    };
  }, [name]);

  if (!iconModule) return null;

  /* Equal to: import { ReactComponent as Icon } from "./path/to/icon.svg" */
  const Component = iconModule.ReactComponent;

  return <Component {...rest} />;
};
