import React from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { BaseButton } from 'common-ui';
import { Dialog, useDialog } from 'common-ui/Dialog';
import { FieldIdAndLabel, FieldsMap } from './MapRequiredFieldsCard';

import { MagicWand } from '@styled-icons/boxicons-solid/MagicWand';
import { Spinner3 } from '@styled-icons/evil/Spinner3';
import { InfoOutline } from '@styled-icons/evaicons-outline/InfoOutline';
import { Close } from '@styled-icons/ionicons-outline/Close';

const AUTO_MAP_FIELDS_QUERY = gql`
  query AutoMapFields($input: AutoMapFieldsInput!) {
    autoMapFields(input: $input) {
      matches {
        csvHeader
        loanField
      }
      errors {
        missingEnums
        multipleHeadersToSingleEnum
        detailedMessage
      }
    }
  }
`;

type LLMAutoMapComponentProps = {
  fieldEnums: FieldIdAndLabel[];
  columns: string[];
  rows: string[][];
  onAutoMapComplete: (fieldsMap: FieldsMap, remainingUnmappedHeaders: string[]) => void;
}

const LLMAutoMapComponent: React.FC<LLMAutoMapComponentProps> = ({ fieldEnums, columns, rows, onAutoMapComplete }) => {
  const dialog = useDialog();

  const [autoMapFields, { loading }] = useLazyQuery(AUTO_MAP_FIELDS_QUERY, {
    onCompleted: (data) => {
      console.log('data', data);
      const fieldsMap: FieldsMap = {};
      data.autoMapFields.matches.forEach((match: any) => {
        fieldsMap[match.loanField] = match.csvHeader;
      });
      // on error, log the error
      if (data.autoMapFields.errors.length) {
        console.error(data.autoMapFields.errors);
      }

      const remainingHeaders = columns.filter((header) => !fieldsMap[header]);
      onAutoMapComplete(fieldsMap, remainingHeaders);
    },
  });

  const handleAutoMapClick = () => {
    // if fieldIds or columns are empty, don't call autoMapFields
    if (!fieldEnums.length || !columns.length) {
      console.error('LLMAutoMap: Field enums or columns are empty');
      return;
    }

    dialog.openDialog();
    const fieldIds = fieldEnums.map((f) => f.fieldId);
    autoMapFields({
      variables: {
        input: {
          columns: columns,
          sampleRows: rows,
          enums: fieldIds,
        },
      },
    });
  };

  return (
    <>
      <Dialog dialog={dialog}>
        <span className='inline-block absolute right-4 top-4 hover:cursor-pointer' onClick={dialog.closeDialog}>
          <Close size={24} className='text-foreground-subtle' />
        </span>
        <div className='flex flex-row gap-6'>
          <div className='w-14 h-14 bg-info-muted rounded-lg justify-center items-center flex'>
            <InfoOutline size={24} className='text-black' />
          </div>
          <div className='flex flex-col w-[400px] text-left'>
            <h2 className='text-xl mb-2'>Mapping uses our generative A.I. model</h2>
            <p className='text-base'>
              Once mapping is complete, please review the results as this will help fine-tune the process for future mapping.
            </p>
            <div className='flex justify-end mt-2'>
              <BaseButton onClick={dialog.closeDialog} label='Close dialog'>
                OK
              </BaseButton>
            </div>
          </div>
        </div>
      </Dialog>

      <div className='mr-5'>
        <BaseButton type="secondary" onClick={handleAutoMapClick} disabled={loading} label='AI Automap'>
          {loading ? <Spinner3 size={20} className='mr-1 animate-spin' /> : <MagicWand size={20} className='mr-1' />}
          AI Automap
        </BaseButton>
      </div>
    </>
  );
};

export default LLMAutoMapComponent;
