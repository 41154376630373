import { Dialog, DialogProps } from 'common-ui/Dialog';
import {
  typographyPTSansNarrow10,
  typographyParagraphSmallPrimaryRegular,
} from 'common-ui/typography';
import { styled } from 'style/ORSNNTheme';
import { useEffect, useState } from 'react';
import { BaseButton } from 'common-ui';
import {
  CloseButton,
  CompactDialogContainer,
  CompactDialogHeader,
} from './commonStyles';
import { useMutation, useQuery } from '@apollo/client';
import {
  GetCollateralNotes,
  GetCollateralNotesVariables,
} from 'query/__generated__/GetCollateralNotes';
import { GET_COLLATERAL_NOTES } from 'query/diligence';
import {
  CreateCollateralNote,
  CreateCollateralNoteVariables,
} from 'mutation/__generated__/CreateCollateralNote';
import { CREATE_COLLATERAL_NOTE } from 'mutation/diligenceMutations';

// TODO(kentskinner): Lots of duplication with DiligenceNotesDialog.tsx. Refactor to use a shared component.

interface CollateralNotesDialogProps {
  documentName: string;
  collateralId?: string;
  dialog: DialogProps;
}

const CollateralNotesDialog: React.FC<CollateralNotesDialogProps> = ({
  documentName,
  collateralId,
  dialog,
}) => {
  const [noteText, setNoteText] = useState<string>('');

  const { data, loading, refetch } = useQuery<
    GetCollateralNotes,
    GetCollateralNotesVariables
  >(GET_COLLATERAL_NOTES, {
    variables: { collateral_id: collateralId! },
    skip: !dialog.isOpen || !collateralId,
    pollInterval: 10000,
  });

  const [createCollateralNote] = useMutation<
    CreateCollateralNote,
    CreateCollateralNoteVariables
  >(CREATE_COLLATERAL_NOTE);

  const handleAddNote = (text: string) => {
    createCollateralNote({
      variables: {
        input: {
          collateral_id: collateralId!,
          note: text,
        },
      },
    }).then(() => {
      refetch();
      dialog.setIsOpen(false);
    });
  };

  useEffect(() => {
    if (dialog.isOpen) {
      // If the dialog is opened, clear the note
      setNoteText('');
    }
  }, [dialog.isOpen]);

  return (
    <Dialog dialog={dialog} contentComponent={CompactDialogContainer}>
      <CompactDialogHeader>
        Add a Note for "{documentName}"
        <CloseButton onClick={() => dialog.setIsOpen(false)} />
      </CompactDialogHeader>
      <NoteHistory>
        {loading && <div>Loading...</div>}
        {data &&
          data.collateralNotes?.entities?.map((note) => (
            <div key={note!.id}>
              <NoteHeader>
                <span>
                  ({note!.author.given_name}{' '}
                  {note!.author.family_name?.substring(0, 1)})
                </span>
              </NoteHeader>
              <NoteDate>
                {new Date(parseInt(note!.created_time) * 1000).toDateString()}
              </NoteDate>
              <NoteText>{note!.note}</NoteText>
            </div>
          ))}
      </NoteHistory>
      <NoteEditor
        aria-label="Add Note"
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
      />
      <ButtonsContainer>
        <BaseButton
          type="secondary"
          label="cancel"
          size="medium"
          onClick={() => dialog.setIsOpen(false)}
        >
          Cancel
        </BaseButton>
        <BaseButton
          label="add-note"
          size="medium"
          disabled={noteText.length === 0}
          onClick={() => {
            handleAddNote(noteText);
          }}
        >
          Add Note
        </BaseButton>
      </ButtonsContainer>
    </Dialog>
  );
};

export default CollateralNotesDialog;

const NoteHistory = styled.div`
  max-height: 444px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 20px;
`;

const NoteHeader = styled.div`
  color: ${(props) => props.theme.color.grey500};
`;

const NoteDate = styled.div`
  ${typographyPTSansNarrow10}
  color: ${(props) => props.theme.color.slate200};
  margin-bottom: 5px;
`;

const NoteText = styled.div`
  ${typographyParagraphSmallPrimaryRegular}
`;

const NoteEditor = styled.textarea`
  ${typographyParagraphSmallPrimaryRegular}
  border: 1px solid #e85ee5;
  border-radius: 4px;
  margin: 15px 0;
  padding: 5px 10px;
  width: 100%;
  height: 118px;
  color: ${(props) => props.theme.color.fgDefault};
  background-color: ${(props) => props.theme.color.bgSurface};

  &:focus {
    outline: none;
    border: 1px solid #e85ee5;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;
