import React, { useRef } from 'react';
import LoanTapeUploader from './loanTapeUploader/LoanTapeUploader';
import MapRequiredFieldsCard, { MappingCardFragments } from './fieldMapping/MapRequiredFieldsCard';
import ProcessingReportCard, { ProcessingReportCardFragments } from './ProcessingReportCard';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { TapesUploadedTableFragments } from './components/TapesUploadedTable';
import { GetLoanTape, GetLoanTapeVariables } from './__generated__/GetLoanTape';

const GET_LOAN_TAPE_QUERY = gql`
  query GetLoanTape($tapeId: ID!) {
    user {
      id
      company {
        id
        loanTape: file(id: $tapeId) {
          id
          ...TapesUploadedTableFile
          ...MappingCardFile
          ...ProcessingReportCardFile
        }
      }
    }
  }
  ${MappingCardFragments.loanTape}
  ${ProcessingReportCardFragments.loanTape}
  ${TapesUploadedTableFragments.loanTape}
`;

export const TapeUploadFlow: React.FC = () => {
  const tapeId = useParams<{ tapeId: string }>().tapeId;

  const { data, loading } = useQuery<GetLoanTape, GetLoanTapeVariables>(GET_LOAN_TAPE_QUERY, {
    variables: { tapeId: tapeId as string },
    skip: !tapeId,
    pollInterval: 5000,
    fetchPolicy: 'network-only'
  });

  const selectedFile = data?.user.company.loanTape;

  const mapFieldsCardRef = useRef<HTMLDivElement>(null);
  const scrollToMapFieldsCard = () => {
    mapFieldsCardRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const processingReportCardRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <LoanTapeUploader onClickNext={scrollToMapFieldsCard} />
      <div ref={mapFieldsCardRef} className='relative'>
        {selectedFile?.headers && !loading && <MapRequiredFieldsCard selectedFile={selectedFile} />}
      </div>
      <div ref={processingReportCardRef}>
        {selectedFile?.processingReport && !loading && <ProcessingReportCard selectedFile={selectedFile} />}
      </div>
    </>
  );
};

