import { useState, useCallback } from 'react';
import { GET_COLLATERAL_NOTES } from 'query/diligence';
import {
  GetCollateralNotes,
  GetCollateralNotesVariables,
} from 'query/__generated__/GetCollateralNotes';

import { Note } from '../DealDocumentTypes';
import { useApolloClient } from '@apollo/client';

// This hook fetches and manages collateral notes
export const useCollateralNotes = () => {
  const client = useApolloClient();

  // State to store the fetched notes, mapped by collateral ID
  const [collateralNotesMap, setCollateralNotesMap] = useState<
    Record<string, Note[]>
  >({});

  const fetchCollateralNotes = useCallback(
    // Fetch notes for each collateralId.
    // TODO: This should be a single query
    async (collateralIds: string[]) => {
      // Create an array of promises to fetch notes for each collateral ID
      const fetchPromises = collateralIds.map((collateralId) => {
        return client.query<GetCollateralNotes, GetCollateralNotesVariables>({
          query: GET_COLLATERAL_NOTES,
          variables: { collateral_id: collateralId },
          fetchPolicy: 'network-only',
        });
      });

      // Wait for all fetches to complete
      const allNotes = await Promise.all(fetchPromises);

      // Update the state with the fetched notes
      setCollateralNotesMap((prevMap) => {
        const newMap = { ...prevMap };
        allNotes.forEach((result, index) => {
          // Map the fetched data to the Note type
          const notes =
            result.data?.collateralNotes?.entities?.map(
              (note) =>
                ({
                  text: note!.note,
                  date: new Date(
                    parseInt(note!.created_time) * 1000
                  ).toDateString(), // Convert Unix timestamp to Date
                  institution: '',
                  firstName: note?.author.given_name,
                  lastInitial: note?.author.family_name?.substring(0, 1),
                } as Note)
            ) || [];

          // Add the notes to the map under the corresponding collateral ID
          newMap[collateralIds[index]] = notes;
        });

        return newMap;
      });
    },
    [client]
  );

  // Return the notes map and the fetch function
  return { collateralNotesMap, fetchCollateralNotes };
};
