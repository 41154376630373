import { gql, useQuery } from '@apollo/client';
import { FC, useState } from 'react';
import { formatCurrency, formatPercent } from 'configs/columns';
import { MarketplaceBlotterQuery } from './__generated__/MarketplaceBlotterQuery';
import { Modal } from 'common-ui';
import RequestAccess from 'features/OnMarket/RequestAccess';
import { RequestAccessFragments } from 'features/OnMarket/RequestAccess.fragments';

const MARKETPLACE_BLOTTER_QUERY = gql`
  query MarketplaceBlotterQuery {
    listings (sort: { sort_field: created_date_seconds, sort_direction: desc }, pagination: { page_size: 3, offset: 0 }){
      id
      provided_name
      asset_class
      wa_coupon
      t_current_balance_cents
      ...on PublicListing {
        ...RequestAccessPublicListing
      }
    }
  }
  ${RequestAccessFragments.PublicListing}
`;

export const MarketplaceBlotter: FC = () => {
  const { data, error: queryError } = useQuery<MarketplaceBlotterQuery>(MARKETPLACE_BLOTTER_QUERY, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedListing, setSelectedListing] = useState<null | MarketplaceBlotterQuery['listings'][0]>(null);

  if (queryError) {
    return <div>Error loading listings</div>;
  }

  const listings = data?.listings || [];

  const handleListingClick = (listing: MarketplaceBlotterQuery['listings'][0]) => {
    setSelectedListing(listing);
    setIsModalOpen(true);
  };

  return (
    <div>
      <h2 className='font-heebo font-medium text-lg text-white mb-4'>Marketplace Blotter</h2>
      <div className="flex overflow-x-auto pb-4">
        {listings.map((listing) => (
          <div
            key={listing.id}
            className="text-white border-r border-slate-500 first:pl-2 pl-6 last:border-r-0 pr-6 last:pr-0 flex-shrink-0 cursor-pointer hover:bg-slate-700 hover:scale-105 transition-all duration-200"
            onClick={() => handleListingClick(listing)}
          >
            <div className="text-purple-300 font-medium max-w-[130px]">{listing.provided_name}</div>
            <div>{listing.asset_class}</div>
            <div className="font-bold">
              <span className="whitespace-nowrap">GWAC</span>{' '}
              <span className="whitespace-nowrap">{formatPercent(listing.wa_coupon ?? 0, 3)}</span>
            </div>
            <div className="text-green-500">
              <span className="whitespace-nowrap">UPB</span>{' '}
              <span className="whitespace-nowrap">{formatCurrency(listing.t_current_balance_cents)}</span>
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && selectedListing && (
        <Modal
          headerText=""
          setIsOpen={setIsModalOpen}
          type="request-access"
          subText=""
          caption=""
          className="p-6 pt-1 bg-background-canvas rounded-lg border border-pink-500 shadow-lg text-white max-w-[985px] w-full overflow-y-auto max-h-[540px] font-heebo"
        >
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold">{selectedListing.name}</h2>
            <button className="text-grey-500 hover:text-grey-300" onClick={() => setIsModalOpen(false)}>✕</button>
          </div>
          <RequestAccess listingId={selectedListing.id} listingDetails={selectedListing} />
        </Modal>
      )}
    </div>
  );
};
