import { RangePillButton } from 'common-ui';
import { BaseFiltersConfig, RangeFilter, RangeFilterConfigEntry } from '../filters.types';
import type {CommonProps} from '../PillFilters';

export function RangePillFilter<T extends BaseFiltersConfig>({
  rangeFilter,
  filterConfig,
  removeFilter,
  updateFilter,
  index,
}: CommonProps<T> & {
  rangeFilter: RangeFilter<T>;
  filterConfig: RangeFilterConfigEntry;
}) {
  return (
    <RangePillButton
      name={filterConfig.displayName}
      min={filterConfig.min}
      max={filterConfig.max}
      initialValue={[rangeFilter.min, rangeFilter.max]}
      iconName="pill-close"
      onIconClick={() => removeFilter(rangeFilter.name, index)}
      onChange={(values) => updateFilter(rangeFilter.name, index, values)}
      displayPercentage={!!filterConfig.percentage}
      integer={!!filterConfig.integer}
      decimal={!!filterConfig.decimal}
    />
  );
}
