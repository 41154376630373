import { Dialog, DialogProps } from 'common-ui/Dialog';
import {
  CloseButton,
  CompactDialogContainer,
  CompactDialogHeader,
} from './commonStyles';
import { styled } from 'style/ORSNNTheme';
import { FileDiligenceStatus, FileStatusDropdown } from './FileStatus';
import { typographyLabelSmallPrimaryRegular } from 'common-ui/typography';
import { GetDealDiligence_deal_diligence_collateral } from 'query/__generated__/GetDealDiligence';

interface QuickStatusDialogProps {
  loanName: string;
  collateral: GetDealDiligence_deal_diligence_collateral[];
  dialog: DialogProps;
  onStatusChange: (docId: string, value: FileDiligenceStatus) => void;
}

export const QuickStatusDialog = ({
  dialog,
  collateral,
  loanName,
  onStatusChange,
}: QuickStatusDialogProps) => {
  return (
    <Dialog dialog={dialog} contentComponent={CompactDialogContainer}>
      <CompactDialogHeader>
        View/Edit Documents Status for "{loanName}"
        <CloseButton onClick={() => dialog.setIsOpen(false)} />
      </CompactDialogHeader>
      <div>Total: {collateral.length} Documents</div>
      <DocumentList>
        {collateral.map((collateral) => (
          <DocumentRow key={collateral.id}>
            <DocumentName>{collateral.name}</DocumentName>
            <div style={{ width: '220px' }}>
              {collateral.collateral_status && (
                <FileStatusDropdown
                  isInSelectedRow={false}
                  value={collateral.collateral_status}
                  borderColor="#616161"
                  onChange={(status) => onStatusChange(collateral.id, status)}
                />
              )}
            </div>
          </DocumentRow>
        ))}
      </DocumentList>
    </Dialog>
  );
};

const DocumentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DocumentName = styled.div`
  ${typographyLabelSmallPrimaryRegular}
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DocumentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  ${typographyLabelSmallPrimaryRegular}
`;
