import React from 'react';
import { TapeUploadFlow } from './TapeUploadFlow';
import { PortfolioLayout } from 'features/pages/portfolio/Portfolio/PortfolioLayout';
import TapeUploadContext from './loanTapeUploader/TapeUploadContext';
import { FileIngestOperationProvider } from './FileIngestOperation';
import { MapFieldsOperationProvider } from './MapFieldsOperation';

export const TapeUploadPage: React.FC = () => {
  return (
    <PortfolioLayout hideSummery={true}>
      <TapeUploadContext.Provider
        value={{
          uploadedTapes: [],
          selectedFile: undefined,
          selectedFileId: undefined,
          setSelectedFileId: () => {
            throw new Error('setSelectedFileId not implemented');
          },
        }}
      >
        <FileIngestOperationProvider>
          <MapFieldsOperationProvider>
            <TapeUploadFlow />
          </MapFieldsOperationProvider>
        </FileIngestOperationProvider>
      </TapeUploadContext.Provider>
    </PortfolioLayout>
  );
};
