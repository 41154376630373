import { gql } from '@apollo/client';

export const GET_USER_COMPANY = gql`
  query GetUserCompany {
    user {
      id
      company {
        id
        name
      }
    }
  }
`;