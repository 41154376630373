import { useQuery } from '@apollo/client';
import { Spinner } from 'baseui/spinner';
import { GET_UPLOADED_FILES_WITH_HEADERS } from 'query/getUploadedFilesWithHeaders';
import { GetUploadedFilesWithHeaders } from 'query/__generated__/GetUploadedFilesWithHeaders';
import { useEffect, useMemo } from 'react';
import { SectionHeader, TapesUploadedPanel } from '../loanTapeUploader/LoanTapeUploader.styles';
import TapesUploadedTable, { InProgressFile } from './TapesUploadedTable';

type UploadHistoryProps = {
  uploadsInProgress: InProgressFile[];
};

const UploadHistory = (props: UploadHistoryProps) => {
  const { data, loading, error, startPolling, stopPolling } = useQuery<GetUploadedFilesWithHeaders>(
    GET_UPLOADED_FILES_WITH_HEADERS,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 2000,
    }
  );

  useEffect(() => {
    startPolling(2000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const loanTapeIds = useMemo(() => 
    data?.user.company.loan_tapes.map((loanTape) => loanTape.id) || []
  , [data]);

  return ( 
    <TapesUploadedPanel>
      <>
        <SectionHeader>TAPES UPLOADED { loading && <Spinner size={12}/> }</SectionHeader>
        {loanTapeIds.length > 0 && <span>Select a tape get started</span>}
        <TapesUploadedTable
          inProgressFiles={props.uploadsInProgress.filter(file => file.id == null || !loanTapeIds?.includes(file.id))}
          tableRows={data?.user.company.loan_tapes}
          error={error}
        />
      </>
    </TapesUploadedPanel>
  );
};

export type {
  InProgressFile,
  UploadHistoryProps,
};

export default UploadHistory;
