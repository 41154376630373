import { FC } from 'react';

const InfoBadge: FC<{label: string}> = ({label}) => {
  return (
    <div className='px-1 py-[2px] text-[#3142C4] rounded-sm font-bold bg-info-subtle font-pt-sans-narrow text-xs leading-3 h-[18px]'>
      {label}
    </div>
  )
}

export {
  InfoBadge
}
