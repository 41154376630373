import React from 'react';
import { styled } from 'style/ORSNNTheme';
import templateRemovalConfirmationImgPath from 'assets/svgs/check-dark-gray.svg';
import { CloseButton } from 'common-ui/form/StandardForm';

const Image = styled.img`
  background: ${(props) => props.theme.color.successDefault};
  border-radius: 8px;
  margin-right: 24px;
`;

interface TemplateToastContentProps {
  documentName: string;
  isCreatingTemplate: boolean;
  onClose: () => void;
}

const TemplateToastContent: React.FC<TemplateToastContentProps> = ({
  documentName,
  isCreatingTemplate,
  onClose,
}) => (
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    <Image
      src={templateRemovalConfirmationImgPath}
      alt="template removal confirmation check"
      width={56}
      height={56}
    />
    <div>
      <div
        style={{
          fontSize: '20px',
          fontWeight: 500,
          marginBottom: '8px',
        }}
      >
        Template {isCreatingTemplate ? 'Created' : 'Removed'}
      </div>
      <div>
        <b>{documentName}</b> has been{' '}
        {isCreatingTemplate ? 'created' : 'removed'} as a template.
      </div>
    </div>
    <CloseButton onClick={onClose} />
  </div>
);

export default TemplateToastContent;
